import { Component, OnDestroy, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { DxValidatorModule } from "devextreme-angular/ui/validator";
import notify from "devextreme/ui/notify";
import { AppSettings } from "src/app/app-settings";
import { confirm } from "devextreme/ui/dialog";


@Component({
  selector: "app-bill-validation",
  templateUrl: "./bill-validation.component.html",
  styleUrls: ["./bill-validation.component.scss"],
})
export class BillValidationComponent
  extends SharedClassComponent
  implements OnInit, OnDestroy
{
  now: Date = new Date();
  billCreateForm: FormGroup;
  maxLength = 500;
  amount = 0;
  selectedCurrency = "TZS";
  endpoint = "bills/create";
  ItemForm: FormGroup;
  BillItemForm: FormGroup;
  billItems = [];
  currencyDts = [];
  paymentTypeDts = [
    {
      id: "EXACT",
      text: "EXACT",
    },
    {
      id: "FULL",
      text: "FULL",
    },
    {
      id: "PARTIAL",
      text: "PARTIAL",
    },
    {
      id: "INFINITY",
      text: "INFINITY",
    },
    {
      id: "LIMITED",
      text: "LIMITED",
    },
  ];
  revenueDataSource = this.authService.getCategoriesDetails();
  billItemsDatasource = [];
  createdBillDatasource = [];

  institutionName = this.authService.getUserDetails().spName;
  institutionCode = this.authService.getUserDetails().spId;
  title = "Create Bill";
  spCatList = [];
  showBillPendingAlert = false;
  showBillItemsDialog = false;
  cityPattern = "^[^0-9]+$";
  namePattern: any = /^[^0-9]+$/;
  phonePattern: any = /^[0-9]+$/;
  countries: string[];
  phoneRules: any = {
    X: /[0-9]/,
  };
  billId = null;
  hideUpdateBillButton = true;
  editBillDatasource: any;
  accDistributionDatasource = [];
  currencyList = ["TZS", "USD"];
  totalAmountBilled: number = 0;

  hideBillUpdateCategory = true;
  maxValue: number = 0;
  remarkPostForm: FormGroup;
  showRemark = false;
  showRemarksPopUp: boolean = false;
  isAllowedToReuseBill: boolean = false;
  isAllowedToCreateBill: boolean = false;

  actions = [
    { value: 1, text: "Update Bill", icon: "fa fa-pencil", action: "update" },

    { value: 4, text: "Cancel Bill", icon: "fa fa-close", action: "cancel" },

    {
      value: 2,
      text: "Change Bill's Expire Date",
      icon: "fa fa-repeat",
      action: "change",
    },
  ];

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.getServiceProviderAccounts();
    this.remarkPostForm = new FormGroup({
      remark: new FormControl("", [Validators.required]),
    });
    this.billCreateForm = new FormGroup({
      payerNumber: new FormControl("", Validators.compose([])),
      payerName: new FormControl("", Validators.compose([Validators.required])),
      // email: new FormControl("", Validators.compose([Validators.email])),

      billExpireDate: new FormControl(this.now, Validators.compose([])),
      // phone: new FormControl(
      //   "",
      //   Validators.compose([Validators.min(10), Validators.required])
      // ),

      email: new FormControl(
        "",
        Validators.compose([
          Validators.email,
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/),
        ])
      ),

      phone: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(07|06)[0-9]{8}$/),
          Validators.minLength(10),
          Validators.maxLength(10),
        ])
      ),
      currency: new FormControl(
        "TZS",
        Validators.compose([Validators.required])
      ),
      paymentType: new FormControl(
        this.paymentTypeDts[0].id,
        Validators.compose([Validators.required])
      ),
      exchangeRate: new FormControl(1, Validators.compose([])),
      billDescription: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      revenueSource: new FormControl(null, Validators.compose([])),
      institutionName: new FormControl(
        { value: this.institutionName, disabled: true },
        Validators.compose([])
      ),
      institutionCode: new FormControl(
        { value: this.institutionCode, disabled: true },
        Validators.compose([])
      ),
      billAmount: new FormControl(null, Validators.compose([])),
      spBillId: new FormControl("", Validators.compose([])),
      payerId: new FormControl("", Validators.compose([])),
    });

    this.ItemForm = new FormGroup({
      catName: new FormControl("", Validators.compose([Validators.required])),
      amount: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]\d*$/),
        ])
      ),
      description: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.BillItemForm = new FormGroup({
      billItem: this.fb.array([]),
    });
    this.calculateTotalAmount();

    this.getCategoryBySp();
    // this.getCurrency();

    this.route.queryParams.subscribe((params) => {
      this.billId = params.billId;

      if (
        this.billId &&
        sessionStorage.getItem(AppSettings.billEditKey) !== null &&
        sessionStorage.getItem(AppSettings.billEditKey) !== undefined
      ) {
        this.editBillDatasource = JSON.parse(
          sessionStorage.getItem(AppSettings.billEditKey)
        );
        this.hideUpdateBillButton = false;
        this.billCreateForm.patchValue(this.editBillDatasource);
        this.billCreateForm
          .get("payerName")
          .patchValue(this.editBillDatasource.payerName);
        this.billCreateForm
          .get("email")
          .patchValue(this.editBillDatasource.payerEmail);
        this.billCreateForm
          .get("billExpireDate")
          .patchValue(this.editBillDatasource.expireDate);
        this.billCreateForm
          .get("phone")
          .patchValue(this.editBillDatasource.payerMobile);
        this.billCreateForm
          .get("currency")
          .patchValue(this.editBillDatasource.currency);
        this.billCreateForm
          .get("paymentType")
          .patchValue(this.editBillDatasource.pmtOptName);
        this.billCreateForm
          .get("billDescription")
          .patchValue(
            this.editBillDatasource.description
              ? this.editBillDatasource.description
              : this.editBillDatasource.billDesc
          );
        this.billCreateForm
          .get("billAmount")
          .patchValue(this.editBillDatasource.billAmount);
        this.billCreateForm
          .get("spBillId")
          .patchValue(
            this.editBillDatasource.invoiceNo
              ? this.editBillDatasource.invoiceNo
              : this.editBillDatasource.spBillId
          );
        this.billCreateForm
          .get("payerId")
          .patchValue(this.editBillDatasource.payerId);
        this.billItems = this.editBillDatasource.billItems;

        this.billItems.forEach((item) => {
          this.addbillItemReturned(item);
        });

        if (this.billItems.length == 0) {
          this.createbillItem(this.maxValue);
        }
        this.calculateTotalAmount();
      } else {
        this.hideUpdateBillButton = true;
      }
    });

    if (!this.billId) {
      this.addbillItem();
      sessionStorage.removeItem(AppSettings.billEditKey);
    }
  }

  getServiceProviderAccounts() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;

            this.isAllowedToReuseBill = pemsn.some(
              (permission) =>
                permission.permission === "Can Reuse Bill" &&
                permission.permissionName === "true"
            );

            const canChangeExpirDate = pemsn.some(
              (permission) =>
                permission.permission === "Can Change Bills Expiry Date" &&
                permission.permissionName === "true"
            );

            if (!canChangeExpirDate) {
              const items = this.actions.filter((item) => item.value != 2);

              this.actions = items;
            }

            const canCancelBill = pemsn.some(
              (permission) =>
                permission.permission === "Can Cancel Bill" &&
                permission.permissionName === "true"
            );
            if (!canCancelBill) {
              const items = this.actions.filter((item) => item.value != 4);

              this.actions = items;
            }

            const canUpdateBill = pemsn.some(
              (permission) =>
                permission.permission === "Can Update Bill" &&
                permission.permissionName === "true"
            );

            if (!canUpdateBill) {
              const items = this.actions.filter((item) => item.value != 1);

              this.actions = items;
            }

            this.isAllowedToCreateBill = pemsn.some(
              (permission) =>
                permission.permission === "Create Bill" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  ngOnDestroy(): void {
    // sessionStorage.removeItem(AppSettings.billEditKey);
  }

  onActionClick(e: any) {
    const action = e.itemData.action;
    switch (action) {
      case "cancel":
        this.openPopUp();
        break;
      case "print":
        this.printOrderForm(this.editBillDatasource);
        break;
      case "change":
        // this.createBill("change");
        this.changeBillDetails();
        break;
      case "reuse":
        this.billReuse();
        break;
      case "update":
        this.createBill("update");
        break;
    }
  }

  openPopUp() {
    this.showRemarksPopUp = true;
  }
  createBill(action: String) {
    Object.keys(this.billCreateForm.controls).forEach((key) => {
      const control = this.billCreateForm.get(key);
      control.markAsTouched();
    });
    const billItemList = this.BillItemForm.get("billItem").value.map(
      ({ id, ...rest }) => rest
    );

    const phone = this.billCreateForm.get("phone");
    if (!phone.value) {
      this.toastr.error("Phone number is required", "Error");
      return;
    }
    if (phone.value && phone.invalid) {
      this.toastr.error(
        "The phone number must start with '07' or '06' and be exactly 10 digits.",
        "Invalid Phone Number"
      );
      return;
    }

    if (this.billCreateForm.invalid) {
      this.toastr.error(
        "Bill can not be created, required fields are empty or invalid"
      );

      return;
    }

    if (billItemList.length < 1) {
      this.toastr.error("Bill can not be created, Bill items are required");
      return;
    }

    let totalBillItemsAmount = 0;
    for (const iterator of billItemList) {
      if (
        iterator.amount === null ||
        iterator.amount === undefined ||
        !iterator.amount
      ) {
        this.toastr.error(
          "Bill can not be created, Bill item amount can not be empty"
        );
        return;
      }

      if (
        !iterator.description ||
        `${iterator.description === null}`.trim() == ""
      ) {
        this.toastr.error(
          "Bill can not be created, Bill item Reference can not be empty"
        );
        return;
      }

      if (!iterator.category) {
        this.toastr.error(
          "Bill can not be created, Bill item Revenue source can not be empty"
        );
        return;
      }
      totalBillItemsAmount += parseFloat(iterator.amount);
    }

    if (totalBillItemsAmount <= 0) {
      this.toastr.error("Billed amount can not be less or equal to zero.");
      return;
    }

    if (this.billCreateForm.get("billExpireDate").value == null) {
      const dateString =
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2);
      this.billCreateForm.get("billExpireDate").patchValue(dateString);
    } else {
      const dateString =
        new Date(
          this.billCreateForm.get("billExpireDate").value
        ).getFullYear() +
        "-" +
        (
          "0" +
          (new Date(
            this.billCreateForm.get("billExpireDate").value
          ).getMonth() +
            1)
        ).slice(-2) +
        "-" +
        (
          "0" +
          new Date(this.billCreateForm.get("billExpireDate").value).getDate()
        ).slice(-2);
      this.billCreateForm.get("billExpireDate").patchValue(dateString);
    }

    const rawRequest = {
      requestType: "BILLS_CREATE",
      billStatus: "1",
      billId: this.billId,
      amount: totalBillItemsAmount,
      description: this.billCreateForm.get("billDescription").value,
      spId: this.institutionCode,
      spBillId: this.billCreateForm.get("spBillId").value,
      expireDate: this.billCreateForm.get("billExpireDate").value,
      currency: this.billCreateForm.get("currency").value,
      payerId: this.billCreateForm.get("payerId").value,
      payerMobile: this.billCreateForm.get("phone").value,
      payerEmail: this.billCreateForm.get("email").value,
      payerName: this.billCreateForm.get("payerName").value,
      payerTelephone: this.billCreateForm.get("phone").value,
      paymentOption: this.billCreateForm.get("paymentType").value,
      billItems: billItemList,
    };

    if (this.hideUpdateBillButton == false) {
      // console.log("data");
      rawRequest.requestType = "BILLS_UPDATE";
      rawRequest.spId = this.editBillDatasource.spId;
      rawRequest.billItems = rawRequest.billItems.map((item) => {
        const { category, ...rest } = item;
        return { categoryId: category, ...rest };
      });

      const result = confirm(
        `Are you sure you want to ${action} this bill?`,
        "Approve"
      );

      result.then((dialogResult) => {
        if (dialogResult) {
          // this.updateBillDetails(rawRequest, action);
          // this.cancelBillDetails()
          this.cancelBillDetailsWithoutReason(rawRequest, action);

          return;
        } else {
          return;
        }
      });

      return;
    }

    this.spinner.show();
    this.utilities.postServiceCall(rawRequest).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdBillDatasource = [];
        if (servRes.statusId == 2000) {
          // this.createdBillDatasource.push(servRes.data);

          this.toastr.success("Bill has been created successfully.");
          this.router.navigate(["/generated-bills"]);
          // const data = servRes.data;

          //      this.billItemsDatasource = [];

          //      if (data.billItems.length < 1) {
          //        this.billItemsDatasource.push({
          //          itemAmount: data.billAmount,
          //          itemDesc: data.billDesc,
          //          itemCatName: data.catName,
          //          itemCurrency: data.currency
          //        });
          //      } else {
          //        for (const iterator of data.billItems) {
          //          this.billItemsDatasource.push({
          //            itemAmount: iterator.amount,
          //            itemDesc: iterator.desc,
          //            itemCatName: iterator.categoryName,
          //            itemCurrency: data.currency
          //          });
          //        }

          //      }
          //      sessionStorage.removeItem(AppSettings.billDetailsKey);
          //      sessionStorage.removeItem(AppSettings.billItemsKey);
          //      sessionStorage.removeItem(AppSettings.bulkBillerKey);
          //      sessionStorage.removeItem(AppSettings.bulkBillerFlag);
          //      sessionStorage.setItem(AppSettings.bulkBillerFlag,  '0');
          //      sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(data));
          //      sessionStorage.setItem(AppSettings.billItemsKey, JSON.stringify(this.billItemsDatasource));
          //      this.router.navigate(['/bill-payment-form']);
          // sessionStorage.removeItem(AppSettings.billEditKey);
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  updateBillDetails(data, action: String) {
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdBillDatasource = [];
        if (servRes.statusId == 2000) {
          sessionStorage.removeItem(AppSettings.billEditKey);
          sessionStorage.setItem(
            AppSettings.billEditKey,
            JSON.stringify(servRes.data)
          );
          // this.createdBillDatasource.push(servRes.data);
          this.billReuse();

          // if (action == "reuse") {
          //   this.billReuse();
          //   return;
          // }

          this.toastr.success("Bill Updated Succesfull.");
          // this.router.navigate(['/generated-bills']);
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  billReuse() {
    const data = {
      requestType: "BILLREUSE",
      billId: this.billId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdBillDatasource = [];
        if (servRes.statusId == 2000) {
          // this.createdBillDatasource.push(servRes.data);

          this.toastr.success("Operation Succesfull.");
          // this.router.navigate(['/generated-bills']);
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  changeBillDetails() {
    const data = {
      requestType: "BILLCHANGE",
      billId: this.billId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdBillDatasource = [];
        if (servRes.statusId == 2000) {
          // this.createdBillDatasource.push(servRes.data);

          this.toastr.success("Operation Succesfull.");
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  cancelBillDetails() {
    if (this.remarkPostForm.invalid) {
      this.toastr.error("Please enter reason");
      return;
    }

    this.showRemarksPopUp = false;
    const result = confirm(
      "Are you sure you want to Cancel this bill?",
      "Approve"
    );

    const data = {
      requestType: "BILLCANCEL",
      billId: this.billId,
      cancelReason: this.remarkPostForm.get("remark").value,
    };

    result.then((dialogResult) => {
      if (dialogResult) {
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const servRes = res.json();
            this.spinner.hide();
            this.createdBillDatasource = [];
            if (servRes.statusId == 2000) {
              // this.createdBillDatasource.push(servRes.data);

              this.toastr.success("Bill Canceled Succesfull.");
              this.router.navigate(["/generated-bills"]);
            } else {
              this.toastr.error(servRes.statusMessage, "Request Failed");
            }
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong, failed to create the bill."
            );
          }
        );
      }
    });
  }
  cancelBillDetailsWithoutReason(rawRequest, action: String) {
    const data = {
      requestType: "BILLCANCEL",
      billId: this.billId,
      cancelReason: "Updated",
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();
        this.createdBillDatasource = [];
        if (servRes.statusId == 2000) {
          // this.createdBillDatasource.push(servRes.data);
          this.updateBillDetails(rawRequest, action);
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  printInvoice() {
    this.router.navigate(["/preview-cleared-bills"], {
      queryParams: { billId: this.billId },
      queryParamsHandling: "merge",
    });
  }

  printOrderForm(e) {
    this.billItemsDatasource = [];

    if (e.billItems.length < 1) {
      this.billItemsDatasource.push({
        itemAmount: e.billAmount,
        description: e.description,
        itemCatName: e.catName,
        itemCurrency: e.currency,
      });
    } else {
      for (const iterator of e.billItems) {
        this.billItemsDatasource.push({
          itemAmount: iterator.amount,
          description: iterator.description,
          itemCatName: iterator.categoryName,
          itemCurrency: e.currency,
        });
      }
    }
    sessionStorage.removeItem(AppSettings.billDetailsKey);
    sessionStorage.removeItem(AppSettings.billItemsKey);
    sessionStorage.removeItem(AppSettings.bulkBillerKey);
    sessionStorage.removeItem(AppSettings.bulkBillerFlag);
    sessionStorage.setItem(AppSettings.bulkBillerFlag, "0");
    sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(e));
    sessionStorage.setItem(
      AppSettings.billItemsKey,
      JSON.stringify(this.billItemsDatasource)
    );
    this.router.navigate(["/bill-payment-form"]);
  }

  onPopupClose() {
    this.hideUpdateBillButton = true;
  }
  getBillDetailsForUpdate() {}

  onFormReset() {
    this.amount = 0;
    this.billItemsDatasource = [];
    this.billCreateForm.reset();
    this.billCreateForm.get("institutionName").patchValue(this.institutionName);
    this.billCreateForm.get("institutionCode").patchValue(this.institutionCode);
  }

  getCategoryBySp() {
    const data = {
      requestType: "SP_SERVICE_CATEGORY_LIST",
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.spCatList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getCurrency() {
    const data = {
      requestType: "SP_CURRENCY_LIST",
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.currencyDts = srvRes.data;
          this.billCreateForm.get("currency").setValue(srvRes.data[0].currency);
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Currency",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  addNewBillItem() {
    this.showBillItemsDialog = true;
    this.selectedCurrency = this.billCreateForm.get("currency").value;
    this.accDistributionDatasource = [];
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      id: "button-style",
      options: {
        text: "Add Item",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.addNewBillItem.bind(this),
      },
    });
  }

  onServiceCategoryChanged(e) {
    this.accDistributionDatasource = [];

    if (this.billCreateForm.get("currency").invalid) {
      this.toastr.error("Please select Currency first");
      return;
    }

    let counter = 0;
    for (const iterator of this.spCatList) {
      if (iterator.catId == e.value) {
        for (const item of iterator.accDistribution) {
          if (item.currency == this.billCreateForm.get("currency").value) {
            for (const i of item.distribution) {
              this.accDistributionDatasource.push({
                id: counter++,
                accNo: i.accNo,
                amount: i.amount,
                description: i.description,
                currency: this.billCreateForm.get("currency").value,
                amtOptCode: iterator.amtOptCode,
                distOptCode: iterator.distOptCode,
                categoryId: e.value,
                categoryName: iterator.catName,
              });
            }
            break;
          }
        }
        break;
      }
    }
  }

  addBillCategoryItemToMainDatasource() {
    let totalAmount = 0;
    let data = {};

    for (const iterator of this.accDistributionDatasource) {
      totalAmount += +iterator.amount;
    }

    for (const iterator of this.accDistributionDatasource) {
      data = {
        categoryId: iterator.categoryId,
        categoryName: iterator.categoryName,
        amount: totalAmount,
        distribution: this.accDistributionDatasource,
        description: `${iterator.categoryName} for ${
          this.billCreateForm.get("payerName").value
        }`,
      };
    }
    this.billItemsDatasource.push(data);
    this.showBillItemsDialog = false;
  }

  addItems() {
    if (this.ItemForm.invalid) {
      this.toastr.error("Input valid data");
      return;
    }

    const data = {
      category: this.ItemForm.get("catName").value,
      amount: this.ItemForm.get("amount").value,
      description: this.ItemForm.get("description").value,
    };

    this.billItems.push(data);

    this.showBillItemsDialog = false;
    this.ItemForm.reset();
  }

  createbillItem(index) {
    return this.fb.group({
      category: ["", Validators.compose([Validators.required])],
      amount: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]\d*$/),
        ]),
      ],
      description: ["", Validators.required],
      id: [index],
    });
  }
  get billItem() {
    return this.BillItemForm.get("billItem") as FormArray;
  }

  addbillItem() {
    const max = this.maxValue++;
    this.billItem.push(this.createbillItem(max + 1));
  }
  addbillItemReturned(item) {
    const max = this.maxValue++;
    this.billItem.push(this.populateBillItem(item, max));
  }

  populateBillItem(item, max) {
    return this.fb.group({
      category: [item.categoryId, Validators.compose([Validators.required])],
      amount: [
        item.amount,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]\d*$/),
        ]),
      ],
      description: [item.description, Validators.required],
      id: [max],
    });
  }

  removeBillItem(i) {
    this.billItem.removeAt(i);
    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    this.totalAmountBilled = this.billItem.controls
      .map((control) => control.get("amount").value)
      .reduce((acc, value) => acc + value, 0);
  }
}
