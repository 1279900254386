import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-usergroup",
  templateUrl: "./usergroup.component.html",
  styleUrls: ["./usergroup.component.scss"],
})
export class UserGroupListComponent
  extends SharedClassComponent
  implements OnInit
{
  userGroupListDatasource: any;
  paramswinpnlN: boolean = false;
  showAddButton = false;
  showUpdateButton = false;

  name: any;

  Id: any;

  hname: any;

  description: any;
  isAllowedToAdd: Boolean = false;
  isAllowedToDelete: Boolean = false;
  isAllowedToUpdate: Boolean = false;
  ngOnInit() {
    this.getUseWithPermissions()
    this.getuserGroups();
    this.paramwinfrm = new FormGroup({
      hname: new FormControl("", Validators.compose([Validators.required])),

      description: new FormControl("", Validators.compose([])),
    });
  }

  getUseWithPermissions() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;

            this.isAllowedToAdd = pemsn.some(
              (permission) =>
                permission.permission === "Add Usergroup (a)" &&
                permission.permissionName === "true"
            );          

            this.isAllowedToDelete = pemsn.some(
              (permission) =>
                permission.permission === "Delete Usergroup (a)" &&
                permission.permissionName === "true"
            );
            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Update Usergroup (a)" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getuserGroups() {
    const data = {
      requestType: "USERGROUP_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.userGroupListDatasource = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onParamsToolBarPreparingN(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Add",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.adduserGroups.bind(this),
      },
    });
  }

  adduserGroups() {
    if(!this.isAllowedToAdd){
      this.toastr.warning("You don't have permissions", "Failed")
        return;
      }
    this.paramwinfrm.reset();
    this.showAddButton = true;
    this.showUpdateButton = false;
    this.paramswinpnlN = true;
  }

  postData() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all fields");
      return;
    }

    const data = {
      requestType: "USERGROUP_CREATE",
      name: this.paramwinfrm.get("hname").value,

      description: this.paramwinfrm.get("description").value,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getuserGroups();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  update() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error(
        "Enter all required fields to proceed and Make sure phone numbers have 10 digits"
      );
      return;
    }

    const data = {
      requestType: "USERGROUP_UPDATE",
      name: this.paramwinfrm.get("hname").value,

      description: this.paramwinfrm.get("description").value,

      id: this.Id,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getuserGroups();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  delete() {
    const result = confirm("Are you sure you want to delete?", "");

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "USERGROUP_DELETE",
          id: this.Id,
        };

        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.toastr.success(response.statusMessage);
              this.getuserGroups();
              this.paramswinpnlN = false;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  previewDetails(e) {
    console.log(e);
    if (e.data) {
      this.showAddButton = false;
      this.showUpdateButton = true;

      this.hname = e.data.usergroupName;

      this.description = e.data.usergroupDesc;
      this.Id = e.data.usergroupId;
      this.paramswinpnlN = true;
    } else {
      this.showAddButton = true;
      this.showUpdateButton = false;
    }
  }
}
