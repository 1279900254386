import { Component, OnDestroy, OnInit } from "@angular/core";
import CryptoJS from "crypto-js";
import { AppSettings } from "src/app/app-settings";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-preview-invoices",
  templateUrl: "./preview-invoices.component.html",
  styleUrls: ["./preview-invoices.component.scss"],
})
export class PreviewInvoicesComponent
  extends SharedClassComponent
  implements OnInit
{
  now: Date = new Date();
  billItemsDatasource = [];
  institutionName = this.authService.getUserDetails().spName;
  institutionCode = this.authService.getUserDetails().spId;
  title = "Create Bill";
  billInformation: any;

  billId = null;
  invoiceList: any;
  hideButtonContolNumber: boolean = false;

  ngOnInit() {
    // this.billInformation = data[0];
    console.log(this.billInformation);
    this.appInfo.setTitle(this.title);

    this.invoiceList = this.getUInvoiceDetails();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  getUInvoiceDetails() {
    let temp = sessionStorage.getItem(AppSettings.invoiceKey);
    if (temp === null || temp === undefined) {
      return JSON.parse(temp);
    }
    temp = this.decryptString(sessionStorage.getItem(AppSettings.invoiceKey));
    temp = JSON.parse(JSON.stringify(temp));
    return temp;
  }

  printInvoice() {
    this.router.navigate(["/invoice-print"], {
      queryParams: { invoiceId: this.invoiceList.invoiceId },
      queryParamsHandling: "merge",
    });
  }
  getInvoiceById(billId) {
    const data = {
      requestType: "CLEARED_BILLS_GET",
      billid: billId,
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        this.spinner.hide();
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data.clearedBills[0];

          this.billInformation = data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  createBill() {
    const invoiceNumber=this.invoiceList.invoiceNo;
    const { invoiceId,invoiceNo, isControlNumberSubmitted, serviceList, ...data } =
      this.invoiceList;
    const rawRequest = { ...data };
    

    const { ...rest } = this.invoiceList;
    const newData = {
      ...rest,
      
      isControlNumberSubmitted: true,
    };
  
    this.spinner.show();
    this.utilities.postServiceCall(rawRequest).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();

        if (servRes.statusId == 2000) {
          this.toastr.success("Bill has been created successfully.");
          // this.router.navigate(['/generated-bills']);

          const data = servRes.data;         
          data.invoiceNo=invoiceNumber;
       

   
          this.billItemsDatasource = [];
          if (data.billItems.length < 1) {
            this.billItemsDatasource.push({
              itemAmount: data.billAmount,
              itemDesc: data.billDesc,
              itemCatName: data.catName,
              itemCurrency: data.currency
            });
          } else {
            for (const iterator of data.billItems) {
              this.billItemsDatasource.push({
                itemAmount: iterator.amount,
                itemDesc: iterator.desc,
                itemCatName: iterator.categoryName,
                itemCurrency: data.currency
              });
            }
          }
          this.hideButtonContolNumber = true;
          sessionStorage.removeItem(AppSettings.billDetailsKey);
          sessionStorage.removeItem(AppSettings.billItemsKey);
          sessionStorage.removeItem(AppSettings.bulkBillerKey);
          sessionStorage.removeItem(AppSettings.bulkBillerFlag);
          sessionStorage.setItem(AppSettings.bulkBillerFlag,  '0');
          sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(data));
          sessionStorage.setItem(AppSettings.billItemsKey, JSON.stringify(this.billItemsDatasource));
          this.router.navigate(['/bill-payment-form']);
          sessionStorage.setItem(
            AppSettings.invoiceKey,
            this.encryptionService.encryptString(newData)
          );
          // this.getUInvoiceDetails();
      
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  decryptString(data) {
    const bytes = CryptoJS.AES.decrypt(data, AppSettings.XPR_TOS_KEY_TEST);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
}
