import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-expired-bill-report",
  templateUrl: "./expired-bill-report.component.html",
  styleUrls: ["./expired-bill-report.component.scss"],
})
export class ExpiredBillReportComponent
  extends SharedClassComponent
  implements OnInit
{
  title = "Expired Bills Report";
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];
  customerList: any;
  departmentList: any;
  branchList: any;
  currencyList = ["TZS", "USD"];
  spCatList: any;
  ngOnInit() {
    //  this.spinner.show();
    this.toastr.info("Data loading in progress", "Please wait");
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);

    this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
    this.paramwinfrm = new FormGroup({
      customerId: new FormControl(""),
      department: new FormControl(""),
      branch: new FormControl(""),
      revenueSource: new FormControl(""),
      currency: new FormControl(""),
      startDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
      endDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
    });

    const dd = String(this.today.getDate()).padStart(2, "0");
    const mm = String(this.today.getMonth() + 1).padStart(2, "0"); // January is 0
    const yyyy = this.today.getFullYear();

    //  this.currentDate = dd + '-' + mm + '-' + yyyy;
    this.currentDate = yyyy + "-" + mm + "-" + dd;
    this.getExpiredBillRange();
    // Controls the datagrid height and max rows to display
    this.observerCall();
    this.currentUser = this.authService.getUserDetails().username;
    this.getCustomerList();
    this.getBranchList();
    this.getDepartmentList();
    this.getCategoryBySp()
  }



  getCategoryBySp() {
    const data = {
      requestType: "SP_SERVICE_CATEGORY_LIST",
      spId: sessionStorage.getItem('spId'),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.spCatList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }




  getCustomerList() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.customerList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  getBranchList() {
    const data = {
      requestType: "BRANCH_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.branchList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  getDepartmentList() {
    const data = {
      requestType: "USERGROUP_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.departmentList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getExpiredBillRange() {
    this.spinner.show();

    const startDatestring =
      this.today.getFullYear() +
      "-" +
      ("0" + (this.today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + this.today.getDate()).slice(-2);
    const dt = new Date(startDatestring).setDate(
      new Date(startDatestring).getDate() + 1
    );
    const dd = String(new Date(dt).getDate()).padStart(2, "0");
    const mm = String(new Date(dt).getMonth() + 1).padStart(2, "0"); // January is 0
    const yyyy = new Date(dt).getFullYear();
    const endDatestring = yyyy + "-" + mm + "-" + dd;
    const data = {
      requestType: "EXPIRED_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId: sessionStorage.getItem("spId"),
      customerId: this.paramwinfrm.get("customerId").value != null ? this.paramwinfrm.get("customerId").value.toString() : "",
      department: this.paramwinfrm.get("department").value != null ? this.paramwinfrm.get("department").value.toString() : "",
      branch: this.paramwinfrm.get("branch").value != null ? this.paramwinfrm.get("branch").value.toString() : "",
      revenueSource: this.paramwinfrm.get("revenueSource").value,
      currency: this.paramwinfrm.get("currency").value,
    };

    this.utilities.postServiceCall(data).subscribe(
      (response) => {
        const res = response.json();
        this.datasource = res.data.expiredBills;
        this.spinner.hide();
        this.toastr.info("Data has finished loading", "Process Completed");
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  getExpiredBillsByDateRange() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and end Date is required.");
      return;
    }
    this.spinner.show();
    const startDatestring =
      this.paramwinfrm.get("startDate").value.getFullYear() +
      "-" +
      ("0" + (this.paramwinfrm.get("startDate").value.getMonth() + 1)).slice(
        -2
      ) +
      "-" +
      ("0" + this.paramwinfrm.get("startDate").value.getDate()).slice(-2);

    const dt = new Date(this.paramwinfrm.get("endDate").value).setDate(
      new Date(this.paramwinfrm.get("endDate").value).getDate() + 1
    );

    const dd = String(new Date(dt).getDate()).padStart(2, "0");
    const mm = String(new Date(dt).getMonth() + 1).padStart(2, "0"); // January is 0
    const yyyy = new Date(dt).getFullYear();
    const endDatestring = yyyy + "-" + mm + "-" + dd;

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }


    const data = {
      requestType: "EXPIRED_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId: sessionStorage.getItem("spId"),
      customerId: this.paramwinfrm.get("customerId").value != null ? this.paramwinfrm.get("customerId").value.toString() : "",
      department: this.paramwinfrm.get("department").value != null ? this.paramwinfrm.get("department").value.toString() : "",
      branch: this.paramwinfrm.get("branch").value != null ? this.paramwinfrm.get("branch").value.toString() : "",
      revenueSource: this.paramwinfrm.get("revenueSource").value,
      currency: this.paramwinfrm.get("currency").value,
    };
    this.utilities.postServiceCall(data).subscribe(
      (response) => {
        const res = response.json();
        this.datasource = res.data.expiredBills;
        this.spinner.hide();
        this.toastr.info("Data has finished loading", "Process Completed");
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        type: "default",
        onClick: refresh_action.bind(this),
      },
      
    }
    ,
    {
      location: "before",
      widget: "dxButton",
      options: {
        text: "Print",
        type: "default",
        icon: "fa fa-print",
        onClick: this.printReport.bind(this),
      },
    });
  }
}
