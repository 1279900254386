import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-exchange-rate",
  templateUrl: "./exchange-rate.component.html",
  styleUrls: ["./exchange-rate.component.scss"],
})
export class ExchangeRateListComponent
  extends SharedClassComponent
  implements OnInit
{
  ExchangeRateListDatasource: any;
  paramswinpnlN: boolean = false;
  showAddButton = false;
  showUpdateButton = false;

  isAllowedToAdd: Boolean = false;
  isAllowedToDelete: Boolean = false;
  isAllowedToUpdate: Boolean = false;
  name: any;

  Id: any;

  amount: any;
  title: string = "Exchange Rate";

  ngOnInit() {
    this.getUseWithPermissions();
    this.getExchangeRate();

    this.paramwinfrm = new FormGroup({
      amount: new FormControl("", Validators.compose([Validators.required])),
    });
  }

  getUseWithPermissions() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;

            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Update Exchange Rate (a)" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }
  getExchangeRate() {
    const data = {
      requestType: "CONFIGURATION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.ExchangeRateListDatasource = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onParamsToolBarPreparingN(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Add",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.adduserGroups.bind(this),
      },
    });
  }

  adduserGroups() {
    if (!this.isAllowedToAdd) {
      this.toastr.warning("You don't have permissions", "Failed");
      return;
    }

    this.paramwinfrm.reset();
    this.showAddButton = true;
    this.showUpdateButton = false;
    this.paramswinpnlN = true;
  }

  update() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Branch name is required");
      return;
    }

    const result = confirm(
      "Are you sure you want to Update Exchange Rate?",
      "Approval"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "CONFIGURATION_UPDATE",
          value: this.paramwinfrm.get("amount").value,

          id: this.Id,
        };

        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.toastr.success(response.statusMessage);
              this.getExchangeRate();
              this.paramswinpnlN = false;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  previewDetails(e) {
    if (e.data) {
      this.showAddButton = false;
      this.showUpdateButton = true;
      const value= e.data.value ? e.data.value : 0;
          this.paramwinfrm.get("amount").setValue(value)

      this.Id = e.data.id;
      this.paramswinpnlN = true;
    } else {
      this.showAddButton = true;
      this.showUpdateButton = false;
    }
  }
}
