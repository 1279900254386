


import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppSettings } from 'src/app/app-settings';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-genereted-invoices',
  templateUrl: './genereted-invoices.component.html',
  styleUrls: ['./genereted-invoices.component.scss']
})
export class GeneretedInvoicesComponent  extends SharedClassComponent implements OnInit   {
  title: string = 'Generated Invoices';

  tab_paneldata: any = [
    {
    ID: 1,
    icon: 'fa fa-check-circle',
    name: 'All Invoices'
    },
    {
    ID: 2,
    icon: 'fa fa-check-circle',
    name: 'Paid Invoices'
    },
    {
    ID: 3,
    icon: 'fa fa-check-circle',
    name: 'Unpaid Invoices'
    }
  ];
  allIncoicesList: any;
  paidInvoicesList: any;
  unPaidInvoicesList: any;
  allClearedBillsEndPoint  = 'bills/cleared/all';
  getClearedBillsByDateRangeEndpoint = 'bills/cleared/all/date/specific';

  getAllBillsEndpoint = 'bills/sp';
  validBills = [];
  clearedBills = [];
  inactiveBills = [];

  endpointName = 'portal/request';
  currentDate: any;
  currentUser;
  now: Date = new Date();

  popUpDialogVisible = false;
  openSummaryPopDialog = false;

  clearedBillsDataSource = [];
  incomingBills = [];
  billItemsDatasource = [];

  tzsAmountCounter: number = 0;
  usdAmountCounter: number = 0;
  tzsTxnCounter = 0;
  usdTxnCounter = 0;

  reportSummaryDataSource = [{
    totalTxn: 0,
    tzsTxn: 0,
    usdTxn: 0,
    tAmtTzs: 0,
    tAmtUsd: 0,
    recordDate: ''
  }];

  oneDayAhead = new Date();
  today = new Date();
  userFirstName = this.authService.getUserDetails().firstName;
  userId = this.authService.getUserDetails().userId;
  isAllowedToUpdate: any;


  ngOnInit() {
    this.getServiceProviderAccount()
  //  this.spinner.show();
   this.toastr.info('Data loading in progress', 'Please wait');
   
   this.appInfo.setTitle(this.title);
   this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
   this.paramwinfrm = new FormGroup({
     startDate: new FormControl(this.today, Validators.compose([Validators.required])),
     endDate: new FormControl(this.today, Validators.compose([Validators.required]))
   });

  
   this.getAllInvoicesListUnPaid();
   this.getAllInvoicesListPaid();
   this.getAllInvoicesList();

   this.observerCall();

  }

  getServiceProviderAccount() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;       
            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Can Update Invoice" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }
  customizeDate(data) {
    return 'From: ' + new DatePipe('en-US').transform(data.value, 'dd-MM-yyyy') + ' to ' + this.currentDate;
}
closePopUp() {
  this.popUpDialogVisible = false;
}
openSummaryDialog() {
  this.openSummaryPopDialog = true;
}
closeOpenSummaryPopDialog() {
  this.openSummaryPopDialog = false;
}


getAllInvoicesList() {
  const data = {
    requestType: "INVOICES_LIST",
  };
  this.spinner.show();
  this.utilities.postServiceCall(data).subscribe(
    (res) => {
      const response = res.json();
      if (response.statusId == 2000) {
        this.allIncoicesList = response.data;
      } else {
        this.toastr.error(response.statusMessage, "Error");
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
      this.toastr.error(
        "Something went wrong please try again",
        "Request Failed"
      );
    }
  );
}

getAllInvoicesListPaid() {
  const data = {
    requestType: "INVOICES_LIST_PAID",
  };
  this.spinner.show();
  this.utilities.postServiceCall(data).subscribe(
    (res) => {
      const response = res.json();
      if (response.statusId == 2000) {
        this.paidInvoicesList = response.data;
      } else {
        this.toastr.error(response.statusMessage, "Error");
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
      this.toastr.error(
        "Something went wrong please try again",
        "Request Failed"
      );
    }
  );
}

getAllInvoicesListUnPaid() {
  const data = {
    requestType: "INVOICES_LIST_UNPAID",
  };
  this.spinner.show();
  this.utilities.postServiceCall(data).subscribe(
    (res) => {
      const response = res.json();
      if (response.statusId == 2000) {
        this.unPaidInvoicesList = response.data;
      } else {
        this.toastr.error(response.statusMessage, "Error");
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
      this.toastr.error(
        "Something went wrong please try again",
        "Request Failed"
      );
    }
  );
}


amountCounter(data) {
  this.tzsAmountCounter = 0;
  this.usdAmountCounter = 0;
  this.tzsTxnCounter = 0;
  this.usdTxnCounter = 0;
  for (const el of data) {
    if (el.currency == 'TZS') {
      this.tzsAmountCounter += el.amount;
      this.tzsTxnCounter += 1;
    }
    if (el.currency == 'USD') {
      this.usdAmountCounter += el.amount;
      this.usdTxnCounter += 1;
    }
  }
}

toolBarPreparing(e, refresh_action) {
  e.toolbarOptions.items.unshift(
    {
      location: 'before',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'help',
        hoverStateEnabled: false,
        hint: 'Double click on the rows to Preview & Print Bill receipt'
      }
    },
    {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
        onClick: refresh_action.bind(this)
      }
    }
  );
}


printInvoice(invoiceId){
  this.router.navigate(['/invoice-print'], { queryParams: { invoiceId: invoiceId }, queryParamsHandling: 'merge'});


}


previewBill(e) {
  const result = confirm('Please click the appropriate action to continue.','Action');
}

editInvoice(e) {
  // sessionStorage.removeItem(AppSettings.billEditKey);
  // sessionStorage.setItem(AppSettings.billEditKey, JSON.stringify(e));
  this.router.navigate(['/invoice/create'], { queryParams: { invoiceId: e.ID }, queryParamsHandling: 'merge'});
}


invoiceDetails(e, isUnpaid:Boolean) {
  const id = e.data.ID;
  console.log(id);
  this.router.navigate(["/invoice-details"], {
    queryParams: { invoiceId: id, isUnpaid: isUnpaid},
    queryParamsHandling: "merge",
  });
}

}
