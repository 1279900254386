import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import CryptoJS from "crypto-js";
import { AppSettings } from "src/app/app-settings";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-print-debit-credit-notes",
  templateUrl: "./print-debit-credit-note.component.html",
  styleUrls: ["./print-debit-credit-note.component.scss"],
})
export class PrintDebitCreditNoteComponent
  extends SharedClassComponent
  implements OnInit
{
  isInvoicePrinted: boolean;
  invoiceId: any;
  currentDate: Date = new Date();
  invoiceDataWithCreditDebitNote: any;
  ngOnInit() {
    this.invoiceDataWithCreditDebitNote = this.getUInvoiceDetails();  

  }

getNumber(value) {
  return !value ? Number(value) : value;
  
}
   getUInvoiceDetails() {
      let temp = sessionStorage.getItem(AppSettings.debitCredit);
      if (temp === null || temp === undefined) {
        return JSON.parse(temp);
      }
      temp = this.decryptString(sessionStorage.getItem(AppSettings.debitCredit));
      temp = JSON.parse(JSON.stringify(temp));
      return temp;
    }


  printInvoiceReceipt() {
    this.isInvoicePrinted = true;
    let popUpWindow;
    const innerContents = document.getElementById("print-container2").innerHTML;
    popUpWindow = window.open(
      "",
      "_blank",
      "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
    );
    popUpWindow.document.write(`
        <html lang="en">
        <head>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=sans-serif:wght@300;400&display=swap" rel="stylesheet">
            <style>
                body {
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #333;
                    font-family: 'sans-serif', sans-serif;
                }

             .invoice-container {
    font-family: Arial, sans-serif;
    font-weight: bold;
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  .invoice-header {
    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;
    margin-top: 3em;
  }
  
  .invoice-left {
    display: flex;
    margin-top: 30px;
  }
  
  .logo {
    height: 80px;
    width: auto;
    margin-right: 10px;
  }


  .invoice-right {

    width: 30%;
    text-align: right;
  }
  
  .invoice-info-box table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-info-box td {
    padding: 5px;
    border: 1px solid #000;
  }



  .invoice-right-info {

    width: 50%;
    text-align: center;
  }

  
 
  


  .invoice-right-info {
    text-align: center;
  }
  
  .invoice-right-info td {
    // padding: 5px;
    border: 1px solid #000;
  }
  
  .invoice-right-info table {
    width: 100%;
    border-collapse: collapse;
  }

  .invoice-left-info
  {
      margin-top: 30px;
      width: 55%;
    }
  
      
    .invoice-left-info table {
      width: 100%;
      border-collapse: collapse;
    }
  
    
  
  .invoice-left-info td {
    padding: 5px;
    border: 1px solid #000;
  }

  .invoice-left-info td.larger {
    padding-bottom: 83px; 
}

  .invoice-dates {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .invoice-dates table {
    border-collapse: collapse;
  }
  
  .invoice-dates td {
    padding: 5px;
    border: 1px solid #000;
    text-align: left;
  }
  
  .invoice-details table {
    width: 100%;

    border-collapse: collapse;
    margin-top: 20px;

  }
  
  .invoice-details th{
    border: 1px solid #000;
    padding: 8px;
    text-align: left;

  }


  .invoice-details td {
    border: 1px solid #000;
    padding: 8px;
    text-align: start;
    height: 400px;
    vertical-align: top;
}


.item-details table {
  width: 100%;

  border-collapse: collapse;
  margin-top: 20px;

}

.item-details th{
  border: 1px solid #000;
  padding: 8px;
  text-align: left;

}


.item-details td {
  border: 1px solid #000;
  padding: 8px;
  text-align: start;
  height: 150px;
  vertical-align: top;
}
  
  .invoice-summary {
    display: flex;
    justify-content: space-between;
    
  }
  .summary-right {
    width: 35%;
 
  }

  .summary-left {
    width: 65%;
    margin-top: 5px;
  }

  .summary-left table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
    border-right: none;
  }
  
  .summary-left td,
  .summary-left th {
    border: none; 
    text-align: center;
    padding: 8px;
  }
  .summary-left p {
    font-size: 17px !important;
    text-align: center;
  }
  
  .summary-right table {
    width: 100%;
    border-top: none;
    border-collapse: collapse;
  }
  
  .summary-right td {
    padding: 5px;
    border: 1px solid #000;
    text-align: right;
  }
  
  .invoice-footer {
    border: 1px solid #000;
width: 55%;
text-align: center;
    padding-top: 15px;
    margin-top: 20px;
  }


  .company-info {
    border: 1px solid #000;
    width: 55%;
    text-align: center;
    padding-top: 5px;
margin-top: 25px;
  }
  
  .company-info table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .company-info td {
    padding: 8px;
  }

  .company-info td:first-child {
    border-right: 1px solid #000;
  
  }
  
  
  .company-info td.separator {
    border-right: 1px solid #000; 
    padding-right: 10px; 
    text-align: left;
  }
  .large-text {
  font-size: 15px;
  }
.td-pad {
  padding: 10px 3px 10px 0 !important;
}


.summary-right td.td-pad:first-child {
  border-right: none !important; 
  text-align: left !important; 
  padding-left: 3px !important; 
  font-size: 17px !important;
}

.summary-right td.td-pad:nth-child(2) {

  text-align: right !important;
}


  
  .footer-left {
    width: 100%;
  }
  
  .footer-right {
    width: 48%;
    margin-top: 3em;
  }
  
  .footer-right table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .footer-right td {
    padding: 5px;
    border: 1px solid #000;
    text-align: right;
  }
  
  

            

                @media (max-width: 576px) {
                    .btn_control_block {
                        display: flex;
                        flex-direction: row;
                    }
                }

                @media print {
                    .page-break { display: block; page-break-before: always; }

                    @page {
                        size: A4;
                        margin: 0mm;
                    }

                    html, body {
                        width: 1024px;
                    }

                    body {
                        margin: 0 auto;
                        line-height: 1em;
                        word-spacing: 2px;
                        letter-spacing: 0.2px;
                        font-size: 17px;
                        color: black;
                        width: 100%;
                        float: none;
                    }

               

                    .footer {
                        bottom: 0;
                        width: 100%;
                        text-align: center;
                    }

             

                    h1 {
                        font: 28px "Times New Roman", Times, serif;
                    }

                    h2 {
                        font: 25px "Times New Roman", Times, serif;
                    }

                    h3 {
                        font: 20px "Times New Roman", Times, serif;
                    }

                    a:link, a:visited {
                        color: #781351;
                        background: transparent;
                        text-decoration: none;
                    }

                    a[href]:after {
                        content: "" !important;
                    }

                    a[href^="http://"] {
                        color: #000;
                    }

                    #header {
                        height: 75px;
                        font-size: 24pt;
                        color: black;
                    }
                }
            </style>
        </head>
        <body onload="window.print()">${innerContents}</body>
        </html>
    `);
    popUpWindow.document.close();
    sessionStorage.removeItem(AppSettings.billDetailsKey);
  }

   decryptString(data) {
      const bytes = CryptoJS.AES.decrypt(data, AppSettings.XPR_TOS_KEY_TEST);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
}
