import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, HeaderComponent } from './shared/components';
import {
  AuthGuardService,
  AuthGuardServiceAdminsOnly
} from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import { AnQrcodeModule } from 'an-qrcode';
import {
  DxDataGridModule,
  DxFormModule,
  DxContextMenuModule,
  DxMenuModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxButtonModule,
  DxPopupModule,
  DxActionSheetModule,
  DxFileUploaderModule,
  DxNumberBoxModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxScrollViewModule,
  DxHtmlEditorModule,
  DxDropDownBoxModule,
  DxTagBoxModule,
  DxRadioGroupModule,
  DxTabPanelModule,
  DxBoxModule,
  DxPieChartModule,
  DxChartModule,
  DxValidatorModule,
  DxLookupModule,
  DxSwitchModule,
  DxAccordionModule,
  DxDropDownButtonModule
} from 'devextreme-angular';
import { UserManagementComponent } from './pages/system-administration/user-management/user-management.component';
import { UserRolesComponent } from './configurations/user-management/user-roles/user-roles.component';
import { AccessLevelComponent } from './configurations/user-management/access-level/access-level.component';
import { NavigationTypesComponent } from './configurations/systemaccess/navigation-types/navigation-types.component';
import { NavigationLevelsComponent } from './configurations/systemaccess/navigation-levels/navigation-levels.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogueComponent } from './utilities/confirmation-dialogue/confirmation-dialogue.component';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from './shared/services/utilities.service';
import { SharedClassComponent } from './configurations/shared-class/shared-class.component';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { UserStatusesComponent } from './configurations/user-management/user-statuses/user-statuses.component';
import { BankBranchesComponent } from './configurations/systemaccess/bank-branches/bank-branches.component';
import { BillValidationComponent } from './bill-management/bill-validation/bill-validation.component';
import { NavigationsComponent } from './configurations/systemaccess/navigations/navigations.component';
import { PermissionsComponent } from './configurations/systemaccess/permissions/permissions.component';
import { RevenueTransactionComponent } from './bill-management/reports/revenue-transaction.component';
import { CurrencyDenominationsComponent } from './configurations/currency-denominations/currency-denominations.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountTypesComponent } from './account/account-types/account-types.component';
import { AccountsComponent } from './account/accounts/accounts.component';
import { GatewayComponent } from './gateway/gateway.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { TemporaryLoginComponent } from './shared/components/temporary-login/temporary-login.component';
import { DataVisualizationComponent } from './data-visualization/data-visualization.component';
import { BillDetailsComponent } from './bill-management/bill-details/bill-details.component';
import { BillCheckComponent } from './bill-management/bill-check/bill-check.component';
import { IncomingTissComponent } from './rtgs/reports/incoming-tiss/incoming-tiss.component';
import { OutgoingTissComponent } from './rtgs/reports/outgoing-tiss/outgoing-tiss.component';
import { OutgoingTissResponseComponent } from './rtgs/reports/outgoing-tiss-response/outgoing-tiss-response.component';
import { ReconciliationStatusComponent } from './reconciliation/reconciliation-status/reconciliation-status.component';
import { BillPaymentFormComponent } from './bill-payment-form/bill-payment-form.component';
import { BulkBillCreateComponent } from './bulk-bill-create/bulk-bill-create.component';
import { ServiceProviderAccountsComponent } from './service-provider-accounts/service-provider-accounts.component';
import { BillReportDateRangeComponent } from './bill-management/reports/bill-report-date-range/bill-report-date-range.component';
//import { BillDetailsByControlNumberComponent } from './bill-management/reports/bill-details-by-control-number/bill-details-by-control-number.component';
import { ExpiredBillReportComponent } from './bill-management/reports/expired-bill-report/expired-bill-report.component';
import { AuditTrailReportComponent } from './bill-management/reports/audit-trail-report/audit-trail-report.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InformationComponent } from './information/information.component';
import { ClearedBillReportComponent } from './bill-management/reports/cleared-bill-report/cleared-bill-report.component';
import { ServiceEntryComponent } from './configurations/service-entry/service-entry.component';
import { CustomersListComponent } from './configurations/customers-list/customers-list.component';
import { CreateInvoiceComponent } from './bill-management/create-invoice/create-invoice.component';
import { GeneretedInvoicesComponent } from './invoice-management/genereted-invoices/genereted-invoices.component';
import { PrintInvoicesComponent } from './invoice-management/print-invoices/print-invoices.component';
import { PreviewClearedBillsComponent } from './bill-management/preview-cleared-bills/preview-cleared-bills.component';
import { ViewClearedBillsComponent } from './bill-management/view-cleared-bills/view-cleared-bills.component';
import { CustomerInfoListComponent } from './pages/customer-info-list/customer-info-list.component';
import { GetCustomerInfoComponent } from './pages/get-customer-info/get-customer-info.component';
import { ServiceEntriesReportComponent } from './pages/service-entries-report/service-entries-report.component';
import { PreviewInvoicesComponent } from './invoice-management/preview-invoices/preview-invoices.component';
import { InvoiceDetailsComponent } from './invoice-management/invoice-details/invoice-details.component';
import { UserDetailsComponent } from './user-management/user-details/user-details.component';
import { UsersListComponent } from './user-management/users-list/users-list.component';
import { UserGroupListComponent } from './configurations/usergroup/usergroup.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { AuthorizationGuard } from './shared/services/authorization.guard';
import { ReconExceptionReportComponent } from './reconciliation/recon-exception-report/recon-exception-report.component';
import { ReconTransactionComponent } from './reconciliation/recon-transaction/recon-transaction.component';



const routes: Routes = [
  {
    path: 'reports/bills/listing',
    component: BillReportDateRangeComponent,
          canActivate: [AuthGuardService, AuthorizationGuard],
        data: { expectedPermission: ["Bill Listing Report (r)"] },
    
     
  },


  {
    path: 'reports/bills/cleared',
    component: ClearedBillReportComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Bill Cleared Report (r)"] },

  },
  {
    path: 'reports/bills/expired',
    component: ExpiredBillReportComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Bill Expired Report (r)"] },

  },
  {
    path: 'reports/audit-trail',
    component: AuditTrailReportComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Audit Trail Report (r)"] },

  },
  {
    path: 'recon-status',
    component: ReconciliationStatusComponent,
    canActivate: [AuthGuardService],
    
  },


  {
    path: 'recon-transaction',
    component: ReconTransactionComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Transaction"] },   
  },

  {
    path: 'recon-exception-report',
    component: ReconExceptionReportComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Exception report"] },
    
  },


  {
    path: 'outgoing-tiss',
    component: OutgoingTissComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'currency-denominations',
    component: CurrencyDenominationsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'bills/status',
    component: BillCheckComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'utilities/confirmation-dialogue',
    component: ConfirmationDialogueComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'navigation-levels',
    component: NavigationLevelsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'navigation-types',
    component: NavigationTypesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'access-level',
    component: AccessLevelComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'user-roles',
    component: UserRolesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'bill-payment-form',
    component: BillPaymentFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'services/providers/accounts',
    component: ServiceProviderAccountsComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'bulk/bill/create',
    component: BulkBillCreateComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Create Bill"] },
  },
  {
    path: 'display-data',
    component: DisplayDataComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'navigations-setup',
    component: NavigationsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'permissions-setup',
    component: PermissionsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'generated-bills',
    component: RevenueTransactionComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Generated Bills"] },
  },
  {
    path: 'data-visualization',
    component: DataVisualizationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginFormComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: "users-list",
    component: UsersListComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View System Users"] },

  },
  {
    path: "fumis/forbidden",
    component: ForbiddenComponent,
    canActivate: [AuthGuardService],
  },
{
    path: "user-details/:id",
    component: UserDetailsComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View System Users"] },
  },

  {
    path: "user-details",
    component: UserDetailsComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View System Users"] },
  },
  {
    path: 'user-statuses',
    component: UserStatusesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'register/institution',
    component: BankBranchesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'bill/create',
    component: BillValidationComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Create Bill"] },
  },
  {
    path: 'invoice/create',
    component: CreateInvoiceComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["Create Invoice"] },
  },

  {
    path: 'genereted-invoices',
    component: GeneretedInvoicesComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Generated Invoice"] },
  },

  {
    path: 'invoice-print',
    component: PrintInvoicesComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'invoice-details',
    component: InvoiceDetailsComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'preview-invoice',
    component: PreviewInvoicesComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'view-cleared-bills',
    component: ViewClearedBillsComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'preview-cleared-bills',
    component: PreviewClearedBillsComponent,
    canActivate: [AuthGuardService]
  },


  {
    path: 'currency-denominations',
    component: CurrencyDenominationsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'sp/categories',
    component: ServiceProviderComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Service Categories"] },

  },
  {
    path: 'service-entry',
    component: ServiceEntryComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Service Entry (a)"] },

  },
  {
    path: 'usergroup',
    component: UserGroupListComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Usergroup (a)"] },

  },


  
  {
    path: 'customers-list',
    component: CustomersListComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Customer List (a)"] },

  },

  {
    path: 'get-customer-info',
    component: GetCustomerInfoComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'service-entries-report',
    component: ServiceEntriesReportComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Service Entry"] },

  },

  {
    path: 'customer-info-list',
    component: CustomerInfoListComponent,
    canActivate: [AuthGuardService, AuthorizationGuard],
    data: { expectedPermission: ["View Customers"] },

  },
  {
    path: 'gateways',
    component: GatewayComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'accounts',
    component: AccountsComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'account-types',
    component: AccountTypesComponent,
    canActivate: [AuthGuardServiceAdminsOnly],
    data: {
      expectedRole: [801, 802]
    }
  },
  {
    path: 'change-temporary-password',
    component: TemporaryLoginComponent
  },
  {
    path: 'information',
    component: InformationComponent
  },
  {
    path: '**',
    redirectTo: 'home',
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,  { useHash: true }),
    DxDataGridModule,
    DxFormModule,
    DxContextMenuModule,
    DxMenuModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule,
    DxActionSheetModule,
    DxFileUploaderModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxSwitchModule ,
    DxContextMenuModule,
    DxMenuModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    NgbModalModule,
    DxTabPanelModule,
    DxBoxModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxPieChartModule,
    DxChartModule,
    DxDropDownButtonModule,

    DxAccordionModule,
    NgHttpLoaderModule.forRoot(),
    ModalDialogModule.forRoot(),
    DxRadioGroupModule,
    DxValidatorModule,
    DxLookupModule,
    AnQrcodeModule,
  ],
  providers: [AuthGuardService, UtilitiesService, AuthGuardServiceAdminsOnly],
  exports: [RouterModule],
  declarations: [
    ServiceEntriesReportComponent,
    GetCustomerInfoComponent,
    CustomerInfoListComponent,
    UsersListComponent,
    UserGroupListComponent,
    UserDetailsComponent,
    ViewClearedBillsComponent,
    PreviewClearedBillsComponent,
    GeneretedInvoicesComponent,
    PrintInvoicesComponent,
    BillCheckComponent,
    HomeComponent,
    ProfileComponent,
    DisplayDataComponent,
    UserManagementComponent,
    UserRolesComponent,
    AccessLevelComponent,
    NavigationTypesComponent,
    NavigationLevelsComponent,
    SharedClassComponent,
    UserStatusesComponent,
    BankBranchesComponent,
    BillValidationComponent,
    CreateInvoiceComponent,
    NavigationsComponent,
    PermissionsComponent,
    RevenueTransactionComponent,
    CurrencyDenominationsComponent,
    SettingsComponent,
    AccountTypesComponent,
    AccountsComponent,
    GatewayComponent,
    ServiceProviderComponent,
    ServiceEntryComponent,
    CustomersListComponent,
    TemporaryLoginComponent,
    DataVisualizationComponent,
    BillDetailsComponent,
    IncomingTissComponent,
    OutgoingTissComponent,
    OutgoingTissResponseComponent,
    ReconciliationStatusComponent,
    
  ReconTransactionComponent,
  ReconExceptionReportComponent,
    BillPaymentFormComponent,
    BulkBillCreateComponent,
    ServiceProviderAccountsComponent,
    BillReportDateRangeComponent,
    ClearedBillReportComponent,
    //BillDetailsByControlNumberComponent,
    ExpiredBillReportComponent,
    AuditTrailReportComponent,
    UserProfileComponent,
    InformationComponent,
    PreviewInvoicesComponent,
    InvoiceDetailsComponent,
    ForbiddenComponent
  ]
})
export class AppRoutingModule {}
