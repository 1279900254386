import { Component, OnInit } from "@angular/core";
import { UtilitiesService } from "src/app/shared/services/utilities.service";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import { ModalDialogService } from "ngx-modal-dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent
  extends SharedClassComponent
  implements OnInit
{
  paramswinpnl: boolean = false;
  paramwinfrm: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  response: any;
  title: string = "Service Provider Users";
  model_name: string = "sp/users";
  authorizeUserEndpointCall = "users/authorize";
  resetUserEndPointCall = "users/reset";
  userPermissions = [];
  LoggeduserPermissions = [];
  userID: string = "";
  hideUserDetailsControls = true;
  usersStatus: any;
  permissions = [];
  updatedPermissions = [];

  selectAllReportAssignedPermisonChecked: boolean = false;
  unassignedReportPermissions = [];
  reportAssiginedPermissions = [];
  selectAllPensionChecked: boolean = false;
  selectAllpensionAssignedPermisonChecked: boolean = false;

  pensionAssignedPermison = [];

  selectAllUnassigned: { [key: string]: boolean } = {};
  phoneEditorOptions = {
    mask: '+255 X00 000 000',
    maskRules: {
      'X': /[1-9]/,  
      '0': /[0-9]/
    }
  };
  permissionGroups = [
    {
      name: "Invoice",
      selectAllUnassigned: false,
      permissions: [
        { permission: "Create Invoice", isGranted: false, group: "Invoice" },
        {
          permission: "Can Receive Invoice Payments",
          isGranted: false,
          group: "Invoice",
        },
        {
          permission: "Can Update Invoice",
          isGranted: false,
          group: "Invoice",
        },
        {
          permission: "View Generated Invoice",
          isGranted: false,
          group: "Invoice",
        },
      ],
      assignedPermissions: [],
      unassignedPermissions: [],
    },
    {
      name: "Bill",
      selectAllUnassigned: false,
      permissions: [
        { permission: "Create Bill", isGranted: false, group: "Bill" },

        {
          permission: "Can Cancel Bill",
          isGranted: false,
          group: "Bill",
        },
        {
          permission: "Can Update Bill",
          isGranted: false,
          group: "Bill",
        },
        {
          permission: "Can Change Bills Expiry Date",
          isGranted: false,
          group: "Bill",
        },
        {
          permission: "Can Reuse Bill",
          isGranted: false,
          group: "Bill",
        },
        { permission: "View Generated Bills", isGranted: false, group: "Bill" },
      ],
      assignedPermissions: [],
      unassignedPermissions: [],
    },

    {
      name: "Reconciliation",
      selectAllUnassigned: false,
      permissions: [
        {
          permission: "Transaction",
          isGranted: false,
          group: "Reconciliation",
        },
        {
          permission: "Exception report",
          isGranted: false,
          group: "Reconciliation",
        },
      ],
      assignedPermissions: [],
      unassignedPermissions: [],
    },
    {
      name: "Customers",
      selectAllUnassigned: false,
      permissions: [
        { permission: "View Customers", isGranted: false, group: "Customers" },

        {
          permission: "Can Add Customers",
          isGranted: false,
          group: "Customers",
        },
        {
          permission: "Can Update Customers",
          isGranted: false,
          group: "Customers",
        },
        {
          permission: "Can Delete Customers",
          isGranted: false,
          group: "Customers",
        },
      ],
      assignedPermissions: [],
      unassignedPermissions: [],
    },
    {
      name: "Service Entry",
      selectAllUnassigned: false,
      permissions: [
        {
          permission: "View Service Entry",
          isGranted: false,
          group: "Service Entry",
        },
        {
          permission: "Can Add Service Entries",
          isGranted: false,
          group: "Service Entry",
        },
        {
          permission: "Can Update Service Entries",
          isGranted: false,
          group: "Service Entry",
        },
        {
          permission: "Can Delete Service Entries",
          isGranted: false,
          group: "Service Entry",
        },
      ],
      assignedPermissions: [],
      unassignedPermissions: [],
    },
    {
      name: "Administration",
      selectAllUnassigned: false,
      permissions: [
        {
          permission: "View System Users",
          isGranted: false,
          group: "Administration",
        },
        {
          permission: "View Service Categories",
          isGranted: false,
          group: "Administration",
        },
        {
          permission: "View Customer List (a)",
          isGranted: false,
          group: "Administration",
        },
        {
          permission: "View Service Entry (a)",
          isGranted: false,
          group: "Administration",
        },
        {
          permission: "View Usergroup (a)",
          isGranted: false,
          group: "Administration",
        },
      ],
      assignedPermissions: [],
      unassignedPermissions: [],
    },
    {
      name: "Report",
      selectAllUnassigned: false,
      permissions: [
        {
          permission: "Bill Listing Report (r)",
          isGranted: false,
          group: "Report",
        },
        {
          permission: "Bill Cleared Report (r)",
          isGranted: false,
          group: "Report",
        },
        {
          permission: "Bill Expired Report (r)",
          isGranted: false,
          group: "Report",
        },
        {
          permission: "Audit Trail Report (r)",
          isGranted: false,
          group: "Report",
        },
      ],
      assignedPermissions: [],
      unassignedPermissions: [],
    },
  ];
  showPasswordAlert: boolean = false;
  statusMessage: any;
  currentPassword: string;

  ngOnInit() {
    this.appInfo.setTitle("Service Provider Users");
    this.userID = this.route.snapshot.params["id"];
    this.paramwinfrm = new FormGroup({
      requestType: new FormControl("SP_USER_CREATE", Validators.compose([])),
      username: new FormControl("", Validators.compose([Validators.required])),
      firstName: new FormControl("", Validators.compose([Validators.required])),
      middleName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      lastName: new FormControl("", Validators.compose([Validators.required])),
      usergroupId: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      spId: new FormControl("", Validators.compose([Validators.required])),
      email: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.email])
      ),
      telephone: new FormControl("", Validators.compose([])),
      mobile: new FormControl(
        "",
        Validators.compose([
          Validators.pattern(/^[1-9]\d{8}$/),
          Validators.required,
        ])
      ),
      id: new FormControl("", Validators.compose([])),
      userId: new FormControl("", Validators.compose([])),
      status_id: new FormControl("", Validators.compose([])),
      status: new FormControl("", Validators.compose([])),
    });
    if (this.userID) {
      this.userGet();
    } else {
      this.processPermissions();
    }

    this.getServiceProviderUserList();
    this.getServiceProviders();
    this.getUserGroups();

    this.observerCall();
  }

  processPermissions() {
    this.permissionGroups.forEach((group) => {
      group.assignedPermissions = group.permissions.filter((p) => p.isGranted);
      group.unassignedPermissions = group.permissions.filter(
        (p) => !p.isGranted
      );
    });
  }

  togglePermission(groupIndex: number, permissionName: string) {
    const permission = this.permissionGroups[groupIndex].permissions.find(
      (p) => p.permission === permissionName
    );
    if (permission) {
      permission.isGranted = !permission.isGranted;
      this.processPermissions();
    }
  }

  toggleAllUnassigned(event: any, groupIndex: number) {
    const group = this.permissionGroups[groupIndex];
    const newStatus = event.target.checked;
    group.unassignedPermissions.forEach(
      (permission) => (permission.isGranted = newStatus)
    );
    this.processPermissions();
  }

  userGet() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: this.userID,
    };

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          let data = response.data;

          data.usergroupId = parseInt(data.usergroupId, 10);
          data.spId = parseInt(data.spId, 10);

          if (data.permissions) {
            this.userPermissions = data.permissions;
            this.updatePermissionGroups();
          }

          if (data && data.mobile) {
        
            data.mobile = data.mobile.replace(/^\+255|^0/, '');
          }
          this.paramwinfrm.patchValue(data);

          this.currentData = data;
          this.hideUserDetailsControls = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  updatePermissionGroups() {
    this.permissionGroups.forEach((group) => {
      group.assignedPermissions = [];
      group.unassignedPermissions = [];

      group.permissions.forEach((permission) => {
        const userPermission = this.userPermissions.find(
          (p) => p.permission === permission.permission
        );

        if (userPermission && userPermission.permissionName === "true") {
          permission.isGranted = true;
          group.assignedPermissions.push(permission);
        } else {
          permission.isGranted = false;
          group.unassignedPermissions.push(permission);
        }
      });
    });
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "New User",
          type: "default",
          icon: "fa fa-plus",
          onClick: this.addNewParameter.bind(this),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "default",
          onClick: this.refreshDataGrid.bind(this),
        },
      }
    );
  }

  funcpopWidth(percentage_width) {
    if (window.innerWidth > 800) {
      return (window.innerWidth * percentage_width) / 100;
    } else {
      return window.innerWidth - 50;
    }
  }

  resetPassword() {
    const data = {
      requestType: "SP_USER_RESET",
      userId:this.userID,
    };
    this.spinner.show();

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this;
          this.toastr.success("Operation succesfull");
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Currency",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getGrantedPermissions() {
    const grantedPermissions = [];

    this.permissionGroups.forEach((group) => {
      group.permissions.forEach((permission) => {
        if (permission.isGranted) {
          grantedPermissions.push({
            permission: permission.permission,
            isGranted: permission.isGranted,
            group: group.name,
          });
        }
      });
    });

    return grantedPermissions;
  }

  closePopUp() {
    this.router.navigate(["/users-list"]);
  }

  SaveChanges(requestType?: any) {






    const grantedPermissions = this.getGrantedPermissions();

    if (this.paramwinfrm.invalid) {
      this.toastr.error("Invalid Form Values");
      return;
    }
    const phone="+255"+this.paramwinfrm.get("mobile").value;
    const data = {
      requestType: requestType,
      firstName: this.paramwinfrm.get("firstName").value,
      middleName: this.paramwinfrm.get("middleName").value,
      lastName: this.paramwinfrm.get("lastName").value,
      username: this.paramwinfrm.get("username").value,
      usergroupId: this.paramwinfrm.get("usergroupId").value,
      spId: this.paramwinfrm.get("spId").value,
      mobile: phone,
      telephone: this.paramwinfrm.get("telephone").value,
      email: this.paramwinfrm.get("email").value,
      userId: this.userID,
      permissions: grantedPermissions,
    };

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          if (requestType === "SP_USER_CREATE") {
            this.statusMessage =
              "User account has been created succesfull. the password is ";
            this.currentPassword = response.data.password;
            this.showPasswordAlert = true;
            return;
          } else {
            this.router.navigate(["/users-list"]);
            this.toastr.success(response.statusMessage);
          }
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  enableDisableUser(status: string) {
    const grantedPermissions = this.getGrantedPermissions();

    if (this.paramwinfrm.invalid) {
      this.toastr.error("Invalid Form Values");
      return;
    }
    const data = {
      requestType: "SP_USER_ENABLE",
      userId: this.userID,
      status: status,
    };

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          // this.router.navigate(["/users-list"]);
          this.toastr.success(response.statusMessage);
          this.userGet();
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onSaveParamsDetails(requestType?: any) {
    if (this.paramwinfrm.get("mobile")) {
      if (
        !this.paramwinfrm.get("mobile").valid &&
        this.paramwinfrm.get("mobile").touched
      ) {
        this.toastr.error(
          "Phone number must be 10 digits starting with 0",
          "Wrong phone number format"
        );
        return;
      }
    }
    if (this.paramwinfrm.get("email")) {
      if (
        !this.paramwinfrm.get("email").valid &&
        this.paramwinfrm.get("email").touched
      ) {
        this.toastr.error("Email format not valid", "Wrong email format");
        return;
      }
    }
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Fill in all the form details", "Response");
      return;
    }
    if (this.paramwinfrm.get("id")) {
      this.param_id = this.paramwinfrm.get("id").value;
    }
    if (this.paramwinfrm.get("user_id")) {
      this.paramwinfrm.get("user_id").setValue(this.param_id);
    }
    if (this.paramwinfrm.get("userId")) {
      this.param_id = this.paramwinfrm.get("userId").value;
    }
    if (this.paramwinfrm.get("number") && this.paramwinfrm.get("acc_number")) {
      this.paramwinfrm
        .get("number")
        .setValue(this.paramwinfrm.get("acc_number").value);
    }

    if (this.paramwinfrm.get("till_allowed")) {
      this.paramwinfrm.get("till_allowed").setValue(0);
    }
    if (this.paramwinfrm.get("max_auth_amt")) {
      this.paramwinfrm.get("max_auth_amt").setValue(5000000);
    }
    if (this.paramwinfrm.get("max_txn_amt")) {
      this.paramwinfrm.get("max_txn_amt").setValue(5000000);
    }

    if (this.paramwinfrm.get("fax")) {
      this.paramwinfrm.get("fax").setValue("");
    }
    if (this.paramwinfrm.get("manager_id")) {
      this.paramwinfrm.get("manager_id").setValue(0);
    }
    if (this.paramwinfrm.get("extension_number")) {
      this.paramwinfrm.get("extension_number").setValue("");
    }

    if (this.paramwinfrm.get("parent_id")) {
      let parent_id = this.paramwinfrm.get("parent_id").value;
      if (parent_id < 1) {
        this.paramwinfrm.get("parent_id").setValue(0);
      }
      this.paramwinfrm.get("subsidium_03").setValue("N/A");
      this.paramwinfrm.get("subsidium_02").setValue("N/A");
      this.paramwinfrm.get("subsidium_01").setValue("N/A");
    }
    if (this.paramwinfrm.get("is_online")) {
      this.paramwinfrm.get("is_online").setValue(0);
    }

    this.endpointcall = "create";
    if (this.param_id > 0) {
      this.endpointcall = "update";
    }
    if (this.paramwinfrm.get("is_enabled")) {
      this.funcGetCheckBoxValue(
        this.paramwinfrm.get("is_enabled").value,
        "is_enabled"
      );
    }

    if (this.paramwinfrm.get("requestType")) {
      if (
        requestType != null ||
        requestType != "" ||
        requestType != undefined
      ) {
        this.paramwinfrm.get("requestType").setValue(requestType);

        if (this.paramwinfrm.get("requestType").value == "SP_USER_ENABLE") {
          if (this.currentData.status == 1) {
            this.paramwinfrm.get("status").setValue("0");
          } else {
            this.paramwinfrm.get("status").setValue("1");
          }
        }
        if (this.paramwinfrm.get("requestType").value == "SP_ENABLE") {
          if (this.currentData.status == 1) {
            this.paramwinfrm.get("status").setValue("0");
          } else {
            this.paramwinfrm.get("status").setValue("1");
          }
        }
        if (this.paramwinfrm.get("requestType").value == "SP_ACC_ENABLE") {
          if (this.currentData.accStatus == 1) {
            this.paramwinfrm.get("accStatus").setValue("0");
          } else {
            this.paramwinfrm.get("accStatus").setValue("1");
          }
        }
        if (
          this.paramwinfrm.get("requestType").value ==
          "SP_SERVICE_CATEGORY_ENABLE"
        ) {
          if (this.currentData.status == 1) {
            this.paramwinfrm.get("status").setValue("0");
          } else {
            this.paramwinfrm.get("status").setValue("1");
          }
        }
      }
    }

    if (this.paramwinfrm.get("is_super_group")) {
      this.funcGetCheckBoxValue(
        this.paramwinfrm.get("is_super_group").value,
        "is_super_group"
      );
    }
    if (
      this.endpointcall == "update" &&
      this.model_name == "currency/denominations"
    ) {
      this.paramwinfrm.get("denomination_id").setValue(this.param_id);
    }
    this.spinner.show();
    this.utilities.postServiceCall(this.paramwinfrm.value).subscribe(
      (response) => {
        this.response = response.json();
        if (this.response.statusId == 2000) {
          this.onGetParamsdetails();

          this.paramswinpnl = false;
          this.toastr.success(this.response.statusMessage, "Response");
        } else if (this.response.statusId == 2114) {
          this.onUserLogOutRequest(this.response.statusMessage);
        } else {
          this.toastr.error(this.response.statusMessage, "Alert");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
        console.error(error);
      }
    );
  }
}
