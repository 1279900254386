


import { Component, OnInit } from '@angular/core';
//import { SharedClassComponent } from 'src/app/shared/components/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent  extends SharedClassComponent implements OnInit {
  modelName = 'backend/request';
  title = 'System Users';
  // variables declaration reactive forms
  paramwinfrm: FormGroup;
  questionsForm: FormGroup;
  securityQuestionsForm: FormGroup;
  password: any;

  namePattern: any = /^[^0-9]+$/;
  passwordRegex = '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{8,}';

  paramswinpnl: boolean = false; // hide/show the pop up panel
  endpointcall:string; // end point string

  response:any;
  RegistrationData: any;

  index = 1; // used as indicator of the string which shows the total qns answered.
  registrationEndPoint = 'backend/request'; // end point
  permissions = []
  updatedPermissions = []
  // datasource
  filteredMenuItems: any[];
  


  private uniquePermissionIds = new Set<number>();

  unassignedPermissions = []
 
  grantedPermissions  = [];
  deniedPermissions  = [];

  alertDialogMessage = '';
  showAlertDialog = false;
  selectedUsergroupv: any;
  permissionRequestPayload: any
  Usergrouppermissions = []
  userGrouploggedInUser: any;
  userList: any;
  ngOnInit() {
    this.userGrouploggedInUser = this.authService.getUserDetails().userGroup;
    

   this.getUserList()
    // this.onGetParamsdetails();

// Controls the datagrid height and max rows to display
    this.observerCall();
  }

  // call getParamsdetails function to refresh datagid
  refreshDataGrid() {
    this.getUserList();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'New User',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.navigateUser.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
       onClick: this.refreshDataGrid.bind(this)
      }
    });
  }

  navigateUser(){

    this.router.navigate(['/user-details']);
  }

  //this.router.navigateByUrl("fumis/users")
funcpopWidth(percentage_width) {
    if (window.innerWidth > 800){
      return  window.innerWidth * percentage_width / 100;
    } else {
      return  window.innerWidth - 50;
    }

  }


  getUserList() {
    const data = {
           "requestType": "SP_USER_LIST",
        "spId": this.authService.getUserDetails().spId
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data.users;
          this.userList = data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  previewDetails(e)
  {

    
    const id =  e.data.id
   
    this.router.navigate(["user-details",id]);
  }
}
