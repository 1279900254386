
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-cleared-bill-report',
  templateUrl: './cleared-bill-report.component.html',
  styleUrls: ['./cleared-bill-report.component.scss']
})
export class ClearedBillReportComponent extends SharedClassComponent implements OnInit {
  title = 'Cleared Bills Report';
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];
  ngOnInit() {
        //  this.spinner.show();
        this.toastr.info('Data loading in progress', 'Please wait');
        // sets the current page title in index.html title tag
        this.appInfo.setTitle(this.title);

        this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
        this.paramwinfrm = new FormGroup({
          startDate: new FormControl(this.today, Validators.compose([Validators.required])),
          endDate: new FormControl(this.today, Validators.compose([Validators.required]))
        });

        const dd = String(this.today.getDate()).padStart(2, '0');
        const mm = String(this.today.getMonth() + 1).padStart(2, '0'); // January is 0
        const yyyy = this.today.getFullYear();

        //  this.currentDate = dd + '-' + mm + '-' + yyyy;
        this.currentDate = yyyy + '-' + mm + '-' + dd;
        this.getclearedBillRange();
        // Controls the datagrid height and max rows to display
        this.observerCall();
        this.currentUser = this.authService.getUserDetails().username;
  }

  getclearedBillRange() {
    this.spinner.show();

    
    const startDatestring = this.today.getFullYear() + "-"+  ("0"+(this.today.getMonth()+1)).slice(-2) + "-" +("0" + this.today.getDate()).slice(-2);
    const dt = new Date(startDatestring).setDate(new Date(startDatestring).getDate() + 1);
    const dd = String(new Date(dt).getDate()).padStart(2, '0');
     const mm = String(new Date(dt).getMonth() + 1).padStart(2, '0'); // January is 0
     const yyyy = new Date(dt).getFullYear();
     const endDatestring = yyyy + '-' + mm + '-' + dd;
    const data = {
              "requestType": "CLEARED_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId:  sessionStorage.getItem('spId')
    };

    this.utilities.postServiceCall(data).subscribe(
        response => {
          const res = response.json();
          this.datasource = res.data.clearedBills;
          this.spinner.hide();
          this.toastr.info('Data has finished loading', 'Process Completed');
        },
        error => {
          this.spinner.hide();
          this.toastr.error(
            'Error occurred while processing the request',
            'Request Failed'
          );
        }
      );
  }

  getclearedBillsByDateRange() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and end Date is required.");
      return;
    }
    this.spinner.show();
    const startDatestring = this.paramwinfrm.get('startDate').value.getFullYear() + "-"+  ("0"+(this.paramwinfrm.get('startDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.paramwinfrm.get('startDate').value.getDate()).slice(-2);

    const dt = new Date(this.paramwinfrm.get('endDate').value).setDate(new Date(this.paramwinfrm.get('endDate').value).getDate() + 1);

    const dd = String(new Date(dt).getDate()).padStart(2, '0');
    const mm = String(new Date(dt).getMonth() + 1).padStart(2, '0'); // January is 0
    const yyyy = new Date(dt).getFullYear();
    const endDatestring = yyyy + '-' + mm + '-' + dd;

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }
    const data = {
              "requestType": "CLEARED_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId:  sessionStorage.getItem('spId')
    };
    this.utilities.postServiceCall(data).subscribe(
      response => {
        const res = response.json();
        this.datasource = res.data.clearedBills;
        this.spinner.hide();
        this.toastr.info('Data has finished loading', 'Process Completed');
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
      }
    );
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      }
    );
  }

}
