import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-customers-list",
  templateUrl: "./customers-list.component.html",
  styleUrls: ["./customers-list.component.scss"],
})
export class CustomersListComponent
  extends SharedClassComponent
  implements OnInit
{
  serviceEntryType: any;
  paramswinpnlN: boolean = false;
  showAddButton = false;
  showUpdateButton = false;
  telephoneNO: any;
  name: any;
  mobileNo: any;
  Id: any;
  htelephoneNO: any;
  hname: any;
  hmobileNo: any;
  email: any;
  website: any;
  details: any;
  isAllowedToAdd: Boolean = false;
  isAllowedToUpdate: Boolean = false;
  isAllowedToDelete: Boolean = false;
  ngOnInit() {
    this.getServiceProviderAccounts()
    this.getcustomers();
    this.paramwinfrm = new FormGroup({
      hname: new FormControl("", Validators.compose([Validators.required])),
      email: new FormControl("", Validators.compose([Validators.email])),
      htelephoneNO: new FormControl(
        "", Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      mobileNo: new FormControl(
        "", Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),

      website: new FormControl("", Validators.compose([])),
      details: new FormControl("", Validators.compose([])),
    });
  }

  getServiceProviderAccounts() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;

            this.isAllowedToAdd = pemsn.some(
              (permission) =>
                permission.permission === "Can Add Customers" &&
                permission.permissionName === "true"
            );

            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Can Update Customers" &&
                permission.permissionName === "true"
            );

            this.isAllowedToDelete = pemsn.some(
              (permission) =>
                permission.permission === "Can Delete Customers" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getcustomers() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.serviceEntryType = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onParamsToolBarPreparingN(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Add",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.addcustomers.bind(this),
      },
    });
  }

  addcustomers() {
    if (!this.isAllowedToAdd) {
      this.toastr.error("Access Denied: Insufficient Permission.");
      return;
    }
    this.paramwinfrm.reset();
    this.showAddButton = true;
    this.showUpdateButton = false;
    this.paramswinpnlN = true;
  }

  postData() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error(
        "Enter all required fields to proceed and Make sure phone numbers have 10 digits"
      );
      return;
    }

    const data = {
      requestType: "SP_CUSTOMER_CREATE",
      name: this.paramwinfrm.get("hname").value,
      telephoneNO: this.paramwinfrm.get("htelephoneNO").value,
      mobileNo: this.paramwinfrm.get("mobileNo").value,
      email: this.paramwinfrm.get("email").value,
      website: this.paramwinfrm.get("website").value,
      details: this.paramwinfrm.get("details").value,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getcustomers();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  update() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error(
        "Enter all required fields to proceed and Make sure phone numbers have 10 digits"
      );
      return;
    }

    const data = {
      requestType: "SP_CUSTOMER_UPDATE",
      name: this.paramwinfrm.get("hname").value,
      telephoneNO: this.paramwinfrm.get("htelephoneNO").value,
      mobileNo: this.paramwinfrm.get("mobileNo").value,
      email: this.paramwinfrm.get("email").value,
      website: this.paramwinfrm.get("website").value,
      details: this.paramwinfrm.get("details").value,
      id: this.Id,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getcustomers();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  delete() {
    const result = confirm("Are you sure you want to delete?", "");

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "SP_CUSTOMER_DELETE",
          id: this.Id,
        };

        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.toastr.success(response.statusMessage);
              this.getcustomers();
              this.paramswinpnlN = false;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  previewDetails(e) {
    console.log(e);
    if (e.data) {
      this.showAddButton = false;
      this.showUpdateButton = true;
      this.htelephoneNO = e.data.telephoneNO;
      this.hname = e.data.Name;
      this.mobileNo = e.data.mobileNo;
      this.email = e.data.email;
      this.details = e.data.details;
      this.website = e.data.website;
      this.Id = e.data.Id;
      this.paramswinpnlN = true;
    } else {
      this.showAddButton = true;
      this.showUpdateButton = false;
    }
  }
}
