import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-bill-summary-report",
  templateUrl: "./bill-summary-report.component.html",
  styleUrls: ["./bill-summary-report.component.scss"],
})
export class BillSummaryReportComponent
  extends SharedClassComponent
  implements OnInit
{

  
  title: string = "Bill Summary Report";
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];
  customerList: any;
  departmentList: any;
  branchList: any;
  currencyList = ["TZS", "USD"];
  spCatList: any;
  revenueTypeList: any;
  totalBillAmountData: any = [];

  criteriaList=[
    {
      name:"All Bills",
      value:"ALL_BILLS"
    },
    {
      name:"Cleared Bills",
      value:"CLEARED_BILLS"
    },
    {
      name:"Expired Bills",
      value:"EXPIRED_BILLS"
    },
    {
      name:"Valid Bills",
      value:"VALID_BILLS"
    },
    {
      name:"Cancelled Bills",
      value:"CANCELLED_BILLS"
    },
    {
      name:"Accrual Bills",
      value:"ACCRUAL_BILLS"
    }
  ]
  selectedStartDate: string;
  selectedEndDate: string;

  ngOnInit() {

    this.appInfo.setTitle(this.title);

    this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
    this.paramwinfrm = new FormGroup({

      department: new FormControl(""),
      criteria: new FormControl(""),
      revenueSource: new FormControl(""),
      currency: new FormControl(""),
      revenueType: new FormControl(""),
      branch: new FormControl(""),

      startDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
      endDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
    });

    const dd = String(this.today.getDate()).padStart(2, "0");
    const mm = String(this.today.getMonth() + 1).padStart(2, "0"); // January is 0
    const yyyy = this.today.getFullYear();

    //  this.currentDate = dd + '-' + mm + '-' + yyyy;
    this.currentDate = yyyy + "-" + mm + "-" + dd;
    this.getBillSummary();
    this.observerCall();
    this.currentUser = this.authService.getUserDetails().username;
 
    this.getDepartmentList();

    this.getRevenueTypeList();
    this.getBranchList();

  }


  getBranchList() {
    const data = {
      requestType: "BRANCH_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.branchList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  getRevenueTypeList() {
    const data = {
      requestType: "SP_SERVICE_CATEGORY_TYPES_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.revenueTypeList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }




  getDepartmentList() {
    const data = {
      requestType: "USERGROUP_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.departmentList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getBillSummary() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and End Date are required.");
      return;
    }
    const startDate = new Date(this.paramwinfrm.get("startDate").value);
    const startDatestring =
      startDate.getFullYear() +
      "-" +
      String(startDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(startDate.getDate()).padStart(2, "0");

    const endDate = new Date(this.paramwinfrm.get("endDate").value);
    const endDatestring =
      endDate.getFullYear() +
      "-" +
      String(endDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(endDate.getDate()).padStart(2, "0");

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than End Date.");
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    const data = {
      requestType: "BILLS_SUMMARY_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
 
      department:
        this.paramwinfrm.get("department").value != null
          ? this.paramwinfrm.get("department").value.toString()
          : "",
          criteria:
        this.paramwinfrm.get("criteria").value != null
          ? this.paramwinfrm.get("criteria").value.toString()
          : "",

      currency: this.paramwinfrm.get("currency").value,
      revenueType:
        this.paramwinfrm.get("revenueType").value != null
          ? this.paramwinfrm.get("revenueType").value.toString()
          : "",

          branch:
          this.paramwinfrm.get("branch").value != null
            ? this.paramwinfrm.get("branch").value.toString()
            : "",
  

    };

    this.selectedStartDate = startDatestring;
    this.selectedEndDate = endDatestring;
    this.utilities.postServiceCall(data).subscribe(
      (response) => {
        const res = response.json();

        this.datasource = res.data.summaryList;
   
        const data = res.data;
        if (data.totals) {
          this.totalBillAmountData = data.totals;
        }

        this.spinner.hide();
        this.toastr.info("Data has finished loading", "Process Completed");
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }


  getTitle() {
    const itemId = this.paramwinfrm.get("criteria").value;

    let itemType;
    if (this.criteriaList) {
      const selectedItem = this.criteriaList.find(
        (customer) => customer.value === itemId
      );
      itemType = selectedItem ? ` for ${selectedItem.name}` : "";
    }

    const dateRange =
      this.selectedStartDate && this.selectedEndDate
        ? ` between ${this.selectedStartDate} and ${this.selectedEndDate}`
        : "";

         const date= ` as of ${this.getCurrentDate()}`

    const title = `${this.title || ""}${itemType}${dateRange} ${date}`.trim() ;  

    return title || this.title + date;
  }


}
