import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AppInfoService } from 'src/app/shared/services';
import { RepositoryService } from '../../shared/services/repository.service';
import notify from 'devextreme/ui/notify';
import { DataVisualizationService } from '../../shared/services/data-visualization.service';
import { EncryptionDecryptionService } from 'src/app/shared/services/encryption-decryption.service';
import { AppSettings } from 'src/app/app-settings';
@Component({
  selector: 'app-shared-class',
  templateUrl: './shared-class.component.html',
  styleUrls: ['./shared-class.component.scss']
})
export class SharedClassComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  constructor(
    public utilities: UtilitiesService,
    public spinner: SpinnerVisibilityService,
    public toastr: ToastrService,
    public viewRef: ViewContainerRef,
    public router: Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    public observer: BreakpointObserver,
    public appInfo: AppInfoService,
    public repoService: RepositoryService,
    public securityService: EncryptionDecryptionService,
    public dataService: DataVisualizationService,
    public encryptionService: EncryptionDecryptionService,

    public fb: FormBuilder,
  ) {
    this.currencyDatasource = [
      {
        id: 1,
        currency_code: 'TZS'
      },
      {
        id: 2,
        currency_code: 'USD'
      }
    ];
    this.tillAllowed = [
      {
        id: 1,
        text: 'Yes'
      },
      {
        id: 0,
        text: 'No'
      }
    ];
    this.status = [
      {
        id: 1,
        text: 'Enabled'
      },
      {
        id: 0,
        text: 'Disabled'
      }
    ];
    this.params_actions = [
      {
        text: 'Actions',
        icon: 'bulletlist',
        type: 'default',
        items: [
          {
            text: 'Edit Record',
            icon: 'edit',
            action: 'edit'
          },
          {
            text: 'Disable/Enabled Record',
            icon: 'repeat',
            action: 'disabled'
          },
          {
            text: 'Delete Record',
            icon: 'clear',
            action: 'delete'
          }
        ]
      }
    ];

    this.billsprams_actions = [
      {
        text: 'Actions',
        icon: 'bulletlist',
        type: 'default',
        items: [
          {
            text: 'Receive Payment',
            icon: 'repeat',
            action: 'receive_payment'
          }
        ]
      }
    ];
    // reactive forms
    this.paramwinfrm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([])),
      is_enabled: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([])),
      access_level_id: new FormControl('', Validators.compose([])),
      navigation_type_id: new FormControl('', Validators.compose([])),
      navigation_level_id: new FormControl('', Validators.compose([]))
    });
    this.billValidationFrm = new FormGroup({
      control_number: new FormControl(
        '',
        Validators.compose([Validators.required])
      )
    });
    this.billValidationDetailsFrm = new FormGroup({
      description: new FormControl('', Validators.compose([])),
      gateway_request_id: new FormControl('', Validators.compose([])),
      channel_ref_number: new FormControl('', Validators.compose([])),
      control_number: new FormControl('', Validators.compose([])),
      credit_account: new FormControl('', Validators.compose([])),
      amount: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([])),
      payer_name: new FormControl('', Validators.compose([])),
      payer_mobile: new FormControl('', Validators.compose([])),
      payer_email: new FormControl('', Validators.compose([])),
      gateway_name: new FormControl('', Validators.compose([])),
      sp_name: new FormControl('', Validators.compose([])),
      sp_code: new FormControl('', Validators.compose([])),
      due_date: new FormControl('', Validators.compose([])),
      bankCode: new FormControl('', Validators.compose([])),
      tin: new FormControl('', Validators.compose([])),
      service_code: new FormControl('', Validators.compose([])),
      psp_request_id: new FormControl('', Validators.compose([])),
      payment_option: new FormControl('', Validators.compose([])),
      invoice_number: new FormControl('', Validators.compose([])),
      invoice_date: new FormControl('', Validators.compose([])),
      receipt_number: new FormControl('', Validators.compose([])),
      receipt_date: new FormControl('', Validators.compose([])),
      timestamp: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([]))
    });

    this.billClearanceCashTransaction = new FormGroup({
      control_number: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      validation_response_id: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      id: new FormControl('', Validators.compose([Validators.required])),
      amount: new FormControl('', Validators.compose([Validators.required])),
      mobile: new FormControl('', Validators.compose([Validators.required])),
      payment_mode: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      email: new FormControl('', Validators.compose([])),
      debit_account: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([Validators.required])),
      depositor_name: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      timestamp: new FormControl('', Validators.compose([])),
      is_showdemoninationdetails: new FormControl('', Validators.compose([])),
      denominations: new FormControl('', Validators.compose([])),
      payment_option: new FormControl('', Validators.compose([]))
    });

    this.accountValidationFrm = new FormGroup({
      account_no: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      account_name: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([])),
      balance: new FormControl('', Validators.compose([]))
    });
    this.previewBillsFrm = new FormGroup({
      clearance_request_id: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      date: new FormControl('', Validators.compose([Validators.required])),
      gateway_code: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      sp_account_number: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      sp_code: new FormControl('', Validators.compose([Validators.required])),
      sp_name: new FormControl('', Validators.compose([Validators.required])),
      customer_name: new FormControl('', Validators.compose([])),
      control_number: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([])),
      amount: new FormControl('', Validators.compose([])),
      receipt_number: new FormControl('', Validators.compose([])),
      gateway_receipt_number: new FormControl('', Validators.compose([])),
      description: new FormControl('', Validators.compose([])),
      payment_option: new FormControl('', Validators.compose([])),
      created_by: new FormControl('', Validators.compose([]))
    });
  }
  // varibale declaration
  rtgsDataSet = [];
  rtgsOutGoingDataSet = [];
  rtgsOutGoingRespDataSet = [];
  currencyDatasource: any; // TEMPORARY VARIABLE
  tabIndex = 0; // This Variable is very important in controlling tabs on homeComponent.html
  currentData: any;
  disableEnableTextSwitcher: any;
  hideSaveButton = false;
  hideButton = false;
  showAuthorizeUserButton = false;
  dialogHeight = 350;
  clearance_request_id: any;
  payment_detailscol = 'col-lg-6';
  is_showdemoninationdetails: any = false;

  tabPanelHeight = 69;
  datagridHeight = 67;
  pageSize = 5;
  allowedPageSises = [];

  onBillTransactionWidth = 65;
  hideprintBtn = false;
  control_number: any;
  denominationData: any;
  amountBalanceChange: any;
  total_amountdenominations = 0;
  me = this;
  total_amount = 0;
  gateway_code: any;
  currency: any;
  gateway_receipt_number: any;
  payment_option: any;
  paymentCode: number;
  receipt_number: any;
  psp_reference_number: any;
  amount: any;
  description: any;
  sp_account_number: any;
  date: any;
  created_by: any;
  sp_code: any;
  sp_name: any;
  customer_name: any;
  status_message: any;
  previewIshiddenField = true;
  title: string;
  model_name: string;
  data: any = {};
  previewBillsFrm: FormGroup;
  previewBillsWin = false;
  record_data: any;
  // variables defination
  paramswinpnl = false;
  paramwinfrm: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  tillAllowed: any;
  status: any;
  response: any;
  userStatusDta: any;
  branchesDta: any;
  accountTypesData: any;
  currencyData: any;
  gatewayData: any;
  userRolesDta: any;
  parentNavListData = [];
  serviceProviders: any;
  billValidationFrm: FormGroup;
  accountValidationFrm: FormGroup;
  billInformationData: any;
  billValidationDetailsFrm: FormGroup;
  onCashtrnsactionWin: boolean;

  rejected_billsctr = '0';
  cleared_billsctr = '0';
  pending_billsctr = '0';

  rejected_bills: any;
  cleared_bills: any;
  pending_bills: any;
  all_bills: any;
  failedcbstransaction_bills: any;
  cbstransaction_bills: any;

  billsprams_actions: any;
  billClearanceCashTransaction: FormGroup;
  isFullAmount: boolean;
  payment_transactiondta: any;
  onBankTransfertrnsactionWin: any;
  isAccountVerified = true;
  paymentOptions: string;
  systemNavigationDta: any;
  userAccessLevelDta: any;
  timeTillNextRefreshInSeconds = 5000;
  modelName = 'settings/get';
  refreshInterval: any;
  cash = 301;
  transfer = 302;
  tiss = 303;
  isBillPayable = 1; // payment can not be received if accept is 0
  isBillValid = true;
  paymentNotAcceptedMessage = 'Bill Payment already received in CBS.';
  currencyButton: any;
  branch: any;
  username: any;
  fraudCounter = 0;
  settingData = {
    setting_id: '1021'
  };
  // stores active user's details

  currentAppName: string=AppSettings.appNme;
  currentUserName: string ="";
  userDetails: any;
  currentDateToPrint = new Date();
  userGroupDatasource = [];
  serviceProvidersDatasource = [];
  userDatasource = [];
  spListDatasource = [];
  spCategoriesDatasource = [];
  spAccountsDatasource = [];
  ngOnInit() {
  // gets current user's data
  this.userDetails = this.authService.getUserDetails();





  this.currentUserName = this.userDetails.firstName + " " + this.userDetails.lastName;
  }

  getUserGroups() {
    const data = {
      "requestType": "USERGROUP_LIST"
    };

    this.utilities.postServiceCall(data).subscribe(res => {
      const srvRes = res.json();
        this.userGroupDatasource = srvRes.data;
    }, error => {
      this.toastr.error(error);
    });
  }

  getServiceProviders() {
    const data = {
      "requestType": "SP_LIST"
    };

    this.utilities.postServiceCall(data).subscribe(res => {
      const srvRes = res.json();
        this.serviceProvidersDatasource = srvRes.data;
    }, error => {
      this.toastr.error(error);
    });
  }

  getServiceProviderUserList() {
    const data = {
      "requestType": "SP_USER_LIST",
      "spId": this.authService.getUserDetails().spId
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(res => {
      const srvRes = res.json();
      if (srvRes.statusId == 2000) {
        this.userDatasource = srvRes.data.users;
        this.spinner.hide();
      }
    }, error => {
      this.toastr.error(error);
      this.spinner.hide();
    });
  }

  getServiceProvidersList() {
    const data = {
      "requestType": "SP_LIST"
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(res => {
      const srvRes = res.json();
      if (srvRes.statusId == 2000) {
        this.spListDatasource = srvRes.data;
        this.spinner.hide();
      }
    }, error => {
      this.toastr.error(error);
      this.spinner.hide();
    });
  }
  getServiceProviderCategories() {
    const data = {
      "requestType": "SP_SERVICE_CATEGORY_LIST",
      "spId": sessionStorage.getItem("spId") 
    };

    // this.authService.getUserDetails().spId
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(res => {
      
      const srvRes = res.json();
      if (srvRes.statusId == 2000) {
        this.spCategoriesDatasource = srvRes.data;
      }
      this.spinner.hide();
    }, error => {
      this.toastr.error(error);
      this.spinner.hide();
    });
  }

  getServiceProviderAccounts() {
    const data = {
      "requestType": "SP_ACC_LIST",
      "spId": sessionStorage.getItem("spId")
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(res => {
      const srvRes = res.json();
      if (srvRes.statusId == 2000) {
        this.spAccountsDatasource = srvRes.data;
      }
      this.spinner.hide();
    }, error => {
      this.toastr.error(error);
      this.spinner.hide();
    });
  }

   //  set automatic refresh of data in interval of time
   setDataGridRefreshTimeInterval() {
    if (this.router.url === '/login') { clearInterval(this.refreshInterval); return; }
    this.utilities.postServiceCall(this.settingData, this.modelName).subscribe( (data) => {
      const res = data.json();
      if (res.statusId === 2000) {
        this.timeTillNextRefreshInSeconds = res.data.value * 1000;
        } else {
          this.timeTillNextRefreshInSeconds = 60000;
        }
      this.refreshInterval = setInterval(() => {
          this.dataGrid.instance.refresh();
          if (this.router.url === '/home') {
            // this.onGetAllBillsdetails();
            // this.onGetCbsAllBillsdetails();
            this.onGetClearedBillsdetails();
            // this.onGetPendingBillsdetails();
            // this.onGetPendingRejecteddetails();
            // this.onGetFailedCbsAllBillsdetails();
          } else {
            this.onGetParamsdetails();
          }
        }, this.timeTillNextRefreshInSeconds);
    });
  }
  // method called to close the pop-up dialog when close button is clicked
onPopUpClose() {
    this.previewBillsWin = false;
    this.onCashtrnsactionWin = false;
    this.onBankTransfertrnsactionWin = false;
  }
  // method called when data-table row is clicked
onBillDoubleClickDetails(e, is_hidden) {
    this.spinner.show();
    const payload = {
      user_id: e.data.created_by
    };
    this.utilities.postServiceCall(payload, 'users/get').subscribe(res => {
      const response = res.json();
      this.spinner.hide();
      if (response.statusId == 2000) {
        const data = {
          branch_id: response.data.branch
        };
        this.username = response.data.username;
        this.utilities.postServiceCall(data, 'branches/get').subscribe( res2 => {
          const serverRes = res2.json();
          this.spinner.hide();
          if (serverRes.statusId == 2000) {
            this.branch = serverRes.data.name;
            this.previewIshiddenField = true;
            this.hideprintBtn = is_hidden;
            const bill_data = e.data;
            this.previewBillsWin = true;
            this.previewBillsFrm.patchValue(bill_data);
            this.clearance_request_id = bill_data.clearance_request_id;
            this.gateway_code = bill_data.gateway_code;
            this.currency = bill_data.currency;
            this.control_number = bill_data.control_number;
            this.gateway_receipt_number = bill_data.gateway_receipt_number;
            this.payment_option = bill_data.payment_option;
            this.receipt_number = bill_data.receipt_number;
            this.psp_reference_number = bill_data.psp_reference_number;
            this.amount = bill_data.amount;
            this.description = bill_data.description;
            this.sp_account_number = bill_data.sp_account_number;
            this.date = bill_data.created_at;
            this.created_by = bill_data.created_by;
            this.sp_code = bill_data.sp_code;
            this.sp_name = bill_data.sp_name;
            this.customer_name = bill_data.customer_name;
            this.status_message = bill_data.status_message;
            this.gateway_code = bill_data.gateway_code;
          }
        }, error2 => {
          this.spinner.hide();
          this.toastr.error('Something went wrong, while creating a payment receipt, please try again.');
        });
      }
    }, error => {
      this.spinner.hide();
    });

  }

// datagrid header components configuration
onBillssToolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          type: 'default',
          icon: 'help',
          hoverStateEnabled: false,
          hint: 'Click on the rows to Preview & Print Bills Details'
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      }
    );
  }
// datagrid header components configuration
onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewParameter.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.refreshDataGrid.bind(this)
        }
      }
    );
  }
  // method to refresh datagrid instance
refreshDataGrid() {
    this.onGetParamsdetails();
  }
// pop up form presets for insert action
addNewParameter() {
  
    this.dialogHeight = 350;
    this.paramwinfrm.reset();
    this.paramswinpnl = true;
    this.hideSaveButton = true;
    this.hideButton = false;
    this.showAuthorizeUserButton = false;
  }
// customize the pop up form details
previewDetails(e, obj) {
    this.dialogHeight = 420;
    this.hideSaveButton = false;
    this.hideButton = true;
    this.currentData = e.data;
    if (e.data.authorized_by != 0) {
      this.showAuthorizeUserButton = false;
    } else {this.showAuthorizeUserButton = true; }
    // tslint:disable-next-line: max-line-length
    this.disableEnableTextSwitcher = obj === 'User' ? e.data.status === 0 ? `Enable ${obj}` : `Disable ${obj}` : e.data.is_enabled === 0 ? `Enable ${obj}` : `Disable ${obj}`;
    let text = '';
    if (e.data.status === 0 || e.data.status === 2) {
      text = 'Enable user';
    } else if (e.data.status === 1) {
      text = 'Disable user';
    }
    this.appInfo.setTextSwitcher(text);

    if (this.model_name === 'branches') {
          this.paramwinfrm.get('branch_id').setValue(e.data.id);
        }
    if (this.model_name === 'navigations') {
      this.paramwinfrm.get('navigation_id').setValue(e.data.id);
    }
    if (this.model_name === 'settings') {
      this.paramwinfrm.get('setting_id').setValue(e.data.id);
    }
    
    if (this.model_name === 'service/providers') {
      this.paramwinfrm.get('sp_id').setValue(e.data.id);
      this.disableEnableTextSwitcher = e.data.status === 0 ? `Enable ${obj}` : `Disable ${obj}`;
    }

    if (this.model_name === 'gateways') {
      this.paramwinfrm.get('gateway_id').setValue(e.data.id);
      this.disableEnableTextSwitcher = e.data.status === 0 ? `Enable ${obj}` : `Disable ${obj}`;
    }
    if (this.model_name === 'accounts') {
      this.paramwinfrm.get('account_id').setValue(e.data.id);
      this.disableEnableTextSwitcher = e.data.status === 0 ? `Enable ${obj}` : `Disable ${obj}`;
    }
    if (this.model_name === 'roles') {
      this.paramwinfrm.get('role_id').setValue(e.data.id);
    }
    if (this.model_name === 'access/levels') {
      this.paramwinfrm.get('access_level_id').setValue(e.data.id);
    }
    if (this.model_name === 'status') {
      this.paramwinfrm.get('status_id').setValue(e.data.id);
    }
    if (this.model_name === 'navigation/types') {
      this.paramwinfrm.get('navigation_type_id').setValue(e.data.id);
    }
    if (this.model_name === 'navigation/levels') {
      this.paramwinfrm.get('navigation_level_id').setValue(e.data.id);
    }
    if (this.model_name === 'permissions') {
      this.paramwinfrm.get('permission_id').setValue(e.data.id);
    }
    if (this.model_name === 'sp/users') {
      for (const iterator of this.userGroupDatasource) {
        if (iterator.usergroupId = e.data.usergroupId) {
          this.paramwinfrm.get('usergroupId').setValue(e.data.usergroupId);
          break;
        }
      }
      for (const iterator of this.serviceProvidersDatasource) {
        if (iterator.spId = e.data.spId) {
          this.paramwinfrm.get('spId').setValue(iterator);
          break;
        }
      }

    }
    this.paramwinfrm.patchValue(e.data);
    this.paramswinpnl = true;
  }
  // action buttons methods
onEditParamsdetails(data) {
    this.paramwinfrm.patchValue(data);
    if (this.paramwinfrm.get('is_enabled')) {
      this.funcSetCheckBoxValue(data.is_enabled, 'is_enabled');
    }
    if (this.paramwinfrm.get('is_super_group')) {
      this.funcSetCheckBoxValue(data.is_super_group, 'is_super_group');
    }
    if (this.model_name == 'navigations') {
      this.paramwinfrm.get('navigation_id').setValue(data.id);
    }

    this.paramswinpnl = true;
  }
funcSetCheckBoxValue(value, panel) {
    let checkbox_value = false;
    if (value == 1) {
      checkbox_value = true;
    }
    this.paramwinfrm.get(panel).setValue(checkbox_value);
  }
funcGetCheckBoxValue(value, panel) {
    let checkbox_value = 0;
    if (value) {
      checkbox_value = 1;
    }
    this.paramwinfrm.get(panel).setValue(checkbox_value);
  }
// set the widget width
funcpopWidth(percentage_width) {
    if (window.innerWidth > 800) {
      return (window.innerWidth * percentage_width) / 100;
    } else {
      return window.innerWidth - 50;
    }
    // return window.innerWidth - 50;
  }
// set the widget height
funcpopHeigt(percentage_height) {
    return (window.innerHeight * percentage_height) / 100;
  }
  // get cleared bills details
onGetClearedBillsdetails() {
    const payload = {};
    this.utilities.postServiceCall(payload, 'bills/cleared').subscribe(
      response => {
        const data = response.json();
        if (data.statusId == 2112) {
          this.toastr.error(
            'Error occurred, Log-Out and Login to continue!!',
            'Response'
          );
        }
        this.cleared_bills = data.data;
        this.cleared_billsctr = this.formatBillCounter(this.cleared_bills.length, 1);
        this.dataService.setClearedBillCounter(this.cleared_bills.length);
        this.dataService.setClearedBillsData(this.cleared_bills);
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }

// function to get all bills
onGetAllBillsdetails() {
    const reqData = {};
    this.utilities.postServiceCall(reqData, 'bills').subscribe(
      response => {
        const data = response.json();
        if (data.statusId == 2112) {
          this.toastr.error(
            'Error occurred, Log-Out and Login to continue!!' + data.statusMessage,
            'Response'
          );
          return;
        }
        this.all_bills = data.data;
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }

onGetFailedCbsAllBillsdetails() {
    let data = {};
    this.utilities.postServiceCall(data, 'bills/cbs/failed').subscribe(
      response => {
        const data = response.json();
        if (data.statusId == 2112) {
          this.toastr.error(
            'Error occurred, Log-Out and Login to continue!!' + data.statusMessage,
            'Response'
          );
          return;
        }
        this.failedcbstransaction_bills = data.data;
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
// get the cbs bills (all)
onGetCbsAllBillsdetails() {
    let data = {};
    this.utilities.postServiceCall(data, 'bills/cbs').subscribe(
      response => {
        const data = response.json();
        if (data.statusId == 2112) {
          this.toastr.error(
            'Error occurred, Log-Out and Login to continue.' + data.statusMessage,
            'Response'
          );
          return;
        }
        this.cbstransaction_bills = data.data;
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetPendingBillsdetails() {
    const reqData = {};
    this.utilities.postServiceCall(reqData, 'bills/pending').subscribe(
      response => {
        this.spinner.hide();
        const data = response.json();
        this.pending_bills = data.data;
        this.pending_billsctr = this.formatBillCounter(this.pending_bills.length, 1);
        // this.dataService.pendingBillCounter(this.pending_bills.length);
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetPendingRejecteddetails() {
    const reqData = {};
    this.utilities.postServiceCall(reqData, 'bills/failed').subscribe(
      response => {
        this.spinner.hide();
        const data = response.json();
        this.rejected_bills = data.data;
        this.rejected_billsctr = this.formatBillCounter(this.rejected_bills.length, 1);
        this.dataService.setRejectedBillCounter(this.rejected_bills.length);
        this.dataService.setRejectedBillsData(this.rejected_bills);
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
// redirect to the transactions grid specific tab
funLinkTab(index, route) {
    if (route === '/home') {
      if (index === 0) {
        this.tabIndex = 0;
      } else if (index === 1) {
        this.tabIndex = 1;
      } else if (index === 2) {
        this.tabIndex = 2;
      }
    } else {
      if (index === 0) {
        this.router.navigate(['/home'], { queryParams: { tabIndex: 0 } });
        this.tabIndex = 0;
      } else if (index === 1) {
        this.router.navigate(['/home'], { queryParams: { tabIndex: 1 } });
        this.tabIndex = 1;
      } else if (index === 2) {
        this.router.navigate(['/home'], { queryParams: { tabIndex: 2 } });
        this.tabIndex = 2;
      }
    }
    return this.tabIndex;
  }
  // controls the number of rows to display
dataGridPagerController() {
    if (
      window.innerHeight < 800 &&
      !(window.innerWidth >= 1500 && window.innerWidth <= 1600)
    ) {
      return 7;
    } else if (
      window.innerWidth >= 1500 &&
      window.innerWidth <= 1600 &&
      window.innerHeight <= 800
    ) {
      return 10;
    } else if (window.innerHeight >= 800 && window.innerHeight < 900) {
      return 10;
    } else {
      return 15;
    }
  }
// controls the PageSizeSelector
allowedPageSizeController() {
    if (window.innerHeight < 800) {
      return [];
    } else if (window.innerHeight >= 800 && window.innerHeight < 900) {
      return [5, 10];
    } else {
      return [5, 10, 15];
    }
  }
// controls the number of rows to display for other datagrids apart from home's ones
otherdataGridPagerController() {
    if (
      window.innerHeight < 800 &&
      !(window.innerWidth >= 1500 && window.innerWidth <= 1600)
    ) {
      return 10;
    } else if (
      window.innerWidth >= 1500 &&
      window.innerWidth <= 1600 &&
      window.innerHeight <= 800
    ) {
      return 15;
    } else if (window.innerHeight >= 800 && window.innerHeight < 870) {
      return 15;
    } else if (window.innerHeight >= 870 && window.innerHeight < 900) {
      return 16;
    } else if (window.innerWidth >= 2000 ) {
      return 23;
    } else if (window.innerWidth >= 1900 ) {
      return 21;
    } else {
      return 19;
    }
  }

// controls the PageSizeSelector for other datagrids apart from home's ones
otherAllowedPageSizeController() {
  if (window.innerHeight < 800) {
    return [];
  } else if (window.innerHeight >= 800 && window.innerHeight < 870) {
    return [5, 10, 15];
  } else if (window.innerHeight >= 870 && window.innerHeight < 900) {
    return [5, 10, 16];
  } else if (window.innerWidth >= 1900 ) {
    return [5, 10, 15, 21];
  } else if (window.innerWidth >= 2000 ) {
    return [5, 10, 15, 23];
  } else {
    return [5, 10, 19];
  }
}

observerCall() {
     // Controls the datagrid height and width on screen size change detection
     const widthHeightController = () => {
      this.pageSize = this.otherdataGridPagerController();
      this.allowedPageSises = this.otherAllowedPageSizeController();
    };
     this.observer.observe(['(max-width: 1400px)', '(max-height: 800px)']).subscribe(result => {
      const isMatched = Object.values(result.breakpoints);
      if (isMatched[0] && isMatched[1]) {
          // this.tabPanelHeight = 56;
          this.datagridHeight = 57;
          widthHeightController();
        } else if ((window.innerWidth >= 1500 && window.innerWidth <= 1600) && window.innerHeight <= 800 ) {
          // this.tabPanelHeight = 59;
          this.datagridHeight = 54;
          widthHeightController();
        } else {
          // this.tabPanelHeight = 69;
          this.datagridHeight = 67;
          widthHeightController();
        }
    });
  }
onGetParamsdetails() {

  if (this.model_name == 'sp/users') {
    this.getServiceProviderUserList();
    this.getServiceProviders();
    this.getUserGroups();
  }
  if (this.model_name == 'sp/list') {
    this.getServiceProvidersList();
  }
  if (this.model_name == 'sp/category') {
    this.getServiceProviderCategories();
    this.getServiceProviders();
  }
  if (this.model_name == 'sp/accounts') {
    this.getServiceProviderAccounts();
  }
  }

onGetBranchesParamsdetails(model, dataSet) {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.branchesDta = res.data;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetAccountTypesParamsdetails(model, dataSet) {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.accountTypesData = res.data;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetServiceProvidersList(model) {
  const data = {

  };
  this.utilities.postServiceCall(data, model).subscribe(
    response => {
      this.spinner.hide();
      const res = response.json();
      this.serviceProviders = res.data;
      this.dataService.setServiceProviderCounter(this.serviceProviders.length);
      this.dataService.setServiceProvidersData(this.serviceProviders);
    },
    error => {
      this.spinner.hide();
      this.toastr.error(
        'Error occurred while processing the request',
        'Request Failed'
      );
      console.error(error);
    }
  );
}
onGetCurrencyParamsdetails(model, dataSet) {
    const data = {
      currency_code: 'ALL'
    };
    this.spinner.show();
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.currencyData = res.data.denominations;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');

        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetGatewayParamsdetails(model, dataSet) {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.gatewayData = res.data;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetStatusesParamsdetails(model, dataSet) {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.userStatusDta = res.data;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }

onGetSystemNavParamsdetails() {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, 'navigations').subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.systemNavigationDta = res.data;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetuserAccessLevelParamsdetails() {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, 'access/levels').subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.userAccessLevelDta = res.data;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
  onGetNavigationList(model) {
    const data = {};

    this.utilities.postServiceCall(data, model).subscribe(
      response => {

        const res = response.json();

        this.parentNavListData = res.data;
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
  //
onGetOtheRolesdetails(model, dataSet) {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.userRolesDta = res.data;
        if (this.model_name == 'users') {
         const user = this.authService.getUserDetails();
         const userRoleId = user.role_id;

         if (userRoleId != 801) {
          this.userRolesDta.splice(this.userRolesDta.findIndex(item => item.id == 801), 1);
          this.userRolesDta.splice(this.userRolesDta.findIndex(item => item.id == 802), 1);
         }
        }
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }
onGetDemoninationDataset(model, reqData) {
    this.spinner.show();
    this.utilities.postServiceCall(reqData, model).subscribe(
      response => {
        this.spinner.hide();
        const data = response.json().data;
        console.log(data);

        this.is_showdemoninationdetails = data.required;

        if (!data.required) {
          this.payment_detailscol = 'col-lg-12';
          this.onBillTransactionWidth = 35;
        }
        this.billClearanceCashTransaction
          .get('is_showdemoninationdetails')
          .setValue(data.required);

        this.denominationData = data.denominations;
      },
      error => {
        // this.onUserLogOutRequest('Error on Communication Channel');
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
        console.error(error);
      }
    );
  }

onSaveParamsDetails(requestType?: any) {
    if (this.paramwinfrm.get('mobile')) {
      if (!this.paramwinfrm.get('mobile').valid && this.paramwinfrm.get('mobile').touched) {
        this.toastr.error('Phone number must be 10 digits starting with 0', 'Wrong phone number format');
        return;
        }
    }
    if (this.paramwinfrm.get('email')) {
      if (!this.paramwinfrm.get('email').valid && this.paramwinfrm.get('email').touched) {
        this.toastr.error('Email format not valid', 'Wrong email format');
        return;
        }
    }
    if (this.paramwinfrm.invalid) {
      this.toastr.error('Fill in all the form details', 'Response');
      return;
    }
    if (this.paramwinfrm.get('id')) {
      this.param_id = this.paramwinfrm.get('id').value;
    }
    if (this.paramwinfrm.get('user_id')) {
      this.paramwinfrm.get('user_id').setValue(this.param_id);
    }
    if (this.paramwinfrm.get('userId')) {
      this.param_id = this.paramwinfrm.get('userId').value;


    }
    if (this.paramwinfrm.get('number') && this.paramwinfrm.get('acc_number')) {
      this.paramwinfrm.get('number').setValue(this.paramwinfrm.get('acc_number').value);
    }
    // setting default values for user account creation
    if (this.paramwinfrm.get('till_allowed')) {
      this.paramwinfrm.get('till_allowed').setValue(0);
    }
    if (this.paramwinfrm.get('max_auth_amt')) {
      this.paramwinfrm.get('max_auth_amt').setValue(5000000);
    }
    if (this.paramwinfrm.get('max_txn_amt')) {
      this.paramwinfrm.get('max_txn_amt').setValue(5000000);
    }
    // if (this.paramwinfrm.get('telephone')) {
    //   this.paramwinfrm.get('telephone').setValue('');
    // }
    if (this.paramwinfrm.get('fax')) {
      this.paramwinfrm.get('fax').setValue('');
    }
    if (this.paramwinfrm.get('manager_id')) {
      this.paramwinfrm.get('manager_id').setValue(0);
    }
    if (this.paramwinfrm.get('extension_number')) {
      this.paramwinfrm.get('extension_number').setValue('');
    }

    // end of default values
    if (this.paramwinfrm.get('parent_id')) {
      let parent_id = this.paramwinfrm.get('parent_id').value;
      if (parent_id < 1) {
        this.paramwinfrm.get('parent_id').setValue(0);
      }
      this.paramwinfrm.get('subsidium_03').setValue('N/A');
      this.paramwinfrm.get('subsidium_02').setValue('N/A');
      this.paramwinfrm.get('subsidium_01').setValue('N/A');
    }
    if (this.paramwinfrm.get('is_online')) {
      this.paramwinfrm.get('is_online').setValue(0);
    }

    this.endpointcall = 'create';
    if (this.param_id > 0) {
      this.endpointcall = 'update';
    }
    if (this.paramwinfrm.get('is_enabled')) {
      this.funcGetCheckBoxValue(
        this.paramwinfrm.get('is_enabled').value,
        'is_enabled'
      );
    }

    if (this.paramwinfrm.get('requestType')) {
      if (requestType != null || requestType != '' || requestType != undefined) {
        this.paramwinfrm.get('requestType').setValue(requestType);

        if (this.paramwinfrm.get('requestType').value == 'SP_USER_ENABLE') {
          if(this.currentData.status == 1) {
            this.paramwinfrm.get('status').setValue("0");
          } else {
            this.paramwinfrm.get('status').setValue("1");
          }
        }
        if (this.paramwinfrm.get('requestType').value == 'SP_ENABLE') {
          if(this.currentData.status == 1) {
            this.paramwinfrm.get('status').setValue("0");
          } else {
            this.paramwinfrm.get('status').setValue("1");
          }
        }
        if (this.paramwinfrm.get('requestType').value == 'SP_ACC_ENABLE') {
          if(this.currentData.accStatus == 1) {
            this.paramwinfrm.get('accStatus').setValue("0");
          } else {
            this.paramwinfrm.get('accStatus').setValue("1");
          }
        }
        if (this.paramwinfrm.get('requestType').value == 'SP_SERVICE_CATEGORY_ENABLE') {
          if(this.currentData.status == 1) {
            this.paramwinfrm.get('status').setValue("0");
          } else {
            this.paramwinfrm.get('status').setValue("1");
          }
        }
      }
    }

    if (this.paramwinfrm.get('is_super_group')) {
      this.funcGetCheckBoxValue(
        this.paramwinfrm.get('is_super_group').value,
        'is_super_group'
      );
    }
    if (
      this.endpointcall == 'update' &&
      this.model_name == 'currency/denominations'
    ) {
      this.paramwinfrm.get('denomination_id').setValue(this.param_id);
    }
    this.spinner.show();
    this.utilities
      .postServiceCall(
        this.paramwinfrm.value
      )
      .subscribe(
        response => {
          this.response = response.json();
          if (this.response.statusId == 2000) {
            this.onGetParamsdetails();

            this.paramswinpnl = false;
            this.toastr.success(this.response.statusMessage, 'Response');
          } else if (this.response.statusId == 2114) {
            this.onUserLogOutRequest(this.response.statusMessage);
          } else {
            this.toastr.error(this.response.statusMessage, 'Alert');
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.toastr.error(
            'Error occurred while processing the request',
            'Request Failed'
          );
          console.error(error);
        }
      );
  }

onDisabledParamsDetails(data) {
    this.param_id = data.id;
    const status = data.status;
    let disable_text = 'Enable';
    this.endpointcall = 'enable';
    this.record_data = {
      user_id: this.param_id
    };
    if (this.paramwinfrm.get('user_id')) {
      this.record_data = {
        user_id: this.param_id
      };
      this.paramwinfrm.get('user_id').setValue(this.param_id);
    }
    if (this.model_name == 'service/providers') {
      this.record_data = {
        sp_id: this.param_id
      };
    }
    if (this.model_name == 'gateways') {
      this.record_data = {
        gateway_id: this.param_id
      };
    }
    if (this.model_name == 'accounts') {
      this.record_data = {
        account_id: this.param_id
      };
    }
    if (this.model_name == 'permissions') {
      this.record_data = {
        permission_id: this.param_id
      };
    }

    if (status == 1) {
      disable_text = 'Disabled';
      this.endpointcall = 'disable';
    }
    const result = confirm(
      'Are You sure You want to ' + disable_text + ' the selected record?',
      disable_text + ' Record'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.paramswinpnl = false;
        this.spinner.show();
        this.utilities
          .postServiceCall(
            this.record_data,
            this.model_name + '/' + this.endpointcall
          )
          .subscribe(
            response => {
              this.response = response.json();
              // the details
              if (this.response.statusId == 2000) {
                this.onGetParamsdetails();
                this.toastr.success(this.response.statusMessage, 'Response');
              } else if (this.response.code == 2114) {
                this.onUserLogOutRequest(this.response.statusMessage);
              } else {
                this.toastr.error(this.response.statusMessage, 'Alert');
              }
              this.spinner.hide();
            },
            error => {
              this.toastr.error('Error occurred while processing the request', 'Request Failed');
              console.error(error);
              this.spinner.hide();
            }
          );
      }
    });
  }
onUserLogOutRequest(message) {
    const result = confirm(
      'You\'re session has expired/invalidated(' + message + ')',
      'User Log-Out'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.spinner.show();
        this.authService.logOut();
      }
    });
  }
onDeleteParamsAction(data) {

    this.param_id = data.id;
    this.endpointcall = 'delete';
    this.record_data = {
      id: this.param_id
    };
    if (this.model_name == 'currency/denominations') {
      this.record_data = {
        denomination_id: this.param_id
      };
    }
    if (this.model_name == 'settings') {
      this.record_data = {
        setting_id: this.param_id
      };
    }
    if (this.model_name == 'service/providers') {
      this.record_data = {
        sp_id: this.param_id
      };
    }
    if (this.model_name == 'gateways') {
      this.record_data = {
        gateway_id: this.param_id
      };
    }
    if (this.model_name == 'accounts') {
      this.record_data = {
        account_id: this.param_id
      };
    }
    if (this.model_name == 'account/types') {
      this.record_data = {
        account_type_id: this.param_id
      };
    }
    if (this.model_name == 'access/levels') {
      this.record_data = {
        access_level_id: this.param_id
      };
    }
    if (this.model_name == 'roles') {
      this.record_data = {
        role_id: this.param_id
      };
    }
    if (this.model_name == 'status') {
      this.record_data = {
        status_id: this.param_id
      };
    }
    if (this.model_name == 'navigation/types') {
      this.record_data = {
        navigation_type_id: this.param_id
      };
    }
    if (this.model_name == 'navigation/levels') {
      this.record_data = {
        navigation_level_id: this.param_id
      };
    }
    if (this.model_name == 'permissions') {
      this.record_data = {
        permission_id: this.param_id
      };
    }
    const result = confirm(
      'Are You sure You want to delete the selected record?',
      'Delete Record'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.paramswinpnl = false;
        this.spinner.show();
        this.utilities
          .postServiceCall(
            this.record_data,
            this.model_name + '/' + this.endpointcall
          )
          .subscribe(
            response => {
              this.response = response.json();

              if (this.response.statusId == 2000) {
                this.onGetParamsdetails();
                this.toastr.success(this.response.statusMessage, 'Response');
              } else {
                this.toastr.error(this.response.statusMessage, 'Alert');
              }
              this.spinner.hide();
            },
            error => {
              this.toastr.error('Error occurred while processing the request',
              'Request Failed');
              console.error(error);
              this.spinner.hide();
            }
          );
      }
    });
  }
onDeleteParamsDetails(data) {
    this.onDeleteParamsAction(data);
  }
onParamsActionsClick(e, data) {
    let action_btn = e.itemData.action;

    if (action_btn === 'edit') {
      this.onEditParamsdetails(data);
    } else if (action_btn === 'disabled') {
      this.onDisabledParamsDetails(data);
    } else if (action_btn === 'delete') {
      this.onDeleteParamsDetails(data);
    } else if (action_btn === 'receive_payment') {
      this.router.navigate(['/bill-validation']);
      this.utilities.setBillControlNumber(data.controlNumber);
    }
  }
onCrudActionsClick(action, data) {

    if (action === 'status') {
      this.onDisabledParamsDetails(data);
    } else if (action === 'update') {
      this.onEditParamsdetails(data);
    } else if (action === 'delete') {
      this.onDeleteParamsDetails(data);
    }
  }
onChangeParamCheckbox(e, checkbox) {
    if (e.value) {
      this.paramwinfrm.get(checkbox).setValue(1);
    } else {
      this.paramwinfrm.get(checkbox).setValue(0);
    }
  }
onValidateAccountNumber() {
    const paymentOption = this.billValidationDetailsFrm.get('payment_option').value;
    const controlNumber = this.billValidationDetailsFrm.get('control_number').value;
    const validation_id = this.billValidationDetailsFrm.get('id').value;
    const mobile = this.billValidationDetailsFrm.get('payer_mobile').value;
    const email = this.billValidationDetailsFrm.get('payer_email').value;
    const totalAmount = this.billValidationDetailsFrm.get('amount').value;
    const currency = this.billValidationDetailsFrm.get('currency').value;
    const payerName = this.billValidationDetailsFrm.get('payer_name').value;

    this.currencyButton = {
      text: currency,
      stylingMode: 'text',
      elementAttr: {
          class: 'currency'
      }
  };
    if (this.accountValidationFrm.invalid) {
      this.toastr.error('Fill In the account number to proceed with payment', 'Response');
      return;
    }

    if (this.accountValidationFrm.get('account_no').value == this.repoService.getSpCreditAccount) {
      this.fraudCounter += 1;
      if (this.fraudCounter >= 3 ) {
        // log out user
        this.toastr.error('Maximum fraud detection count reached, logging you out.', 'Fraud Detection');
        this.authService.logOut();
        return;
      }
      // tslint:disable-next-line: max-line-length
      this.toastr.error(`The customer's debit account number can not be the same as the SP credit account number. Debiting and crediting the same account is strictly forbidden.`, 'Fraud Detected');
      return;
  }
  //   if (validation_id == '' || validation_id < 1) {
  //   this.toastr.warning(
  //     'Bill not validated.',
  //     'Response'
  //   );
  //   return;
  // }
    this.spinner.show();
    this.utilities
      .postServiceCall(this.accountValidationFrm.value, 'bills/account/verify')
      .subscribe(
        response => {
          this.response = response.json();
          // the details
          if (this.response.statusId == 2000) {
            const data = this.response.data;
            this.isAccountVerified = false;
            this.toastr.success(this.response.statusMessage, 'Response');
            this.accountValidationFrm.patchValue(data);

            let debit_account = this.accountValidationFrm.get('account_no').value;
            let payment_mode = this.paymentOptions.toUpperCase();

            this.isFullAmount = false;
            // 503 EXACT
            if (paymentOption === 503) {
              this.isFullAmount = true;
            }
            this.payment_transactiondta = {
              control_number: controlNumber,
              validation_response_id: validation_id,
              id: validation_id,
              amount: totalAmount,
              mobile,
              depositor_name: payerName,
              payment_mode,
              payment_option: paymentOption,
              email,
              currency,
              debit_account
            };
            this.billClearanceCashTransaction.patchValue(
              this.payment_transactiondta
            );
          } else {
            this.toastr.error(this.response.statusMessage, 'Alert');
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.toastr.error('Error occurred while processing the request',
          'Request Failed');
          console.error(error);
        }
      );
  }

onValidateDashControlNumber() {
    if (this.billValidationFrm.invalid) {
      this.toastr.error(
        'Fill In the Payment Control Number to Proceed',
        'Response'
      );
      return;
    }
    this.control_number = this.billValidationFrm.get('control_number').value;

    if (this.control_number.length < 6) {
      this.toastr.error(
        'Invalid control Number, Control number should be of length greater than 5',
        'Response'
      );
      return;
    }
    this.billValidationFrm.get('control_number').setValue(this.control_number);
    this.spinner.show();
    this.utilities
      .postServiceCall(this.billValidationFrm.value, 'bills/validation')
      .subscribe(
        response => {
          this.response = response.json();
          // the details
          if (this.response.statusId == 2000 || this.response.statusId == 2300) {
            const data = this.response.data;
            // check the status
            if (data.status_code == 2000 || data.status_code == 2300) {
              // this.isBillPayable = data.accept;
              if (data.accept == 0) {
                this.repoService.setBillValidationStatus(false);
                notify(data.accept_message, 'error', 60000);
                } else {
                  data.payment_mode === 303 ? this.repoService.setIsTissTransaction(true) : this.repoService.setIsTissTransaction(false);
                  data.payment_mode == 302 ? this.repoService.setIsTransferTransaction(true) : this.repoService.setIsTransferTransaction(false);
                  data.payment_mode == 300 ? this.repoService.setIsForAllPaymentTypes(true) : this.repoService.setIsForAllPaymentTypes(false);
                  data.payment_mode == null ? this.repoService.setIsNullPaymentOption(true) : this.repoService.setIsNullPaymentOption(false);
                  this.repoService.setBillValidationStatus(true);
                 }

              this.router.navigate(['/bill-validation']);
              this.utilities.setBillControlNumber(data.control_number);
              // set sp credit account
              this.repoService.setSpCreditAccount(data.credit_account);
              this.utilities.setBillControlDetails(data);
            }

            if (this.response.statusId != 2300) {
              this.toastr.success(this.response.statusMessage, 'Validation Process');
            }
          } else if (this.response.statusId == 2606 || this.response.statusId == 2605) {
            this.toastr.error(this.response.statusMessage, 'Response');
          } else {
            this.toastr.error(this.response.statusMessage, 'Request Failed');
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.toastr.error('Error occurred while processing the request',
          'Request Failed');
          console.error(error);
        }
      );
  }
onValidateControlNumber() {
    if (this.billValidationFrm.invalid) {
      this.toastr.error(
        'Fill In the Payment Control Number to Proceed',
        'Response'
      );
      return;
    }
    this.spinner.show();
    let control_number = this.billValidationFrm.get('control_number').value;
    this.utilities
      .postServiceCall(
        this.billValidationFrm.value,
        this.model_name + '/' + this.endpointcall
      )
      .subscribe(
        response => {
          this.response = response.json();
          // the details
          if (this.response.statusId == 2000) {
            const data = this.response.data;
            // check the status
            if (data.status_code == 2000) {
              //
              this.billValidationDetailsFrm.patchValue(data);
              this.utilities.setBillControlNumber(data.control_number);
              // set sp credit account
              this.repoService.setSpCreditAccount(data.credit_account);
              this.utilities.setBillControlDetails(data);
              const myArray = [];
              myArray.push(data);
              this.billInformationData = myArray;
            }
            this.toastr.success(this.response.message, 'Alert');
          } else if (this.response.statusId == 2606 || this.response.statusId == 2605) {
            this.toastr.error(this.response.message, 'Response');
          } else {

            this.toastr.error(this.response.message, 'Alert');
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.toastr.error('Error occurred while processing the request',
          'Request Failed');
          console.error(error);
        }
      );
  }
onFuncCashTransaction() {
    // tslint:disable-next-line: one-variable-per-declaration
    const paymentOption = this.billValidationDetailsFrm.get('payment_option')
        .value,
      controlNumber = this.billValidationDetailsFrm.get('control_number').value,
      validation_id = this.billValidationDetailsFrm.get('id').value,
      mobile = this.billValidationDetailsFrm.get('payer_mobile').value,
      email = this.billValidationDetailsFrm.get('payer_email').value,
      totalAmount = this.billValidationDetailsFrm.get('amount').value,
      currency = this.billValidationDetailsFrm.get('currency').value,
      payerName = this.billValidationDetailsFrm.get('payer_name').value,
      payment_mode = 'CASH';

    this.currencyButton = {
        text: currency,
        stylingMode: 'text',
        elementAttr: {
            class: 'currency'
        }
    };

    this.paymentCode = 301;
    if (validation_id == '' || validation_id < 1) {
      this.toastr.error(
        'Bill not validated or not valid.',
        'Response'
      );
      return;
    }
    this.isFullAmount = false;
    // 503 EXACT
    if (paymentOption === 503) {
      this.isFullAmount = true;
    }
    this.payment_transactiondta = {
      control_number: controlNumber,
      validation_response_id: validation_id,
      id: validation_id,
      amount: totalAmount,
      mobile,
      depositor_name: payerName,
      payment_mode,
      payment_option: paymentOption,
      email,
      debit_account: '',
      currency
    };
    this.billClearanceCashTransaction.patchValue(this.payment_transactiondta);
    const data = {
      currency_code: currency
    };
    this.onGetDemoninationDataset('currency/denominations', data);
    this.onCashtrnsactionWin = true;
  }
onFuncBankTransferTransaction(paymentOptions, paymentCode) {
    const validation_id = this.billValidationDetailsFrm.get('id').value;
    const currency = this.billValidationDetailsFrm.get('currency').value;
    this.isAccountVerified = true;
    if (validation_id == '' || validation_id < 1) {
      this.toastr.error(
        'Bill not validated or not valid.',
        'Response'
      );

      return;
    }
    this.onBankTransfertrnsactionWin = true;
    this.paymentCode = paymentCode; // CODE FOR TISS or TRANSFER
    this.paymentOptions = paymentOptions; // TISS or TRANSFER IN STRINGS

    const data = {
      currency_code: currency
    };
    this.onGetDemoninationDataset('currency/denominations', data);
  }
returnBillClearanceDemonination() {
    let demoninations_data = [];

    for (const data of this.denominationData) {
      if (data.items_number > 0) {
        demoninations_data.push({
          currency_denomination_id: data.id,
          items_number: data.items_number
        });
      }
    }
    return demoninations_data;
  }
onSaveBillClearance() {
    if (this.billClearanceCashTransaction.invalid) {
      this.toastr.error('Fill Bill Payment Details to proceed', 'Response');
      return;
    }
    const now = new Date();
    const timestamp = now.getHours() + ':' + now.getMinutes();

    this.billClearanceCashTransaction.get('timestamp').setValue(timestamp);
    this.billClearanceCashTransaction.get('payment_mode').patchValue(this.paymentCode);
    // check the payments
    const payment_option = this.billValidationDetailsFrm.get('payment_option').value;
    const receive_amount = this.billClearanceCashTransaction.get('amount').value;
    const expected_amount = this.billValidationDetailsFrm.get('amount').value;

    if (receive_amount == 0) {
      this.toastr.error('Received amount must not be 0', 'Response');
      return;
    }
    // 501 FULL
    if (payment_option === 501 && receive_amount < expected_amount) {
      this.toastr.error(
        'Amount must be Equal or more than the Billed Amount ' + expected_amount,
        'Response'
      );
      return;
    }
    // 502 PARTIAL
    // if (payment_option === 502 && receive_amount >= expected_amount) {
    //   this.toastr.error(
    //     'Amount must be Equal or less than the Billed Amount ' + expected_amount,
    //     'Response'
    //   );
    //   return;
    // }

    // check if it is TISS or TRANSAFER transaction and check account balance
    // if (this.paymentCode === 302 || this.paymentCode === 303) {
    //   if (this.accountValidationFrm.get('balance')) {
    //     const accountActualBalance = this.accountValidationFrm.get('balance').value;
    //     if (accountActualBalance != null) {
    //        if (receive_amount > accountActualBalance) {
    //       this.toastr.error('Insufficient account balance', 'Error');
    //       return;
    //     }
    //      } else {
    //       this.toastr.error('Insufficient account balance', 'Error');
    //       return;
    //      }
    //     }
    // }

    // get the denomination details
    let is_showdemoninationdetails = this.billClearanceCashTransaction.get(
      'is_showdemoninationdetails'
    ).value;
    if (is_showdemoninationdetails) {
      let demoninations_data = this.returnBillClearanceDemonination();
      this.billClearanceCashTransaction
        .get('denominations')
        .setValue(demoninations_data);
    }

    this.spinner.show();

    this.utilities
      .postServiceCall(
        this.billClearanceCashTransaction.value,
        'bills/clearance'
      )
      .subscribe(
        response => {
          this.response = response.json();
          // the details
          if (this.response.statusId == 2000) {
            const data = this.response.data;
            // check the status
            if (data.status_code == 2000) {
              //
              // this.billValidationDetailsFrm.patchValue(data)
              this.onCashtrnsactionWin = false;
              this.onBankTransfertrnsactionWin = false;
              this.toastr.success(this.response.statusMessage, 'Response');
              this.router.navigate(['/home']);
            } else {
              this.toastr.success(data.statusMessage, 'Response');
            }
          } else {
            this.toastr.error(this.response.statusMessage, 'Alert');
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.toastr.error(
            'Error occurred while processing the request',
            'Request Failed'
          );
          console.error(error);
        }
      );
  }
formatMoney(value) {
    // const temp = ${value}.replace(/\,/g, "");
    // return this.currencyPipe.transform(value).replace("$", "");
}

formatBillCounter(num, decPlaces) {
 // 2 decimal places => 100, 3 => 1000, etc
 decPlaces = Math.pow(10, decPlaces);

 // Enumerate number abbreviations
 const abbrev = [ 'k', 'm', 'b', 't' ];

 // Go through the array backwards, so we do the largest first
 for (let i = abbrev.length - 1; i >= 0; i--) {

     // Convert array index to "1000", "1000000", etc
     const size = Math.pow(10, ( i + 1 ) * 3);

     // If the number is bigger or equal do the abbreviation
     if (size <= num) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          num = Math.round(num * decPlaces / size) / decPlaces;

          // Handle special case where we round up to the next abbreviation
          if ((num == 1000) && (i < abbrev.length - 1)) {
            num = 1;
            i++;
          }

          // Add the letter for the abbreviation
          num += abbrev[i];

          // We are done... stop
          break;
     }
 }

 return num;
}


rtgsReports(endpoint){
  this.spinner.show();
  this.utilities.rtgsPostServiceCall(endpoint).subscribe(res => {
    const responseData = res.json();
    if (endpoint === 'outgoing-tiss') {
      this.rtgsOutGoingDataSet = responseData.data;
    } else if(endpoint === 'outgoing-tiss-response'){
      this.rtgsOutGoingRespDataSet = responseData.data;
    } else {
      this.rtgsDataSet = responseData.data;
    }
    this.spinner.hide();
  }, err => {
    this.spinner.hide();
    this.toastr.error('Something went wrong while process the request.');
    console.log(err);
  });
}


getCurrentDate(): String {
  const dd = String(new Date().getDate()).padStart(2, '0');
  const mm = String(new Date().getMonth() + 1).padStart(2, '0'); // January is 0
  const yyyy = new Date().getFullYear();
  const date = yyyy + '-' + mm + '-' + dd;
  return date;
}



toolBarRtgsPreparing(e, refresh_action) {
  e.toolbarOptions.items.unshift(
    {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
        onClick: refresh_action.bind(this)
      }
    }
  );
}


downloadMessage(filename, data, type) {
  var file = new Blob([data], {type: type});
      var a = document.createElement("a"),
              url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
      }, 0);
}


getTotalSum(arrayData: any[], actualItem: string): number {
  if (!arrayData || !actualItem) return 0;
  return arrayData.reduce((total, item) => total + item[actualItem], 0);
}

printReport() {
  let popUpWindow;

  const innerContents = document.getElementById("report-container-new").innerHTML;
  popUpWindow = window.open(
    "",
    "_blank",
    "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
  );
  // popUpWindow.document.open();
  popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <style>
           body{
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
          }
          .space{
            display: flex;
            justify-content: space-between;
            border: 1px dotted #4d88bb;
            padding: 1rem 0;
          }
          .logo__block{
          /* border-bottom: 1px dotted #4d88bb; */
          
          }
          .logo__block p, .footer, p {
            /* color: #4d88bb; */
          }
          .invoice__head__child_contacts{
            margin: 19px;
          }

       
          .invoice_wrapper {
            position: absolute;
          }
          .invoice_wrapper, .invoice{
            /*background-color: rgba(0, 0, 0, 0.055);*/
            padding: 2rem;
            width: fit-content;
            height: 100vh;
          }
          .invoice__head,
          .invoice__how_to_pay{
            display: flex;
            justify-content: space-between;
          }
          .invoice__body{
            display: flex;
            /* justify-content: center; */
          }
          .invoice__body table tr th{
            background: #035817;
            color:white;
            text-align:left;
            vertical-align:center;
          }
          .invoice__body table tr {
            background-color: rgba(136 ,136 ,136, .1);
            border: 1px solid #ddd;
            padding: .35em;
          }
          .logo__block, .footer{
              text-align: center;
          }
          .full_table{
            width: 100%;
          }
          .divide {
            position: relative;
            min-height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          /* .watermark {
          position:fixed;
          font-size: 88px;
          bottom: 0;
          opacity:0.5;
          z-index:99;
          color: #35313126;
          text-align: center;
          } */
          table tbody tr td{
          padding: .5rem;
          }
          td {
            padding: 5px !important; /* Set the height of the table cells */
          }
          
        
          table {
            border-collapse: collapse;
            width: 100%;
            table-layout: auto;
        }
       
        
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: center;
          overflow: auto; /* Hide content that overflows the cell */        
          max-width: 200px;
          word-wrap: break-word;
  
           
        }
        
        th {
            background-color: #f2f2f2;
        }
        tfoot {
          display: table-footer-group;
        }

          @media print {
            thead { display: table-header-group; }
          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }
          th:first-child,
          td:first-child {
            width: 10%;
            white-space: nowrap;
          }

          .long-name-cell {
            min-width: 25px; /* Set a minimum width to accommodate longer names */
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            font-size: 17px;
            background:rgba(0, 0, 0, 0.1);
padding-top: 20px;
            color:black;
            width: 100%;
            padding:5px;
            float: none;
          }
          .invoice__body table tr th{
            padding: 5px;
          }
          .footer{
            position: absolute;
            bottom: 10px;
            width: 100%;
            text-align: center;
          }
          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }

            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }

           @media print {
            .page-footer-space {
            height: 23px;
           }
.printed-by-info {
              position: fixed;
              bottom: 0;
              width: 98%;
              background-color: white;
              border-top: 1px solid black;
               
               
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          
            .printed-by-info b {
              margin: 0;
            }
          }

           </style>
           </head>
           <body onload="window.print()">
           ${innerContents}
           <div class="printed-by-info">
            
           <div>
             <b>Printed By: ${this.getCurrentUserDetails()}</b>
           </div>
             <p>.</p>
           <div>
           <b>Date: ${this.getCurrentDateForPrint()}</b>
           </div>
         </div>
         </body>
       </html>`);
  popUpWindow.document.close();
}

getCurrentUserDetails(){
const user = this.authService.getUserDetails();
return user.firstName + " " + user.lastName;
}

getCurrentDateForPrint(){
  const currentDate = new Date();

const day = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const year = currentDate.getFullYear();
const hours = String(currentDate.getHours()).padStart(2, '0');
const minutes = String(currentDate.getMinutes()).padStart(2, '0');
const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
return formattedDate;


}
}
