import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-branches",
  templateUrl: "./branches.component.html",
  styleUrls: ["./branches.component.scss"],
})
export class BranchesListComponent
  extends SharedClassComponent
  implements OnInit
{
  branchesListDatasource: any;
  paramswinpnlN: boolean = false;
  showAddButton = false;
  showUpdateButton = false;
  
  isAllowedToAdd: Boolean = false;
  isAllowedToDelete: Boolean = false;
  isAllowedToUpdate: Boolean = false;
  name: any;

  Id: any;

  hname: any;
title:string="Branches List";

  ngOnInit() {
    this.getUseWithPermissions()
    this.getBranches();
    
    this.paramwinfrm = new FormGroup({
      hname: new FormControl("", Validators.compose([Validators.required])),

    });
  }


  getUseWithPermissions() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;

            this.isAllowedToAdd = pemsn.some(
              (permission) =>
                permission.permission === "Add Branches (a)" &&
                permission.permissionName === "true"
            );          

            this.isAllowedToDelete = pemsn.some(
              (permission) =>
                permission.permission === "Delete Branches (a)" &&
                permission.permissionName === "true"
            );
            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Update Branches (a)" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }
  getBranches() {
    const data = {
      requestType: "BRANCH_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.branchesListDatasource = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onParamsToolBarPreparingN(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Add",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.adduserGroups.bind(this),
      },
    });
  }

  adduserGroups() {
if(!this.isAllowedToAdd){
this.toastr.warning("You don't have permissions", "Failed")
  return;
}

    this.paramwinfrm.reset();
    this.showAddButton = true;
    this.showUpdateButton = false;
    this.paramswinpnlN = true;
  }

  postData() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all fields");
      return;
    }

    const data = {
      requestType: "BRANCH_CREATE",
      name: this.paramwinfrm.get("hname").value,


    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getBranches();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  update() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error(
        "Branch name is required"
      );
      return;
    }

    const data = {
      requestType: "BRANCH_UPDATE",
      name: this.paramwinfrm.get("hname").value,

   

      id: this.Id,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getBranches();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  delete() {
    const result = confirm("Are you sure you want to delete?", "");

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "BRANCH_DELETE",
          id: this.Id,
        };

        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.toastr.success(response.statusMessage);
              this.getBranches();
              this.paramswinpnlN = false;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  previewDetails(e) {   
    if (e.data) {
      this.showAddButton = false;
      this.showUpdateButton = true;

      this.hname = e.data.name;

 
      this.Id = e.data.id;
      this.paramswinpnlN = true;
    } else {
      this.showAddButton = true;
      this.showUpdateButton = false;
    }
  }
}
