import { Component, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AppSettings } from "src/app/app-settings";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-revenue-transaction",
  templateUrl: "./revenue-transaction.component.html",
  styleUrls: ["./revenue-transaction.component.scss"],
})
export class RevenueTransactionComponent
  extends SharedClassComponent
  implements OnInit
{
  title: string = "Generated Bills";

  tab_paneldata: any = [
    {
      ID: 1,
      icon: "fa fa-check-circle",
      name: "Valid Bills",
    },
    {
      ID: 2,
      icon: "fa fa-check-circle",
      name: "Cleared Bills",
    },
    {
      ID: 4,
      icon: "fa fa-check-circle",
      name: "Expired Bills",
    },
    {
      ID: 3,
      icon: "fa fa-check-circle",
      name: "Canceled Bills",
    },
  ];
  allClearedBillsEndPoint = "bills/cleared/all";
  getClearedBillsByDateRangeEndpoint = "bills/cleared/all/date/specific";

  getAllBillsEndpoint = "bills/sp";
  validBills = [];
  clearedBills = [];
  inactiveBills = [];
  canceledBills: [];

  endpointName = "portal/request";
  currentDate: any;
  currentUser;
  now: Date = new Date();

  popUpDialogVisible = false;
  openSummaryPopDialog = false;

  clearedBillsDataSource = [];
  incomingBills = [];
  billItemsDatasource = [];

  tzsAmountCounter: number = 0;
  usdAmountCounter: number = 0;
  tzsTxnCounter = 0;
  usdTxnCounter = 0;

  reportSummaryDataSource = [
    {
      totalTxn: 0,
      tzsTxn: 0,
      usdTxn: 0,
      tAmtTzs: 0,
      tAmtUsd: 0,
      recordDate: "",
    },
  ];

  oneDayAhead = new Date();
  today = new Date();
  userFirstName = this.authService.getUserDetails().firstName;
  userId = this.authService.getUserDetails().userId;
  validBillsForm: FormGroup;
  cancelledForm: FormGroup;
  ClearedForm: FormGroup;
  ExpiredForm: FormGroup;
  expiredBills: any;

  // Page numbers for each type of bill
  pageNumberClearedBill: number = 1;
  pageNumberValidBill: number = 1;
  pageNumberExpiredBill: number = 1;
  pageNumberCancelledBill: number = 1;

  // Page sizes for each type of bill
  pageSizeClearedBill: number = 40;
  pageSizeValidBill: number = 40;
  pageSizeExpiredBill: number = 40;
  pageSizeCancelledBill: number = 40;

  // Total page sizes for each type of bill
  totalPageSizeClearedBill: number = 1;
  totalPageSizeValidBill: number = 1;
  totalPageSizeExpiredBill: number = 1;
  totalPageSizeCancelledBill: number = 1;

  // Total item counts for each type of bill
  totalItemsCountClearedBill: number = 0;
  totalItemsCountValidBill: number = 0;
  totalItemsCountExpiredBill: number = 0;
  totalItemsCountCancelledBill: number = 0;
  ngOnInit() {
    //  this.spinner.show();
    //  this.toastr.info('Data loading in progress', 'Please wait');
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
    this.validBillsForm = new FormGroup({
      controlNo: new FormControl(""),
      invoiceNo: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });

    this.ClearedForm = new FormGroup({
      controlNo: new FormControl(""),
      invoiceNo: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });

    this.ExpiredForm = new FormGroup({
      controlNo: new FormControl(""),
      invoiceNo: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });

    this.cancelledForm = new FormGroup({
      controlNo: new FormControl(""),
      invoiceNo: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });

    this.getAllBills();
    // Controls the datagrid height and max rows to display
    this.observerCall();
  }
  customizeDate(data) {
    return (
      "From: " +
      new DatePipe("en-US").transform(data.value, "dd-MM-yyyy") +
      " to " +
      this.currentDate
    );
  }
  closePopUp() {
    this.popUpDialogVisible = false;
  }
  openSummaryDialog() {
    this.openSummaryPopDialog = true;
  }
  closeOpenSummaryPopDialog() {
    this.openSummaryPopDialog = false;
  }

  getAllBills() {
    this.getValidBills();
    this.getClearedBills();
    this.getCancelledBills();
    this.getExpiredBills();
  }
  getValidBills(pageNumber = 1) {
    let sDate;
    let eDate;

    if (this.validBillsForm.get("startDate").value == null) {
      this.validBillsForm.get("startDate").patchValue("");
    }

    if (this.validBillsForm.get("endDate").value == null) {
      this.validBillsForm.get("endDate").patchValue("");
    }

    const startDatestring =
      new Date(this.validBillsForm.get("startDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.validBillsForm.get("startDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.validBillsForm.get("startDate").value).getDate()
      ).slice(-2);
    const endDatestring =
      new Date(this.validBillsForm.get("endDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.validBillsForm.get("endDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.validBillsForm.get("endDate").value).getDate()
      ).slice(-2);

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }

    if (startDatestring.trim() == "NaN-aN-aN") {
      sDate = "";
    } else {
      sDate = startDatestring;
    }

    if (endDatestring.trim() == "NaN-aN-aN") {
      eDate = "";
    } else {
      eDate = endDatestring;
    }

    this.spinner.show();

    const data = {
      requestType: "BILLS_VALID_LIST",
      startDate: sDate,
      endDate: eDate,
      spId: sessionStorage.getItem("spId"),
      invoiceNo: this.validBillsForm.get("invoiceNo").value,
      controlNo: this.validBillsForm.get("controlNo").value,

      pageNumber: pageNumber,
      pageSize: this.pageSizeValidBill,
    };

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        this.validBills = response.data.validBills;

        this.totalPageSizeValidBill =
          response.totalPages == 0 ? 1 : response.totalPages;
        this.pageNumberValidBill = response.pageNumber;
        this.totalItemsCountValidBill = response.totalCount;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  getClearedBills(pageNumber = 1) {
    let sDate;
    let eDate;

    if (this.ClearedForm.get("startDate").value == null) {
      this.ClearedForm.get("startDate").patchValue("");
    }

    if (this.ClearedForm.get("endDate").value == null) {
      this.ClearedForm.get("endDate").patchValue("");
    }

    const startDatestring =
      new Date(this.ClearedForm.get("startDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.ClearedForm.get("startDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      ("0" + new Date(this.ClearedForm.get("startDate").value).getDate()).slice(
        -2
      );
    const endDatestring =
      new Date(this.ClearedForm.get("endDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.ClearedForm.get("endDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      ("0" + new Date(this.ClearedForm.get("endDate").value).getDate()).slice(
        -2
      );

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }

    if (startDatestring.trim() == "NaN-aN-aN") {
      sDate = "";
    } else {
      sDate = startDatestring;
    }

    if (endDatestring.trim() == "NaN-aN-aN") {
      eDate = "";
    } else {
      eDate = endDatestring;
    }

    this.spinner.show();

    const data = {
      requestType: "BILLS_CLEARED_LIST",
      startDate: sDate,
      endDate: eDate,
      spId: sessionStorage.getItem("spId"),
      invoiceNo: this.ClearedForm.get("invoiceNo").value,
      controlNo: this.ClearedForm.get("controlNo").value,
      pageNumber: pageNumber,
      pageSize: this.pageSizeClearedBill,
    };

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        this.clearedBills = response.data.clearedBills;

        this.totalPageSizeClearedBill =
          response.totalPages === 0 ? 1 : response.totalPages;
        this.pageNumberClearedBill = response.pageNumber;
        this.totalItemsCountClearedBill = response.totalCount;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  getExpiredBills(pageNumber = 1) {
    let sDate;
    let eDate;

    if (this.ExpiredForm.get("startDate").value == null) {
      this.ExpiredForm.get("startDate").patchValue("");
    }

    if (this.ExpiredForm.get("endDate").value == null) {
      this.ExpiredForm.get("endDate").patchValue("");
    }

    const startDatestring =
      new Date(this.ExpiredForm.get("startDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.ExpiredForm.get("startDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      ("0" + new Date(this.ExpiredForm.get("startDate").value).getDate()).slice(
        -2
      );
    const endDatestring =
      new Date(this.ExpiredForm.get("endDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.ExpiredForm.get("endDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      ("0" + new Date(this.ExpiredForm.get("endDate").value).getDate()).slice(
        -2
      );

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }

    if (startDatestring.trim() == "NaN-aN-aN") {
      sDate = "";
    } else {
      sDate = startDatestring;
    }

    if (endDatestring.trim() == "NaN-aN-aN") {
      eDate = "";
    } else {
      eDate = endDatestring;
    }

    this.spinner.show();

    const data = {
      requestType: "BILLS_EXPIRED_LIST",
      startDate: sDate,
      endDate: eDate,
      spId: sessionStorage.getItem("spId"),
      invoiceNo: this.ExpiredForm.get("invoiceNo").value,
      controlNo: this.ExpiredForm.get("controlNo").value,
      pageNumber: pageNumber,
      pageSize: this.pageSizeExpiredBill,
    };

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        this.expiredBills = response.data.expiredBills;
        this.totalPageSizeExpiredBill =
          response.totalPages === 0 ? 1 : response.totalPages;
        this.pageNumberExpiredBill = response.pageNumber;
        this.totalItemsCountExpiredBill = response.totalCount;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  getCancelledBills(pageNumber = 1) {
    let sDate;
    let eDate;

    if (this.cancelledForm.get("startDate").value == null) {
      this.cancelledForm.get("startDate").patchValue("");
    }

    if (this.cancelledForm.get("endDate").value == null) {
      this.cancelledForm.get("endDate").patchValue("");
    }

    const startDatestring =
      new Date(this.cancelledForm.get("startDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.cancelledForm.get("startDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.cancelledForm.get("startDate").value).getDate()
      ).slice(-2);
    const endDatestring =
      new Date(this.cancelledForm.get("endDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.cancelledForm.get("endDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      ("0" + new Date(this.cancelledForm.get("endDate").value).getDate()).slice(
        -2
      );

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }

    if (startDatestring.trim() == "NaN-aN-aN") {
      sDate = "";
    } else {
      sDate = startDatestring;
    }

    if (endDatestring.trim() == "NaN-aN-aN") {
      eDate = "";
    } else {
      eDate = endDatestring;
    }

    this.spinner.show();

    const data = {
      requestType: "BILLS_CANCELLED_LIST",
      startDate: sDate,
      endDate: eDate,
      spId: sessionStorage.getItem("spId"),
      invoiceNo: this.cancelledForm.get("invoiceNo").value,
      controlNo: this.cancelledForm.get("controlNo").value,
      pageNumber: pageNumber,
      pageSize: this.pageSizeCancelledBill,
    };

    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        this.canceledBills = response.data.canceledBills;

        this.totalPageSizeCancelledBill =
          response.totalPages === 0 ? 1 : response.totalPages;
        this.pageNumberCancelledBill = response.pageNumber;
        this.totalItemsCountCancelledBill = response.totalCount;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Error occurred while processing the request",
          "Request Failed"
        );
      }
    );
  }

  amountCounter(data) {
    this.tzsAmountCounter = 0;
    this.usdAmountCounter = 0;
    this.tzsTxnCounter = 0;
    this.usdTxnCounter = 0;
    for (const el of data) {
      if (el.currency == "TZS") {
        this.tzsAmountCounter += el.amount;
        this.tzsTxnCounter += 1;
      }
      if (el.currency == "USD") {
        this.usdAmountCounter += el.amount;
        this.usdTxnCounter += 1;
      }
    }
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          type: "default",
          icon: "help",
          hoverStateEnabled: false,
          hint: "Double click on the rows to Preview & Print Bill receipt",
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "default",
          onClick: refresh_action.bind(this),
        },
      }
    );
  }

  printOrderForm(e) {
    this.billItemsDatasource = [];

    if (e.billItems.length < 1) {
      this.billItemsDatasource.push({
        itemAmount: e.billAmount,
        description: e.description,
        itemCatName: e.catName,
        itemCurrency: e.currency,
      });
    } else {
      for (const iterator of e.billItems) {
        this.billItemsDatasource.push({
          itemAmount: iterator.amount,
          description: iterator.description,
          itemCatName: iterator.categoryName,
          itemCurrency: e.currency,
        });
      }
    }
    sessionStorage.removeItem(AppSettings.billDetailsKey);
    sessionStorage.removeItem(AppSettings.billItemsKey);
    sessionStorage.removeItem(AppSettings.bulkBillerKey);
    sessionStorage.removeItem(AppSettings.bulkBillerFlag);
    sessionStorage.setItem(AppSettings.bulkBillerFlag, "0");
    sessionStorage.setItem(AppSettings.billDetailsKey, JSON.stringify(e));
    sessionStorage.setItem(
      AppSettings.billItemsKey,
      JSON.stringify(this.billItemsDatasource)
    );
    this.router.navigate(["/bill-payment-form"]);
  }

  previewBill(e) {
    const result = confirm(
      "Please click the appropriate action to continue.",
      "Action"
    );
  }

  editBill(e) {
    sessionStorage.removeItem(AppSettings.billEditKey);
    sessionStorage.setItem(AppSettings.billEditKey, JSON.stringify(e));
    this.router.navigate(["/bill/create"], {
      queryParams: { billId: e.billId },
      queryParamsHandling: "merge",
    });
  }

  viewGeneretedBill(e) {
    this.router.navigate(["/view-cleared-bills"], {
      queryParams: { billId: e.billId },
      queryParamsHandling: "merge",
    });
  }

  customizeCount(data) {
    return "Total Transactions: " + data.value;
  }

  onPrintBillreceipt() {
    this.previewIshiddenField = false;
    let popUpWindow;
    let innerContents = document.getElementById("print-container").innerHTML;
    popUpWindow = window.open(
      "",
      "_blank",
      "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
    );
    // popupWinindow.document.open();
    popUpWindow.document.write(
      `<html>
   <head>
   <link rel="stylesheet" type="text/css" href="style.css" />
   <style>
   @media print {
    @page {
      size: A4;
      margin: 0mm;
    }

    html, body {
      width: 1024px;
    }

    body {
      margin: 0 auto;
      line-height: 1em;
      word-spacing:2px;
      letter-spacing:0.2px;
      font: 20px "Times New Roman", Times, serif;
      color:black;
      width: 100%;
      float: none;
      padding: 2rem;
    }


    /* avoid page-breaks inside a listingContainer*/
    .listingContainer{
      page-break-inside: avoid;
    }
    h1 {
        font: 28px "Times New Roman", Times, serif;
      }

      h2 {
        font: 24px "Times New Roman", Times, serif;
      }

      h3 {
        font: 20px "Times New Roman", Times, serif;
      }

      / Improve colour contrast of links /
      a:link, a:visited {
        color: #781351
      }

      / URL /
      a:link, a:visited {
        background: transparent;
        color:#333;
        text-decoration:none;
      }

      a[href]:after {
        content: "" !important;
      }
      a[href^="http://"] {
        color:#000;
      }
    }
   </style>
   </head>
   <body onload="window.print()"> ${innerContents} </html>`
    );
    popUpWindow.document.close();
    this.previewBillsWin = false;
  }

  goToNextPageClearedBill() {
    this.pageNumberClearedBill++;
    this.getClearedBills(this.pageNumberClearedBill);
  }

  goToPreviousPageClearedBill() {
    if (this.pageNumberClearedBill > 1) {
      this.pageNumberClearedBill--;
      this.getClearedBills(this.pageNumberClearedBill);
    }
  }

  goToNextPageValidBill() {
    this.pageNumberValidBill++;
    this.getValidBills(this.pageNumberValidBill);
  }

  goToPreviousPageValidBill() {
    if (this.pageNumberValidBill > 1) {
      this.pageNumberValidBill--;
      this.getValidBills(this.pageNumberValidBill);
    }
  }

  goToNextPageExpiredBill() {
    this.pageNumberExpiredBill++;
    this.getExpiredBills(this.pageNumberExpiredBill);
  }

  goToPreviousPageExpiredBill() {
    if (this.pageNumberExpiredBill > 1) {
      this.pageNumberExpiredBill--;
      this.getExpiredBills(this.pageNumberExpiredBill);
    }
  }

  goToNextPageCancelledBill() {
    this.pageNumberCancelledBill++;
    this.getCancelledBills(this.pageNumberCancelledBill);
  }

  goToPreviousPageCancelledBill() {
    if (this.pageNumberCancelledBill > 1) {
      this.pageNumberCancelledBill--;
      this.getCancelledBills(this.pageNumberCancelledBill);
    }
  }
}
