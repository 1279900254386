import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-get-customer-info",
  templateUrl: "./get-customer-info.component.html",
  styleUrls: ["./get-customer-info.component.scss"],
})
export class GetCustomerInfoComponent
  extends SharedClassComponent
  implements OnInit
{
  jobListList: any;
  tab_paneldata: any = [
    {
      ID: 1,
      icon: "fa fa-check-circle",
      name: "All Invoices",
    },
    {
      ID: 2,
      icon: "fa fa-check-circle",
      name: "Paid Invoices",
    },
    {
      ID: 3,
      icon: "fa fa-check-circle",
      name: "Unpaid Invoices",
    },

    {
      ID: 4,
      icon: "fa fa-check-circle",
      name: "Invoice Paid Without Control Number",
    },
    {
      ID: 5,
      icon: "fa fa-check-circle",
      name: "Invoice Payment List",
    },
  ];
  monthList = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  paramswinpnlN: boolean = false;
  showAddButton = false;
  showUpdateButton = false;
  serviceDate: any;
  serviceType: any;
  serviceAmount: any;
  Id: any;
  hserviceDate: any;
  catId: number = 0;
  hserviceAmount: any;
  currency: any;
  serviceProvidedTo: any;

  customerNo: any;
  // salesCatID: number=42; // prev 42
  conAviationfuelGroundCatID: number=142201120042;  // prev 43
  rentalFeeCatID: number=141501130003;  // prev 41 not exctly

  conDutyFreeShopServCatID: number=142201120043;
  conAirCraftcateringServiceServCatID: number=142201120044; 
  conOtherGroindHandlerCatID: number=142201120045; 
  conOtherShopAndServCatID: number=142201120046; 

  currencyDts = [
    {
      id: "TZS",
      text: "TZS",
    },
    {
      id: "USD",
      text: "USD",
    },
  ];
  spCatList: any;
  currentDFate = new Date();
  institutionCode = this.authService.getUserDetails().spId;
  customerList: any;
  paymentStatus: any;
  paymentStatusOne: Boolean = true;
  cus_no: any;
  allIncoicesList: any;
  paidInvoicesList: any;
  unPaidInvoicesList: any;
  customerName: any;
  customerEntryInfoForm: FormGroup;
  serviceEntryList: any[];
  invoicePaidManuallyList: any;
  invoicePaidManuallyWaitingApproval: any;
  selectedCustomerNo: any;
  userId = this.authService.getUserDetails().userId;

  periodOfRentDataSource = [
    {
      name: "Days",
    },

    {
      name: "Months",
    },

    {
      name: "Years",
    },

    {
      name: "Date Range",
    },
  ];
  numberOfDays: number = 0;
  updateRent: boolean = false;
  newCatList: any[] = [];
  ngOnInit() {
    this.paramwinfrm = new FormGroup({
      catId: new FormControl("", Validators.compose([Validators.required])),
      amount: new FormControl(""),
      time: new FormControl(""),
      sqmeter: new FormControl(""),
      dtSelect: new FormControl("Days"),
      startDate: new FormControl(new Date()),
      endDate: new FormControl(new Date()),

      sales: new FormControl(""),
      percCons: new FormControl(""),

      fuelLitres: new FormControl(""),
      fuelAmount: new FormControl(""),
    });

    this.customerEntryInfoForm = new FormGroup({
      month: new FormControl("", Validators.compose([Validators.required])),
      year: new FormControl("", Validators.compose([Validators.required])),
    });

    this.route.queryParams.subscribe((params) => {
      this.cus_no = params.cusno;
      this.customerName = params.name;

      if (this.cus_no) {
        this.getJobList(this.cus_no);
        this.getServiceEntryInitialData();
        this.getAllInvoicesListUnPaid(this.cus_no);
        this.getAllInvoicesListPaid(this.cus_no);
        this.getAllInvoicesList(this.cus_no);
      }
    });
    this.getCategoryBySp();
    this.getCustomerList();
    this.getInvoiceWaitingApproval();
    this.getInvoicePaidManually();
  }

  getServiceEntryInitialData() {
    const data = {
      requestType: "GET_SERVICE_RECEIVERS_LIST",
      customerNo: this.cus_no,
    };

    this.serviceEntryList = [];
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.serviceEntryList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getServiceEntry() {
    if (this.customerEntryInfoForm.invalid) {
      this.toastr.error("Enter a Valid Year and Month", "Error");
      return;
    }

    const data = {
      requestType: "SP_CUSTOMERS_SERVICE_RECEIVERS_LIST_BY_RANGE",
      month: this.customerEntryInfoForm.get("month").value,
      year: this.customerEntryInfoForm.get("year").value,
      custNo: this.cus_no,
    };
    this.serviceEntryList = [];
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.serviceEntryList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getAllInvoicesList(cus_no) {
    const data = {
      requestType: "INVOICES_LIST_CUSTOMERS",
      cusNo: cus_no,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.allIncoicesList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getAllInvoicesListPaid(cus_no) {
    const data = {
      requestType: "INVOICES_LIST_CUSTOMERS_PAID",
      cusNo: cus_no,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.paidInvoicesList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getAllInvoicesListUnPaid(cus_no) {
    const data = {
      requestType: "INVOICES_LIST_CUSTOMERS_UNPAID",
      cusNo: cus_no,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.unPaidInvoicesList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getCustomerList() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.customerList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getCategoryBySp() {
    const data = {
      requestType: "SP_SERVICE_CATEGORY_LIST",
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.spCatList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getInvoicePaidManually() {
    const data = {
      requestType: "INVOICES_PAYED_MANUALLY",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.invoicePaidManuallyList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getInvoiceWaitingApproval() {
    const data = {
      requestType: "INVOICES_PAYED_MANUALLY_WAITING_APPROVAL",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.invoicePaidManuallyWaitingApproval = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  getJobList(cus_no) {
    const data = {
      requestType: "SP_CUSTOMER_JOBS_LIST_GET",
      cusNo: cus_no,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.jobListList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onParamsToolBarPreparingN(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Add",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.addjobList.bind(this),
      },
    });
  }

  addjobList() {
    const firstArrayCatIds = this.jobListList.map((item) => item.catId);

    const filteredServices = this.spCatList.filter(
      (service) => !firstArrayCatIds.includes(service.catId.toString())
    );
    this.newCatList = filteredServices;

    this.paramwinfrm.reset();
    this.showAddButton = true;
    this.showUpdateButton = false;
    this.paramswinpnlN = true;
  }

  postData() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all required fields to proceed");
      return;
    }
    const catId = this.paramwinfrm.get("catId").value;

    this.updateRent = false;
    this.numberOfDays = 0;
    if (catId === 23 || catId === this.rentalFeeCatID) {
      this.validateRentAdDetails();
    }

    if (catId === this.conDutyFreeShopServCatID || catId === this.conAirCraftcateringServiceServCatID || catId === this.conOtherGroindHandlerCatID || catId === this.conOtherShopAndServCatID ) {
      this.validateSalesDetails();
    }


    if (catId === this.conAviationfuelGroundCatID) {
      this.validateFuelDetails();
    }
    const data = {
      requestType: "SP_CUSTOMER_JOB_CREATE",
      catId: this.paramwinfrm.get("catId").value,
      cusNo: this.cus_no,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          if (catId === this.conDutyFreeShopServCatID || catId === this.conAirCraftcateringServiceServCatID || catId === this.conOtherGroindHandlerCatID || catId === this.conOtherShopAndServCatID ) {
            this.saveConcession(1);
          }

          if (catId === this.conAviationfuelGroundCatID) {
            this.saveConcession(2);
          }
          if (this.updateRent) {
            this.saveRentAds(this.numberOfDays);
          }

          if (catId != 23 && this.catId != this.conDutyFreeShopServCatID && this.catId != this.conAirCraftcateringServiceServCatID && this.catId != this.conOtherGroindHandlerCatID && this.catId == this.conOtherShopAndServCatID && catId != this.conAviationfuelGroundCatID) {
            this.getJobList(this.cus_no);
            this.paramswinpnlN = false;
          }
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  validateSalesDetails() {
    if (
      !this.paramwinfrm.get("sales").value ||
      !this.paramwinfrm.get("percCons").value
    ) {
      this.toastr.error("Please Enter Sales Amount and percentage");
      return;
    }
  }

  validateFuelDetails() {
    if (
      !this.paramwinfrm.get("fuelLitres").value ||
      !this.paramwinfrm.get("fuelAmount").value
    ) {
      this.toastr.error("Fuel Litres And Fuel Amount");
      return;
    }
  }
  validateInput(event: any) {
    const value = event.value;
    if (value < 1 || value > 100) {
      event.component.value = null;
    }
  }

  limitInput(event: any) {
    const inputElement = event.event.target as HTMLInputElement;
    if (inputElement.value.length > 2) {
      inputElement.value = inputElement.value.slice(0, 2);
      event.component.value = Number(inputElement.value);
    }
  }

  validateRentAdDetails() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all required fields to proceed");
      return;
    }
    const catId = this.paramwinfrm.get("catId").value;
    const dtSelect = this.paramwinfrm.get("dtSelect").value;
    if (catId === 23 || catId === this.rentalFeeCatID) {
      if (
        !this.paramwinfrm.get("amount").value ||
        !this.paramwinfrm.get("sqmeter").value
      ) {
        this.toastr.error(
          "Please Enter square meter and Price Per Square Meter"
        );
        return;
      }

      if (dtSelect === "Date Range") {
        const startDate = new Date(this.paramwinfrm.get("startDate").value);
        const endDate = new Date(this.paramwinfrm.get("endDate").value);

        if (!startDate || !endDate) {
          this.toastr.error("Start Date and End Date are required");
          return;
        }

        if (startDate > endDate) {
          this.toastr.error("Start Date should not be greater than End Date");
          return;
        }

        this.numberOfDays =
          (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
        console.log("Number of days for Date Range:", this.numberOfDays);
      } else {
        const time = this.paramwinfrm.get("time").value;
        if (!time) {
          this.toastr.error(`Enter Number of ${dtSelect}`);
          return;
        }

        this.numberOfDays = 0;
        if (dtSelect === "Days") {
          this.numberOfDays = time;
        } else if (dtSelect === "Months") {
          this.numberOfDays = time * 30;
        } else if (dtSelect === "Years") {
          this.numberOfDays = time * 365;
        }

        this.updateRent = true;
      }
    }
  }

  updateRentAdDetails() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all required fields to proceed");
      return;
    }

    const catId = this.paramwinfrm.get("catId").value;
    if (catId === 23 || catId === this.rentalFeeCatID) {
      this.validateRentAdDetails();
    }

    if (this.numberOfDays == 0) {
      this.toastr.error("Number of Days can not be 0");
      return;
    }

    const data = {
      requestType: "SP_CUSTOMER_JOB_RENTDETAILS_UPDATE",
      // catId: this.paramwinfrm.get("catId").value,
      cusNo: this.cus_no,
      sqmeter: this.paramwinfrm.get("sqmeter").value,
      price: this.paramwinfrm.get("amount").value,
      time: this.numberOfDays,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.updateRent = false;
          this.toastr.success(response.statusMessage);
          this.getJobList(this.cus_no);
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  updateConcessionDetails() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all required fields to proceed");
      return;
    }

    const catId = this.paramwinfrm.get("catId").value;
    if (catId === this.conDutyFreeShopServCatID || catId === this.conAirCraftcateringServiceServCatID || catId === this.conOtherGroindHandlerCatID || catId === this.conOtherShopAndServCatID ) {
      this.validateSalesDetails();
    }

    if (catId === this.conAviationfuelGroundCatID) {
      this.validateFuelDetails();
    }
    let data;
    if (catId === this.conDutyFreeShopServCatID || catId === this.conAirCraftcateringServiceServCatID || catId === this.conOtherGroindHandlerCatID || catId === this.conOtherShopAndServCatID ){
      data = {
        requestType: "SP_CUSTOMER_JOB_CONCESSIONDETAILS_UPDATE",
        catId: this.paramwinfrm.get("catId").value,
        cusNo: this.cus_no,
        sales: this.paramwinfrm.get("sales").value,
        percCons: this.paramwinfrm.get("percCons").value,
        fuelLitres: null,
        fuelAmount: null,
      };
    } else {
      data = {
        requestType: "SP_CUSTOMER_JOB_CONCESSIONDETAILS_UPDATE",
        catId: this.paramwinfrm.get("catId").value,
        cusNo: this.cus_no,
        fuelLitres: this.paramwinfrm.get("fuelLitres").value,
        fuelAmount: this.paramwinfrm.get("fuelAmount").value,
        sales: null,
        percCons: null,
      };
    }

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.updateRent = false;
          this.toastr.success(response.statusMessage);
          this.getJobList(this.cus_no);
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  saveConcession(type) {
    let data;
    if (type == 1) {
      data = {
        requestType: "SP_CUSTOMER_JOB_CONCESSIONDETAILS_CREATE",
        catId: this.paramwinfrm.get("catId").value,
        cusNo: this.cus_no,
        sales: this.paramwinfrm.get("sales").value,
        percCons: this.paramwinfrm.get("percCons").value,
        fuelLitres: null,
        fuelAmount: null,
      };
    } else {
      data = {
        requestType: "SP_CUSTOMER_JOB_CONCESSIONDETAILS_CREATE",
        catId: this.paramwinfrm.get("catId").value,
        cusNo: this.cus_no,
        fuelLitres: this.paramwinfrm.get("fuelLitres").value,
        fuelAmount: this.paramwinfrm.get("fuelAmount").value,
        sales: null,
        percCons: null,
      };
    }

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.updateRent = false;
          this.toastr.success(response.statusMessage);
          this.getJobList(this.cus_no);

          this.paramswinpnlN = false;
          return;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  saveRentAds(days) {
    const data = {
      requestType: "SP_CUSTOMER_JOB_RENTDETAILS_CREATE",
      catId: this.paramwinfrm.get("catId").value,
      cusNo: this.cus_no,
      sqmeter: this.paramwinfrm.get("sqmeter").value,
      price: this.paramwinfrm.get("amount").value,
      time: days,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.updateRent = false;
          this.toastr.success(response.statusMessage);
          this.getJobList(this.cus_no);
          this.paramswinpnlN = false;
          return;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  delete() {
    const result = confirm("Are you sure you want to delete?", "");

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "SP_CUSTOMER_JOBS_DELETE",

          jobId: this.Id,
        };

        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.toastr.success(response.statusMessage);
              this.getJobList(this.cus_no);
              this.paramswinpnlN = false;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  confirmPosting(invoiceId, status) {
    const result = confirm(
      `Are you sure you want to ${
        status === 1 ? "Approve" : "Decline"
      } Invoice Payment?`,
      `${status === 1 ? "Approve" : "Decline"} Confirmation`
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "INVOICES_RECEIVE_PAYMENT_APPROVAL",
          invoiceId: invoiceId,
          paidWithNoCno: status,
        };

        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.getInvoiceWaitingApproval();
              this.getInvoicePaidManually();
              this.toastr.success(response.statusMessage);
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  previewcustomerNo(e) {
    if (e.data) {
      this.showAddButton = false;
      this.showUpdateButton = true;

      this.catId = e.data.catId ? Number(e.data.catId) : e.data.catId;
      this.serviceAmount = e.data.serviceAmount;
      this.selectedCustomerNo = e.data.customerNo;

      this.Id = e.data.jobId;

      if (this.catId == this.conAviationfuelGroundCatID) {
        this.getRentalDetails(this.catId, this.selectedCustomerNo);
      }

      if (this.catId == this.conDutyFreeShopServCatID || this.catId == this.conAirCraftcateringServiceServCatID || this.catId == this.conOtherGroindHandlerCatID ||this.catId == this.conOtherShopAndServCatID) {
        this.getConcession(this.catId, this.selectedCustomerNo);
      }

      if (this.catId == this.conAviationfuelGroundCatID) {
        this.getConcession(this.catId, this.selectedCustomerNo);
      }
      if (this.catId != 23 && this.catId != this.conDutyFreeShopServCatID && this.catId != this.conAirCraftcateringServiceServCatID && this.catId != this.conOtherGroindHandlerCatID && this.catId == this.conOtherShopAndServCatID && this.catId != this.conAviationfuelGroundCatID) {
        this.paramswinpnlN = true;
      }
    } else {
      this.showAddButton = true;
      this.showUpdateButton = false;
    }
  }

  getConcession(catId, cusNo) {
    const data = {
      requestType: "SP_CUSTOMER_JOB_CONCESSIONDETAILS_GET",
      catId: catId,
      cusNo: cusNo,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data[0];

          if (data) {
            this.paramwinfrm.get("sales").patchValue(data.sales);
            this.paramwinfrm
              .get("percCons")
              .patchValue(data.percentOfConcession);
            this.paramwinfrm.get("fuelLitres").patchValue(data.fuelLitres);
            this.paramwinfrm.get("fuelAmount").patchValue(data.fuelAmount);
            this.paramswinpnlN = true;
          }
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getRentalDetails(catId, cusNo) {
    const data = {
      requestType: "SP_CUSTOMER_JOB_RENTDETAILS_GET",
      catId: catId,
      cusNo: cusNo,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data[0];

          if (data) {
            this.paramwinfrm.get("sqmeter").patchValue(data.sqMeter);
            this.paramwinfrm.get("amount").patchValue(data.rentPrice);
            this.paramwinfrm.get("time").patchValue(data.rentTime);
            this.paramwinfrm.get("dtSelect").patchValue("Days");
            this.paramswinpnlN = true;
          }
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  printInvoice(invoiceId) {
    this.router.navigate(["/invoice-print"], {
      queryParams: { invoiceId: invoiceId },
      queryParamsHandling: "merge",
    });
  }

  invoiceDetails(e, isUnpaid: Boolean) {
    const id = e.data.ID;
    console.log(id);
    this.router.navigate(["/invoice-details"], {
      queryParams: { invoiceId: id, isUnpaid: isUnpaid },
      queryParamsHandling: "merge",
    });
  }
  initiateCreateInvoice() {
    if (!this.selectedCustomerNo || !this.catId) {
      this.toastr.error("Customer Number and Category ID");
      return;
    }

    this.router.navigate(["/invoice/create"], {
      queryParams: { catId: this.catId },
      queryParamsHandling: "merge",
    });
  }
}
