
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-valid-bill-report',
  templateUrl: './valid-bill-report.component.html',
  styleUrls: ['./valid-bill-report.component.scss']
})
export class ValidBillReportComponent extends SharedClassComponent implements OnInit {
  title = 'Valid Bills Report';
  oneDayAhead = new Date();
  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];
  customerList: any;
  departmentList: any;
  branchList: any;
  currencyList = ["TZS", "USD"];

  billTypeList=[{
    "id": "1",
    "name": "With Invoice"
  },
  {
    "id": "2",
    "name": "Without Invoice"
  }]
  spCatList: any;
  revenueTypeList: any;
  totalBillAmountData: any=[];
  selectedEndDate: string;
  selectedStartDate: string;
  ngOnInit() {
        //  this.spinner.show();
        this.toastr.info('Data loading in progress', 'Please wait');
        // sets the current page title in index.html title tag
        this.appInfo.setTitle(this.title);

        this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);
        this.paramwinfrm = new FormGroup({
          customerId: new FormControl(""),
          department: new FormControl(""),
          revenueSource: new FormControl(""),
          currency: new FormControl(""),
          branch: new FormControl(""),
          revenueType: new FormControl(""),
          inInv: new FormControl(""),
          startDate: new FormControl(this.today, Validators.compose([Validators.required])),
          endDate: new FormControl(this.today, Validators.compose([Validators.required]))
        });

        const dd = String(this.today.getDate()).padStart(2, '0');
        const mm = String(this.today.getMonth() + 1).padStart(2, '0'); // January is 0
        const yyyy = this.today.getFullYear();

        //  this.currentDate = dd + '-' + mm + '-' + yyyy;
        this.currentDate = yyyy + '-' + mm + '-' + dd;
        this.getValidBillsByDateRange();
        // Controls the datagrid height and max rows to display
        this.observerCall();
        this.currentUser = this.authService.getUserDetails().username;
        this.getCustomerList();
        this.getBranchList();
        this.getDepartmentList();
        this.getCategoryBySp()
        this.getRevenueTypeList();
      }
    
    


      getRevenueTypeList() {
        const data = {
          requestType: "SP_SERVICE_CATEGORY_TYPES_LIST",
         
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const srvRes = res.json();
    
            if (srvRes.statusId == 2000) {
              this.revenueTypeList = srvRes.data;
              this.spinner.hide();
            } else {
              this.toastr.error(
                "Failed to load Service Categories",
                srvRes.statusMessage
              );
            }
            this.spinner.hide();
          },
          (error) => {
            this.toastr.error(error);
            this.spinner.hide();
          }
        );
      }
    
    

      getCategoryBySp() {
        const data = {
          requestType: "SP_SERVICE_CATEGORY_LIST",
          spId: sessionStorage.getItem('spId'),
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const srvRes = res.json();
    
            if (srvRes.statusId == 2000) {
              this.spCatList = srvRes.data;
              this.spinner.hide();
            } else {
              this.toastr.error(
                "Failed to load Service Categories",
                srvRes.statusMessage
              );
            }
            this.spinner.hide();
          },
          (error) => {
            this.toastr.error(error);
            this.spinner.hide();
          }
        );
      }
    
    
    
    
      getCustomerList() {
        const data = {
          requestType: "SP_CUSTOMER_LIST",
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.customerList = response.data;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    
      getBranchList() {
        const data = {
          requestType: "BRANCH_LIST",
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.branchList = response.data;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    
      getDepartmentList() {
        const data = {
          requestType: "USERGROUP_LIST",
        };
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.departmentList = response.data;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }



  getValidBillsByDateRange(pageNumber = 1) {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and End Date are required.");
      return;
    } 

   
    const startDate = new Date(this.paramwinfrm.get("startDate").value);
    const startDatestring =
      startDate.getFullYear() +
      "-" +
      String(startDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(startDate.getDate()).padStart(2, "0");
    

    const endDate = new Date(this.paramwinfrm.get("endDate").value);
    const endDatestring =
      endDate.getFullYear() +
      "-" +
      String(endDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(endDate.getDate()).padStart(2, "0");
    

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than End Date.");
      this.spinner.hide();
      return;
    }    
    this.spinner.show();
    const data = {
              "requestType": "VALID_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId:  sessionStorage.getItem('spId'),
      customerId: this.paramwinfrm.get("customerId").value != null ? this.paramwinfrm.get("customerId").value.toString() : "",
      department: this.paramwinfrm.get("department").value != null ? this.paramwinfrm.get("department").value.toString() : "",
      branch: this.paramwinfrm.get("branch").value != null ? this.paramwinfrm.get("branch").value.toString() : "",
      revenueSource: this.paramwinfrm.get("revenueSource").value,
      currency: this.paramwinfrm.get("currency").value,
      revenueType:this.paramwinfrm.get("revenueType").value != null ? this.paramwinfrm.get("revenueType").value.toString() : "",
   inInv: this.paramwinfrm.get("inInv").value != null ? this.paramwinfrm.get("inInv").value : "",
   pageNumber: pageNumber,
   pageSize: this.pageSizePaginator,


    };

    this.selectedStartDate = startDatestring;
    this.selectedEndDate = endDatestring;
    this.utilities.postServiceCall(data).subscribe(
      response => {
        const res = response.json();
        this.datasource = res.data.validBills;
        
        const data = res.data;  

  
        if(data.currencies){
          this.totalBillAmountData =data.currencies;

        }
        this.totalPageSize = data.totalPages == 0 ? 1 : data.totalPages;
        this.pageNumber = data.pageNumber;
        this.totalItemsCount = data.billsCount;

        this.spinner.hide();
        this.toastr.info('Data has finished loading', 'Process Completed');
      },
      error => {
        this.spinner.hide();
        this.toastr.error(
          'Error occurred while processing the request',
          'Request Failed'
        );
      }
    );
  }

  
  toolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Print",
          type: "default",
          icon: "fa fa-print",
          onClick: this.PrintSingle.bind(this),
        },
      },

      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Print All",
          type: "default",
          icon: "fa fa-print",
          onClick: this.exportData.bind(this, "print"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          type: "default",
          icon: "fa fa-file-pdf-o",
          onClick: this.exportData.bind(this, "pdf"),
        },
      },

      {
        location: "after",
        widget: "dxButton",
        options: {
          type: "default",
          icon: "fa fa-file-excel-o",
          onClick: this.exportData.bind(this, "excel"),
        },
      }
    );
  }
  PrintSingle() {
    this.spinner.show();
    this.printDatasource = [];

    this.printDatasource = this.datasource;
    setTimeout(() => {
      this.spinner.hide();

      if (this.printDatasource.length === 0) {
        this.toastr.warning("No data available for printing.");
      } else {
        this.printReport();
      }
    }, 1000);
  }

  exportData(type: string) {
    if (this.datasource.length === 0) {
      this.toastr.warning("No data Available.");
      return;
    }
    this.selectedExportType = type;
    this.spinner.show();
    if (this.allDataHasBeenLoaded) {
      if (this.printDatasource.length != this.printDatasource.length) {
        setTimeout(() => {
          this.spinner.hide();
          this.printDatasource = this.tempPrintAllDatasource;
        }, 1000);
      } else {
        this.spinner.hide();
        this.exportBasedOnType();

        return;
      }
    }
    this.printDatasource = [];
    this.totalFetchedItems = 0;
    this.isFetchingAllData = true;
    this.spinner.show();
    this.toastr.info("Loading all data for. Please wait...");
    this.fetchPageForPrinting(1);
  }

  private fetchPageForPrinting(pageNumber) {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Start Date and End Date are required.");
      this.spinner.hide();
      this.isFetchingAllData = false;
      return;
    }

    const startDate = new Date(this.paramwinfrm.get("startDate").value);
    const startDatestring = `${startDate.getFullYear()}-${String(
      startDate.getMonth() + 1
    ).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`;

    const endDate = new Date(this.paramwinfrm.get("endDate").value);
    const endDatestring = `${endDate.getFullYear()}-${String(
      endDate.getMonth() + 1
    ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than End Date.");
      this.spinner.hide();
      this.isFetchingAllData = false;
      return;
    }

    const data = {
      requestType: "VALID_BILLS_REPORT",
      startDate: startDatestring,
      endDate: endDatestring,
      spId: sessionStorage.getItem("spId"),
      customerId:
        this.paramwinfrm.get("customerId").value != null
          ? this.paramwinfrm.get("customerId").value.toString()
          : "",
      department:
        this.paramwinfrm.get("department").value != null
          ? this.paramwinfrm.get("department").value.toString()
          : "",
      branch:
        this.paramwinfrm.get("branch").value != null
          ? this.paramwinfrm.get("branch").value.toString()
          : "",
      revenueSource: this.paramwinfrm.get("revenueSource").value,
      currency: this.paramwinfrm.get("currency").value,
      revenueType:
        this.paramwinfrm.get("revenueType").value != null
          ? this.paramwinfrm.get("revenueType").value.toString()
          : "",
      pageNumber: pageNumber,
      pageSize: this.pageSizePaginatorAll,
    };

    this.utilities.postServiceCall(data).subscribe(
      (response) => {
        const res = response.json();
        const responseData = res.data;

        if (pageNumber === 1) {
          this.totalPageSizePrint = responseData.totalPages;
          this.totalItemsCount = responseData.billsCount;
        }

        if (responseData.validBills && responseData.validBills.length > 0) {
          this.printDatasource = this.printDatasource.concat(
            responseData.validBills
          );
          this.totalFetchedItems += responseData.validBills.length;
          this.tempPrintAllDatasource = this.printDatasource;
        }

        const totalPages = this.totalPageSizePrint
          ? this.totalPageSizePrint
          : 1;
        const progressPercentage = Math.round((pageNumber / totalPages) * 100);
        this.toastr.clear();
        this.toastr.info(
          `Loading data: ${progressPercentage}% complete (${this.totalFetchedItems}/${this.totalItemsCount} records)`
        );
        if (pageNumber < responseData.totalPages) {
          this.fetchPageForPrinting(pageNumber + 1);
        } else {
          this.spinner.hide();
          this.isFetchingAllData = false;

          if (this.printDatasource.length === 0) {
            this.toastr.warning("No data available.");
          } else {
            setTimeout(() => {
              this.allDataHasBeenLoaded = true;

              this.exportBasedOnType();
            }, 1000);
          }
        }
      },
      (error) => {
        this.spinner.hide();
        this.isFetchingAllData = false;
        this.toastr.error(
          "Error occurred while loading data",
          "Request Failed"
        );
      }
    );
  }

  exportBasedOnType() {
    switch (this.selectedExportType) {
      case "print":
        this.printReport();
        break;
      case "pdf":
        this.downloadToPDF("valid_bills_report");
        break;

      case "excel":
        this.exportToExcel();
        break;

      default:
        this.printReport();
        break;
    }
  }

  exportToExcel() {
    let totalAmountsText = "";
    if (this.totalBillAmountData && this.totalBillAmountData.length > 0) {
      totalAmountsText = this.totalBillAmountData
        .map((total) => `${total.currency}: ${total.amount.toFixed(2)}`)
        .join(", ");
    }

    this.onExporting({
      subtitle: this.getTitle(),
      worksheetName: this.title,
      filename: "valid_bills_report.xlsx",
      summary: totalAmountsText
        ? `Total Bill Amounts: ${totalAmountsText}`
        : "",
      columns: [
        { key: "billId", header: "Bill ID", width: 15 },
        { key: "controlNumber", header: "Control Number", width: 20 },
        { key: "billAmount", header: "Bill Amount", width: 15 },
        { key: "currency", header: "Currency", width: 10 },
        { key: "billStatus", header: "Bill Status", width: 15 },
        { key: "payerName", header: "Payer Name", width: 20 },
        { key: "payerMobile", header: "Payer Mobile", width: 15 },
        { key: "pmtOptName", header: "Payment Options", width: 20 },
        {
          key: "createdAt",
          header: "Creation Date",
          width: 15,
          formatter: (value) => new Date(value).toLocaleDateString("en-GB"),
        },
      ],
      data: this.printDatasource,
    });
  }

  goToNextPage() {
    this.pageNumber++;
    this.getValidBillsByDateRange(this.pageNumber);
  }

  goToPreviousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.getValidBillsByDateRange(this.pageNumber);
    }
  }

  getTitle() {
    const customerID = this.paramwinfrm.get("customerId").value;

    let customerName;
    if (this.customerList) {
      const selectedCustomer = this.customerList.find(
        (customer) => customer.cus_no === customerID
      );
      customerName = selectedCustomer ? ` for ${selectedCustomer.Name}` : "";
    }

    const dateRange =
      this.selectedStartDate && this.selectedEndDate
        ? ` between ${this.selectedStartDate} and ${this.selectedEndDate}`
        : "";

        const date= ` as of ${this.getCurrentDate()}`

        const title = `${this.title || ""}${customerName}${dateRange} ${date}`.trim() ;  
    
        return title || this.title + date;
  }
}
