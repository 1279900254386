import { Component, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AppSettings } from "src/app/app-settings";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-genereted-invoices",
  templateUrl: "./genereted-invoices.component.html",
  styleUrls: ["./genereted-invoices.component.scss"],
})
export class GeneretedInvoicesComponent
  extends SharedClassComponent
  implements OnInit
{
  title: string = "Generated Invoices";

  tab_paneldata: any = [
    {
      ID: 1,
      icon: "fa fa-check-circle",
      name: "All Invoices",
    },
    {
      ID: 2,
      icon: "fa fa-check-circle",
      name: "Paid Invoices",
    },
    {
      ID: 3,
      icon: "fa fa-check-circle",
      name: "Unpaid Invoices",
    },

    // {
    //   ID: 4,
    //   icon: "fa fa-times-circle",
    //   name: "Canceled Invoices",
    // },
  ];
  allIncoicesList: any;
  paidInvoicesList: any;
  unPaidInvoicesList: any;
  allClearedBillsEndPoint = "bills/cleared/all";
  getClearedBillsByDateRangeEndpoint = "bills/cleared/all/date/specific";

  getAllBillsEndpoint = "bills/sp";
  validBills = [];
  clearedBills = [];
  inactiveBills = [];

  endpointName = "portal/request";
  currentDate: any;
  currentUser;
  now: Date = new Date();

  popUpDialogVisible = false;
  openSummaryPopDialog = false;

  clearedBillsDataSource = [];
  incomingBills = [];
  billItemsDatasource = [];

  tzsAmountCounter: number = 0;
  usdAmountCounter: number = 0;
  tzsTxnCounter = 0;
  usdTxnCounter = 0;

  reportSummaryDataSource = [
    {
      totalTxn: 0,
      tzsTxn: 0,
      usdTxn: 0,
      tAmtTzs: 0,
      tAmtUsd: 0,
      recordDate: "",
    },
  ];

  oneDayAhead = new Date();
  today = new Date();
  userFirstName = this.authService.getUserDetails().firstName;
  userId = this.authService.getUserDetails().userId;
  isAllowedToUpdate: any;

  allInvoiceForm: FormGroup;
  paidInvoiceForm: FormGroup;
  unPaidInvoiceForm: FormGroup;
  customerList: any;
  canceledInvoicesList: any = [];
  isAllowedToResend: any;
  pageNumberPaid: number = 1;
  pageNumberUnPaid: number = 1;

  pageSizePaginatorPaid: number = 40;
  pageSizePaginatorUnPaid: number = 40;



 totalPageSizePaid: number = 1;
  totalPageSizeUnPaid: number = 1;


  totalItemsCountPaid: number = 0;
  totalItemsCountUnPaid: number = 0;



  ngOnInit() {
    this.getCustomerList();
    this.getServiceProviderAccount();
    //  this.spinner.show();
    this.toastr.info("Data loading in progress", "Please wait");

    this.appInfo.setTitle(this.title);
    this.oneDayAhead.setDate(this.oneDayAhead.getDate() + 1);

    this.allInvoiceForm = new FormGroup({
      payerId: new FormControl(""),
      invoiceNo: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });

    this.paidInvoiceForm = new FormGroup({
      payerId: new FormControl(""),
      invoiceNo: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });

    this.unPaidInvoiceForm = new FormGroup({
      payerId: new FormControl(""),
      invoiceNo: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });
  
    this.getAllInvoicesListUnPaid();
    this.getAllInvoicesListPaid();
    this.getAllInvoicesList();
    this.getCanceledInvoices();

    this.observerCall();
  }

  getCustomerList() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.customerList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getServiceProviderAccount() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;
            this.isAllowedToUpdate = pemsn.some(
              (permission) =>
                permission.permission === "Can Update Invoice" &&
                permission.permissionName === "true"
            );


            this.isAllowedToResend = pemsn.some(
              (permission) =>
                permission.permission === "Can Resend Invoice" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }
  customizeDate(data) {
    return (
      "From: " +
      new DatePipe("en-US").transform(data.value, "dd-MM-yyyy") +
      " to " +
      this.currentDate
    );
  }
  closePopUp() {
    this.popUpDialogVisible = false;
  }
  openSummaryDialog() {
    this.openSummaryPopDialog = true;
  }
  closeOpenSummaryPopDialog() {
    this.openSummaryPopDialog = false;
  }

  getAllInvoicesListPaid(pageNumber = 1) {
    let sDate;
    let eDate;

    if (this.paidInvoiceForm.get("startDate").value == null) {
      this.paidInvoiceForm.get("startDate").patchValue("");
    }

    if (this.paidInvoiceForm.get("endDate").value == null) {
      this.paidInvoiceForm.get("endDate").patchValue("");
    }

    const startDatestring =
      new Date(this.paidInvoiceForm.get("startDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.paidInvoiceForm.get("startDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.paidInvoiceForm.get("startDate").value).getDate()
      ).slice(-2);
    const endDatestring =
      new Date(this.paidInvoiceForm.get("endDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.paidInvoiceForm.get("endDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.paidInvoiceForm.get("endDate").value).getDate()
      ).slice(-2);

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }

    if (startDatestring.trim() == "NaN-aN-aN") {
      sDate = "";
    } else {
      sDate = startDatestring;
    }

    if (endDatestring.trim() == "NaN-aN-aN") {
      eDate = "";
    } else {
      eDate = endDatestring;
    }

    this.spinner.show();

    const data = {
      requestType: "INVOICES_LIST_PAID",
      startDate: sDate,
      endDate: eDate,
      invoiceNo: this.paidInvoiceForm.get("invoiceNo").value,
      payerId: this.paidInvoiceForm.get("payerId").value,
      pageNumber: pageNumber,
      pageSize: this.pageSizePaginatorPaid,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.paidInvoicesList = response.data;
          this.totalPageSizePaid = response.totalPages == 0 ? 1 : response.totalPages;
          this.pageNumberPaid = response.pageNumber;
          this.totalItemsCountPaid = response.totalCount;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getAllInvoicesList(pageNumber = 1) {
    let sDate;
    let eDate;

    if (this.allInvoiceForm.get("startDate").value == null) {
      this.allInvoiceForm.get("startDate").patchValue("");
    }

    if (this.allInvoiceForm.get("endDate").value == null) {
      this.allInvoiceForm.get("endDate").patchValue("");
    }

    const startDatestring =
      new Date(this.allInvoiceForm.get("startDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.allInvoiceForm.get("startDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.allInvoiceForm.get("startDate").value).getDate()
      ).slice(-2);
    const endDatestring =
      new Date(this.allInvoiceForm.get("endDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.allInvoiceForm.get("endDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.allInvoiceForm.get("endDate").value).getDate()
      ).slice(-2);

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }

    if (startDatestring.trim() == "NaN-aN-aN") {
      sDate = "";
    } else {
      sDate = startDatestring;
    }

    if (endDatestring.trim() == "NaN-aN-aN") {
      eDate = "";
    } else {
      eDate = endDatestring;
    }

    this.spinner.show();

    const data = {
      requestType: "INVOICES_LIST",
      startDate: sDate,
      endDate: eDate,
      invoiceNo: this.allInvoiceForm.get("invoiceNo").value,
      payerId: this.allInvoiceForm.get("payerId").value,
      pageNumber: pageNumber,
      pageSize: this.pageSizePaginator,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.allIncoicesList = response.data;
          this.totalPageSize = response.totalPages == 0 ? 1 : response.totalPages;
          this.pageNumber = response.pageNumber;
          this.totalItemsCount = response.totalCount;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getAllInvoicesListUnPaid(pageNumber = 1) {
    let sDate;
    let eDate;

    if (this.unPaidInvoiceForm.get("startDate").value == null) {
      this.unPaidInvoiceForm.get("startDate").patchValue("");
    }

    if (this.unPaidInvoiceForm.get("endDate").value == null) {
      this.unPaidInvoiceForm.get("endDate").patchValue("");
    }

    const startDatestring =
      new Date(this.unPaidInvoiceForm.get("startDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.unPaidInvoiceForm.get("startDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.unPaidInvoiceForm.get("startDate").value).getDate()
      ).slice(-2);
    const endDatestring =
      new Date(this.unPaidInvoiceForm.get("endDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.unPaidInvoiceForm.get("endDate").value).getMonth() + 1)
      ).slice(-2) +
      "-" +
      (
        "0" + new Date(this.unPaidInvoiceForm.get("endDate").value).getDate()
      ).slice(-2);

    if (new Date(startDatestring) > new Date(endDatestring)) {
      this.toastr.error("Start Date is greater than end Date.");
      this.spinner.hide();
      return;
    }

    if (startDatestring.trim() == "NaN-aN-aN") {
      sDate = "";
    } else {
      sDate = startDatestring;
    }

    if (endDatestring.trim() == "NaN-aN-aN") {
      eDate = "";
    } else {
      eDate = endDatestring;
    }

    this.spinner.show();

    const data = {
      requestType: "INVOICES_LIST_UNPAID",
      startDate: sDate,
      endDate: eDate,
      invoiceNo: this.unPaidInvoiceForm.get("invoiceNo").value,
      payerId: this.unPaidInvoiceForm.get("payerId").value,
      pageNumber: pageNumber,
      pageSize: this.pageSizePaginatorUnPaid,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.unPaidInvoicesList = response.data;
       
          this.totalPageSizeUnPaid = response.totalPages == 0 ? 1 : response.totalPages;
          this.pageNumberUnPaid = response.pageNumber;
          this.totalItemsCountUnPaid = response.totalCount;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getCanceledInvoices() {
    const data = {
      requestType: "ZM_CANCELLATION_PENDING",
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.canceledInvoicesList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  amountCounter(data) {
    this.tzsAmountCounter = 0;
    this.usdAmountCounter = 0;
    this.tzsTxnCounter = 0;
    this.usdTxnCounter = 0;
    for (const el of data) {
      if (el.currency == "TZS") {
        this.tzsAmountCounter += el.amount;
        this.tzsTxnCounter += 1;
      }
      if (el.currency == "USD") {
        this.usdAmountCounter += el.amount;
        this.usdTxnCounter += 1;
      }
    }
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          type: "default",
          icon: "help",
          hoverStateEnabled: false,
          hint: "Double click on the rows to Preview & Print Bill receipt",
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "default",
          onClick: refresh_action.bind(this),
        },
      }
    );
  }

  printInvoice(invoiceId) {
    this.router.navigate(["/invoice-print"], {
      queryParams: { invoiceId: invoiceId },
      queryParamsHandling: "merge",
    });
  }

  previewBill(e) {
    const result = confirm(
      "Please click the appropriate action to continue.",
      "Action"
    );
  }

  editInvoice(e) {
    // sessionStorage.removeItem(AppSettings.billEditKey);
    // sessionStorage.setItem(AppSettings.billEditKey, JSON.stringify(e));
    this.router.navigate(["/invoice/create"], {
      queryParams: { invoiceId: e.ID },
      queryParamsHandling: "merge",
    });
  }

  invoiceDetails(e, isUnpaid: Boolean) {
    const id = e.data.ID;

    this.router.navigate(["/invoice-details"], {
      queryParams: { invoiceId: id, isUnpaid: isUnpaid },
      queryParamsHandling: "merge",
    });
  }

  cancelInvoice(e) {
    const data = {
      requestType: "BILL_CANCEL_RESEND",
      id: e.id,
    };

    const result = confirm(
      "Are you sure you want to Resend this Invoice?",
      "Resend Approval"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const servRes = res.json();
            this.spinner.hide();
            if (servRes.statusId == 2000) {
              this.toastr.success(servRes.statusMessage, "Success");
              this.getCanceledInvoices();
            } else {
              this.toastr.error(servRes.statusMessage, "Request Failed");
            }
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong, failed to create the bill."
            );
          }
        );
      }
    });
  }


  goToNextPage() {
    this.pageNumber++;
    this.getAllInvoicesList(this.pageNumber);
  }

  goToPreviousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.getAllInvoicesList(this.pageNumber);
    }
  }



  goToNextPagePaid() {
    this.pageNumberPaid++;
    this.getAllInvoicesListPaid(this.pageNumberPaid);
  }

  goToPreviousPagePaid() {
    if (this.pageNumberPaid > 1) {
      this.pageNumberPaid--;
      this.getAllInvoicesListPaid(this.pageNumberPaid);
    }
  }

  goToNextPageUnPaid() {
    this.pageNumberUnPaid++;
    this.getAllInvoicesListUnPaid(this.pageNumberUnPaid);
  }

  goToPreviousPageUnPaid() {
    if (this.pageNumberUnPaid > 1) {
      this.pageNumberUnPaid--;
      this.getAllInvoicesListUnPaid(this.pageNumberUnPaid);
    }
  }

  

}
