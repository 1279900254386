import { Component, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { confirm } from "devextreme/ui/dialog";
import { AppSettings } from "src/app/app-settings";
import { FormGroup, FormControl, Validators } from "@angular/forms";
@Component({
  selector: "app-invoice-details",
  templateUrl: "./invoice-details.component.html",
  styleUrls: ["./invoice-details.component.scss"],
})
export class InvoiceDetailsComponent
  extends SharedClassComponent
  implements OnInit
{
  isInvoicePrinted: boolean;
  invoiceId: any;
  currentDate: Date = new Date();
  invoiceData: any;
  isAllowedToReceivePayment: boolean = false;
  isAllowedToAddDebitCreditNote: boolean = false;
  isUnpaid: boolean = false;
  billItemsDatasource = [];
  institutionCode = this.authService.getUserDetails().spId;
  receivePaymentPopUp: boolean = false;
  paymentMethodList: any;
  selectedDebitCreditType: any;
  showDebitCreditDebitPopUp: boolean = false;
  enteredAmount: string = "";

  debitCreditForm: FormGroup;
  ngOnInit() {
    this.paramwinfrm = new FormGroup({
      pmtMethodId: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.debitCreditForm = new FormGroup({
      amount: new FormControl("", Validators.compose([Validators.required])),
    });
    this.getServiceProviderAccount();
    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params.invoiceId;
      this.isUnpaid = params.isUnpaid === "true" ? true : false;

      if (this.invoiceId && this.isUnpaid) {
        this.getInvoiceById(this.invoiceId);
      } else {
        this.getInvoiceDetailsById(this.invoiceId);
      }
    });
    this.getPaymentMethod();
  }

  getPaymentMethod() {
    const data = {
      requestType: "PMT_METHODS_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.paymentMethodList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getInvoiceDetailsById(invoiceId) {
    const data = {
      requestType: "INVOICES_GET",
      invoiceId: invoiceId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data[0];
          this.invoiceData = data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getServiceProviderAccount() {
    const data = {
      requestType: "SP_USER_INFO",
      spUserId: sessionStorage.getItem("userId"),
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          if (srvRes.data) {
            const pemsn = srvRes.data.permissions;
            this.isAllowedToReceivePayment = pemsn.some(
              (permission) =>
                permission.permission === "Can Receive Invoice Payments" &&
                permission.permissionName === "true"
            );


            this.isAllowedToAddDebitCreditNote = pemsn.some(
              (permission) =>
                permission.permission === "Can Add Debit Or Credit Note" &&
                permission.permissionName === "true"
            );
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getInvoiceById(invoiceId) {
    const data = {
      requestType: "INVOICES_PAYED_MANUALLY_WAITING_APPROVAL_GET",
      invoiceId: invoiceId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data[0];
          this.invoiceData = data;
          
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  openReceivePaymentPopUp() {
    this.receivePaymentPopUp = true;
  }

  openDebitCreditAccount(type) {
    this.selectedDebitCreditType = type;
    this.showDebitCreditDebitPopUp = true;
  }

  receivedPayment() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Please a Select Payment Method");
      return;
    }

    const result = confirm(
      "Are you sure you want to Confirm Received Payment ",
      "Confirmation"
    );
    const data = {
      requestType: "INVOICES_RECEIVE_PAYMENT",
      invoiceId: this.invoiceId,
      pmtMethodId:
        this.paramwinfrm.get("pmtMethodId").value != null
          ? this.paramwinfrm.get("pmtMethodId").value.toString()
          : "",
    };

    result.then((dialogResult) => {
      if (dialogResult) {
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.receivePaymentPopUp = false;
              this.toastr.success(response.statusMessage, "Success");
              javascript: history.back();
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  printInvoice() {
    const invoiceId = this.invoiceId;

    if (!this.invoiceId) {
      return;
    }

    this.router.navigate(["/invoice-print"], {
      queryParams: { invoiceId: invoiceId },
      queryParamsHandling: "merge",
    });
  }

  createBill() {
    const Invoicedata = {
      requestType: "BILLS_CREATE",
      billStatus: "1",
      payerName: this.invoiceData.PAYER_NAME,

      date: this.invoiceData.INVOICE_DATE,
      currency: this.invoiceData.CURRENCY,
      amount: this.invoiceData.AMOUNT,
      description: this.invoiceData.DESCRIPTION,
      payerMobile: this.invoiceData.PAYER_MOBILE,
      payerEmail: this.invoiceData.PAYER_EMAIL,
      expireDate: this.invoiceData.EXPIRE_DATE,
      payerTelephone: this.invoiceData.PAYER_TELEPHONE,
      spId: this.institutionCode,
      spBillId: this.invoiceData.INVOICE_NUMBER,
      //REQUIRED
      // payerId: this.invoiceData.PAYER_NAME,
      // paymentOption: this.invoiceData.PAYER_NAME,

      billItems: [
        {
          category: this.invoiceData.REVENUE_SOURCE_ID,
          amount: this.invoiceData.AMOUNT,
          description: this.invoiceData.DESCRIPTION,
        },
      ],
    };

    this.spinner.show();
    this.utilities.postServiceCall(Invoicedata).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();

        if (servRes.statusId == 2000) {
          this.toastr.success("Bill has been created successfully.");

          const data = servRes.data;
          data.invoiceNo = this.invoiceData.INVOICE_NUMBER;

          const inData = Invoicedata;
          (inData as any).isControlNumberSubmitted = true;

          this.billItemsDatasource = [];
          if (data.billItems.length < 1) {
            this.billItemsDatasource.push({
              itemAmount: data.billAmount,
              itemDesc: data.billDesc,
              itemCatName: data.catName,
              itemCurrency: data.currency,
            });
          } else {
            for (const iterator of data.billItems) {
              this.billItemsDatasource.push({
                itemAmount: iterator.amount,
                itemDesc: iterator.desc,
                itemCatName: iterator.categoryName,
                itemCurrency: data.currency,
              });
            }
          }

          sessionStorage.removeItem(AppSettings.billDetailsKey);
          sessionStorage.removeItem(AppSettings.billItemsKey);
          sessionStorage.removeItem(AppSettings.bulkBillerKey);
          sessionStorage.removeItem(AppSettings.bulkBillerFlag);
          sessionStorage.setItem(AppSettings.bulkBillerFlag, "0");
          sessionStorage.setItem(
            AppSettings.billDetailsKey,
            JSON.stringify(data)
          );
          sessionStorage.setItem(
            AppSettings.billItemsKey,
            JSON.stringify(this.billItemsDatasource)
          );
          this.router.navigate(["/bill-payment-form"]);
          sessionStorage.setItem(
            AppSettings.invoiceKey,
            this.encryptionService.encryptString(inData)
          );

          // this.getUInvoiceDetails();
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  cancelPopUp() {
    this.showDebitCreditDebitPopUp = false;
  }

  saveDebitCredit() {
    
    if (this.debitCreditForm.invalid) {
      this.toastr.warning("Please Enter Rate", "warning");
      return;
    }

    if (!this.invoiceId) {
      this.toastr.error(
        "Failed to get invoice Id try to re-open invoice again"
      );
      return;
    }

    const data = {
      requestType: "INVOICE_DRCR_NOTE",
      invoiceId: this.invoiceId,
      rate: this.debitCreditForm.get("amount").value,
      type: this.selectedDebitCreditType === 1 ? "CR" : "DR",
    };

    const result = confirm(
      `Are you sure you want to ${
        this.selectedDebitCreditType === 1 ? "Credit" : "Debit"
      } this amount ${this.enteredAmount} to this this customer (${
        this.invoiceData.PAYER_NAME
      })?`,
      `${
        this.selectedDebitCreditType === 1 ? "Credit" : "Debit"
      } Note Verification`
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const servRes = res.json();
            this.spinner.hide();
            if (servRes.statusId == 2000) {
              this.toastr.success(servRes.statusMessage, "Success");


              servRes.data.type =  this.selectedDebitCreditType;
              const newDats = {
                invoiceData: this.invoiceData,
                debitCredit: servRes.data,
              };
this.getFinalInvoice(newDats);

    
            } else {
              this.toastr.error(servRes.statusMessage, "Request Failed");
            }
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong, failed to create the bill."
            );
          }
        );
      }
    });
  }


  
  getFinalInvoice(newData){
    const data = {
      requestType: "INVOICES_PAYED_MANUALLY_WAITING_APPROVAL_GET",
      invoiceId:this.invoiceId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data[0];
          this.invoiceData = data;

          newData.invoiceData = this.invoiceData;
          sessionStorage.setItem(
            AppSettings.debitCredit,
            this.encryptionService.encryptString(newData)
          );        
          this.spinner.hide();

          this.router.navigate(["/print-debit-credit-note"]);
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }


  updateNumber(e: any) {
    const value = e.event.target.value;

    this.enteredAmount = value;
  }
}
